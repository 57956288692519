let API_URL = "https://backend.sfscredit.com/api/v1/";
let API_URL_V2 = "https://backend.sfscredit.com/api/v2/";
let PAYSTACK_PK = "pk_live_6047ef197e04d69bb36db60024011905cfb901fc";

// Test Server API
if (window.location.hostname.includes("review.sfscredit.com")) {
    API_URL = "https://review-backend.sfscredit.com/api/v1/";
    API_URL_V2 = "https://review-backend.sfscredit.com/api/v2/";
    PAYSTACK_PK = "pk_test_11cd40bdd7b67933b920d2c0f9fb801d6eb1e32c";
}

// Local Machine API
if (window.location.hostname.includes("localhost")) {
    API_URL = "http://127.0.0.1:8000/api/v1/";
    API_URL_V2 = "http://127.0.0.1:8000/api/v2/";
    PAYSTACK_PK = "pk_test_11cd40bdd7b67933b920d2c0f9fb801d6eb1e32c";
}

export { API_URL, API_URL_V2, PAYSTACK_PK };
