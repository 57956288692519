import React, { useEffect, useState } from "react";
import { Button, Icon, message, Skeleton, notification } from "antd";
import {
    getAdminSettings,
    updateAdminSetting,
} from "../../../APIsHandler/ApiController";

export default function AdminSetting(props) {
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [settings, setSettings] = useState(null);

    function handleChange(e) {
        setSettings({
            ...settings,
            [e.target.name]: e.target.value,
        });
    }

    function handleSubmit() {
        setUpdating(true);
        updateAdminSetting(settings)
            .then(() => {
                setUpdating(false);

                message.success(`Updated Setting Successfully`, 3);
            })
            .catch((error) => {
                setUpdating(false);
                message.error(
                    `Unable to update settings.  ${
                        error.data !== undefined ? error.data.message : ""
                    }. Please try again.`,
                    3
                );
            });
    }

    function fetch() {
        getAdminSettings()
            .then((resp) => {
                setSettings(resp.data.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                message.error(
                    `Unable to get settings.  ${
                        error.data !== undefined ? error.data.message : ""
                    }. Please try again.`,
                    3
                );
            });
    }

    useEffect(() => {
        fetch();
    }, []);

    return (
        <Skeleton loading={loading} active>
            <div className="row">
                <div className="col-md-10">
                    <h1 className="z-dashboard-label">Settings</h1>
                    <div className="mt-4">
                        <div className="z-mt-10">
                            <div className="row">
                                <div className="col-md-4 mb-4">
                                    <p className="z-profile-label mb-2">
                                        Amount Per Point
                                    </p>
                                    <input
                                        size="large"
                                        type="number"
                                        placeholder="Amount Per Point"
                                        value={
                                            settings &&
                                            settings.amount_per_point
                                        }
                                        name="amount_per_point"
                                        onChange={handleChange}
                                        className="form-control profile-form"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-4">
                                    <p className="z-profile-label mb-2">
                                        New Sign Up Referral Point
                                    </p>
                                    <input
                                        size="large"
                                        type="number"
                                        placeholder="New Sign Up Referral Point"
                                        value={
                                            settings &&
                                            settings.new_sign_up_referral_point
                                        }
                                        name="new_sign_up_referral_point"
                                        onChange={handleChange}
                                        className="form-control profile-form"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-4">
                                    <p className="z-profile-label mb-2">
                                        Loan Request Approval Referral Point
                                    </p>
                                    <input
                                        size="large"
                                        type="number"
                                        placeholder="Loan Request Approval Referral Point"
                                        value={
                                            settings &&
                                            settings.loan_request_approval
                                        }
                                        name="loan_request_approval"
                                        onChange={handleChange}
                                        className="form-control profile-form"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-4">
                                    <p className="z-profile-label mb-2">
                                        Loan Payback Referral Point
                                    </p>
                                    <input
                                        size="large"
                                        type="number"
                                        placeholder="Loan Payback Referral Point"
                                        value={
                                            settings && settings.loan_payback
                                        }
                                        name="loan_payback"
                                        onChange={handleChange}
                                        className="form-control profile-form"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <Button
                                        className="z-landing-btn z-section-btn z-profile-button mr-3 mb-3"
                                        shape="round"
                                        size="large"
                                        loading={updating}
                                        onClick={handleSubmit}
                                    >
                                        Update Settings
                                    </Button>
                                    <Button
                                        className="z-landing-btn z-section-btn z-profile-button z-button-plain2 mr-3 mb-3"
                                        shape="round"
                                        size="large"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Skeleton>
    );
}
