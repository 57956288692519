import React, {Fragment} from "react";
import {Button, Col, Row} from "antd";
import logo from "../../assets/images/placeholder_logo.png";


const Header = (props) => {
    const {changePage, showSignupModal} = props;
    return (
        <Fragment>
            <Row className="z-height-inherit">
                <Col span={24} className="z-height-inherit">
                    <div className="z-navbar z-flex-space">
                        <div className="z-navbar-logo">
                            <img src={logo} alt="logo"/>
                        </div>
                        <div className="z-flex">
                            <Button className="z-nav-btn" onClick={(e) => changePage(e, 'landingPageBody')}> Home </Button>
                            <Button className="z-nav-btn" onClick={ (e) => changePage(e, 'gettingStarted')}>Getting Started</Button>
                            <Button className="z-nav-btn">Faqs</Button>
                            <Button className="z-nav-btn" onClick={e => {showSignupModal(e, 0)}}>Signup</Button>
                            <Button className="z-nav-btn z-nav-login" onClick={e => {showSignupModal(e, 1)}}>Login</Button>
                        </div>
                    </div>
                </Col>

            </Row>
        </Fragment>
    )
};
export default Header;