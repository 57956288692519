import React, { Component } from "react";
import {
    Button,
    Col,
    Input,
    Modal,
    Row,
    Select,
    Steps,
    Icon,
    Tooltip,
} from "antd";
import "../../assets/css/main.css";
import IconIm from "../../assets/images/forward icon.png";
import Icon2 from "../../assets/images/previous icon.png";
/*import Logo from '../../assets/images/download.png'
import Logo2 from '../../assets/images/bank-phb-c16cc46e-ee61-4d55-a257-4cf57c228a6-resize-750.jpeg'*/
import DefaultCompanyAvatar from "../../assets/images/defaultCompanyIcon.png";

const Step = Steps.Step;
const Option = Select.Option;

export default class SignupModal extends Component {
    render() {
        const {
            state,
            closeSignupModal,
            selectOrganization,
            navigateBack,
            goToSignupModal,
            showSignupModal,
            logIn,
            handleForm,
            handleChange,
            registerUser,
            showPasswordModal,
            showEmailVerificationModal,
            loginEnter,
        } = this.props;
        return (
            <div>
                <Modal
                    className="z-custom z-modal-signup"
                    visible={state.dualModal}
                    title={null}
                    onOk={this.handleOk}
                    onCancel={(e) => {
                        closeSignupModal();
                    }}
                    footer={null}
                >
                    <div>
                        <Row>
                            <Col
                                offset={12}
                                span={12}
                                className="z-mt-3 z-login-res"
                            >
                                <div className="z-flex-baseline z-mr-25">
                                    <div className="z-testimonial">
                                        <h2
                                            className={
                                                state.tabType === 1
                                                    ? "z-login-active z-mr--25"
                                                    : "z-login-inactive z-mr-20 z-flex-baseline"
                                            }
                                            onClick={(e) => {
                                                showSignupModal(e, 1);
                                            }}
                                        >
                                            Log in
                                        </h2>
                                    </div>
                                    <div className="z-testimonial">
                                        <h2
                                            className={
                                                state.tabType === 0
                                                    ? "z-login-active"
                                                    : "z-login-inactive z-mr-20 z-flex-baseline"
                                            }
                                            onClick={(e) => {
                                                showSignupModal(e, 0);
                                            }}
                                        >
                                            Sign up
                                        </h2>
                                    </div>
                                </div>
                                <div className="z-signup-hr">
                                    <hr
                                        className={
                                            state.tabType === 1
                                                ? "z-hr-login"
                                                : ""
                                        }
                                    />
                                </div>
                            </Col>

                            {state.tabType === 0 && (
                                <Col span={24} className="z-mt-3">
                                    <Row>
                                        <Col
                                            offset={8}
                                            span={4}
                                            className="z-signup-res"
                                        >
                                            <Steps
                                                direction="vertical"
                                                size="small"
                                                current={state.tabNum}
                                                className="z-mt-10"
                                            >
                                                <Step className="z-step" />
                                                <Step />
                                            </Steps>
                                        </Col>
                                        {state.tabNum === 0 && (
                                            <Col
                                                span={12}
                                                className="z-signup-res2"
                                            >
                                                <div className="z-mb-4">
                                                    <p className="z-signup-parag">
                                                        Create free account in 2
                                                        easy steps
                                                    </p>
                                                </div>
                                                <div>
                                                    <h2 className="z-signup-header z-mb-3">
                                                        Select Company
                                                    </h2>
                                                </div>
                                                <div className="z-flex-column-center">
                                                    <Select
                                                        defaultValue={
                                                            state.companyName
                                                                ? state.companyName
                                                                : "Choose a company"
                                                        }
                                                        disabled={
                                                            state.isGuarantor
                                                        }
                                                        size="large"
                                                        className="z-select"
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    >
                                                        {state.companyLoading ? (
                                                            <Option value="loading...">
                                                                <Icon type="loading" />
                                                            </Option>
                                                        ) : (
                                                            state.companies.map(
                                                                (
                                                                    company,
                                                                    i
                                                                ) => {
                                                                    return (
                                                                        <Option
                                                                            value={
                                                                                company.id
                                                                            }
                                                                            className="z-select-input"
                                                                            key={
                                                                                i
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    company.logo
                                                                                        ? company.logo
                                                                                        : DefaultCompanyAvatar
                                                                                }
                                                                                alt="logo"
                                                                            />{" "}
                                                                            {
                                                                                company.name
                                                                            }
                                                                        </Option>
                                                                    );
                                                                }
                                                            )
                                                        )}
                                                    </Select>
                                                    <Input
                                                        size="large"
                                                        placeholder="Referal Code"
                                                        name="referalCode"
                                                        value={
                                                            state.referalCode
                                                        }
                                                        onChange={(e) => {
                                                            handleForm(e);
                                                        }}
                                                        className="z-footer-input z-signup-company z-mt-5"
                                                    />
                                                </div>
                                                <div className="z-flex-row z-mt-26 ">
                                                    <Button
                                                        className="z-landing-btn z-signup-small z-mb-6 z-button-gradient z-mr-3"
                                                        shape="round"
                                                        size="large"
                                                        onClick={(e) => {
                                                            goToSignupModal(e);
                                                        }}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        className="z-landing-btn z-signup-small z-mb-6 z-button-gradient z-flex-row"
                                                        shape="round"
                                                        size="large"
                                                        onClick={(e) => {
                                                            selectOrganization(
                                                                e
                                                            );
                                                        }}
                                                        disabled={
                                                            state.company
                                                                .length < 1
                                                        }
                                                    >
                                                        Next{" "}
                                                        <img
                                                            className="z-btn-img"
                                                            src={IconIm}
                                                            alt="next"
                                                        />
                                                    </Button>
                                                </div>
                                            </Col>
                                        )}

                                        {state.tabNum === 1 && (
                                            <Col
                                                span={12}
                                                className="z-signup-res2"
                                            >
                                                <div>
                                                    <h2 className="z-signup-header z-mb-3">
                                                        Email Validation
                                                    </h2>
                                                </div>
                                                <div className="z-flex-column-center">
                                                    <Input
                                                        size="large"
                                                        placeholder="First name"
                                                        name="firstname"
                                                        onChange={(e) => {
                                                            handleForm(e);
                                                        }}
                                                        className="z-footer-input z-signup-company"
                                                    />
                                                    <Input
                                                        size="large"
                                                        placeholder="Last name"
                                                        name="lastname"
                                                        onChange={(e) => {
                                                            handleForm(e);
                                                        }}
                                                        className="z-footer-input z-signup-company"
                                                    />
                                                    <Input
                                                        size="large"
                                                        placeholder="email"
                                                        name="useremail"
                                                        value={state.useremail}
                                                        onChange={(e) => {
                                                            handleForm(e);
                                                        }}
                                                        className="z-footer-input z-signup-company"
                                                        disabled={
                                                            state.isGuarantor
                                                        }
                                                    />
                                                    <Tooltip title="The password must have at least 8 character, which must contain at least one numeric digit, one uppercase and one lowercase letter">
                                                        <Input.Password
                                                            size="large"
                                                            placeholder="password"
                                                            name="userpassword"
                                                            onChange={(e) => {
                                                                handleForm(e);
                                                            }}
                                                            className="z-signup-password no-mb"
                                                        />
                                                    </Tooltip>
                                                </div>
                                                <div className="z-flex-row z-mt-18 ">
                                                    <Button
                                                        className="z-landing-btn z-signup-small z-mb-6 z-button-gradient z-mr-3 z-flex-row"
                                                        shape="round"
                                                        size="large"
                                                        onClick={(e) => {
                                                            navigateBack(e);
                                                        }}
                                                    >
                                                        <img
                                                            className="z-btn-img2 z-ml12"
                                                            src={Icon2}
                                                            alt="next"
                                                        />{" "}
                                                        Previous
                                                    </Button>
                                                    <Button
                                                        className="z-landing-btn z-signup-small z-mb-6 z-button-gradient z-flex-row"
                                                        shape="round"
                                                        size="large"
                                                        onClick={(e) => {
                                                            registerUser();
                                                        }}
                                                        disabled={
                                                            state.lastname
                                                                .length < 1 ||
                                                            state.firstname
                                                                .length < 1 ||
                                                            state.useremail
                                                                .length < 1 ||
                                                            state.userpassword
                                                                .length < 1
                                                        }
                                                    >
                                                        Finish{" "}
                                                        {state.register ? (
                                                            <Icon type="loading" />
                                                        ) : (
                                                            <img
                                                                className="z-btn-img"
                                                                src={IconIm}
                                                                alt="next"
                                                            />
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            )}

                            {state.tabType === 1 && (
                                <Col
                                    offset={12}
                                    span={12}
                                    className="z-login-mt z-login-res"
                                >
                                    <div className="z-flex-column-center">
                                        <Input
                                            size="large"
                                            placeholder="email"
                                            name="email"
                                            onChange={(e) => {
                                                handleForm(e);
                                            }}
                                            className="z-footer-input z-signup-company z-login-mb z-login-input"
                                            value={state.email}
                                            onKeyUp={(e) => {
                                                loginEnter(e);
                                            }}
                                            disabled={state.isGuarantor}
                                        />
                                        <Input.Password
                                            size="large"
                                            placeholder="password"
                                            name="password"
                                            onChange={(e) => {
                                                handleForm(e);
                                            }}
                                            className="z-signup-password no-mb z-login-input2"
                                            value={state.password}
                                            onKeyUp={(e) => {
                                                loginEnter(e);
                                            }}
                                        />
                                        <div className="z-mt-15 z-b-flex">
                                            <Button
                                                className="z-landing-btn z-section-btn z-login-btn z-gradient"
                                                shape="round"
                                                size="large"
                                                loading={state.loginLoading}
                                                onClick={(e) => {
                                                    logIn(e);
                                                }}
                                            >
                                                Log in
                                            </Button>
                                        </div>
                                        <div className="z-mt-6">
                                            <p
                                                className="z-p-small z-cursor-poiner"
                                                onClick={(e) => {
                                                    showPasswordModal();
                                                }}
                                            >
                                                forgot password?
                                            </p>
                                        </div>
                                        <div className="z-mt-6">
                                            <p
                                                className="z-p-small z-cursor-poiner"
                                                onClick={(e) => {
                                                    showEmailVerificationModal();
                                                }}
                                            >
                                                Verify your account?
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </div>
                </Modal>
            </div>
        );
    }
}
