import {Icon, Layout} from "antd";
import moment from 'moment';
import React from "react";
import NotificationTab from "../dashboardLayout/NotificationTab";

const { Header } = Layout;

export const HeaderNav = (props) => {
    const {showSideBar,user, notifications, deleteNotification, markAll, badge, userRole, todayDate, deleteNote} = props;

    return(
        <Header className="z-nav-header z-flex-row2">
            <div className="z-flex-space z-width-100">
                <div className="z-width-100 z-flex-start">
                    <div className="z-display-none">
                        <Icon type="menu-unfold" className="z-nav-fold" onClick={ () => showSideBar() } />
                    </div>
                    {userRole === "admin" ? <h2>
                        Admin,
                    </h2> : <h2>
                        Hello {user.data.firstname},
                    </h2>}
                </div>
                <NotificationTab badge={badge} markAll={markAll} notifications={notifications} deleteNotification={deleteNotification} deleteNote={deleteNote}/>
            </div>
            <div className="z-flex-space z-width-100 z-dash-res z-res-w-70" >
                <div className="z-nav-header-p">
                    {moment(todayDate).format("DD MMMM, YYYY")}
                </div>
            </div>
        </Header>
    )
};