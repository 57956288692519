import React, { useState } from "react";
import { Button, Popconfirm, message } from "antd";
import { deleteBlacklist } from "../../../APIsHandler/ApiController";

export default function DeleteBlacklistButton({ id, reload }) {
    const [isLoading, setIsLoading] = useState(false);

    function handleDelete() {
        setIsLoading(true);

        deleteBlacklist(id)
            .then(() => {
                setIsLoading(false);
                reload();
            })
            .catch((error) => {
                setIsLoading(false);
                message.error(
                    `Unable to delete Email from blacklist.  ${
                        error.data !== undefined ? error.data.message : ""
                    }. Please try again.`,
                    3
                );
            });
    }

    return (
        <Popconfirm
            title="Do you want to remove this email from Blackist"
            onConfirm={handleDelete}
            okText="Yes"
            cancelText="No"
        >
            <Button loading={isLoading} className="z-table-btn" type="danger">
                {" "}
                Delete
            </Button>
        </Popconfirm>
    );
}
