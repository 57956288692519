import React, { useState } from "react";
import { Button, Table } from "antd";
import Column from "antd/lib/table/Column";
import moment from "moment";
import { formatCurrency } from "../../globalComponents/globalFunctions";
import NavigationTab from "../../NavigationTab";
import LoanPackageModal from "./LoanPackageModal";
import DeleteLoanPackageButton from "./DeleteLoanPackageButton";

export default function LoanPackagesTable({
    loanPackages,
    fetchLoanPackages,
    companies,
    companyLoading,
}) {
    const [currentNavIndex, setCurrentNavIndex] = useState(0);
    const [nextNavIndex, setNextNavIndex] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [navDiff, setNavDiff] = useState(10);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [noOfPages, setNoOfPages] = useState(0);
    const [showLoanPackageModal, setShowLoanPackageModal] = useState(false);
    const [loanPackage, setLoanPackage] = useState(null);

    function closeLoanPackageModal() {
        setLoanPackage(null);
        setShowLoanPackageModal(false);
    }

    function openLoanPackageModal(loanPackage) {
        setShowLoanPackageModal(true);
        setLoanPackage(loanPackage);
    }

    function onSelectChange(selectedRowKeys) {
        console.log("selectedRowKeys changed: ", selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    }

    const navigateRight = () => {
        if (currentPage !== noOfPages) {
            setNextNavIndex(nextNavIndex + navDiff);
            setCurrentNavIndex(currentNavIndex + navDiff);
            setCurrentPage((nextNavIndex + navDiff) / navDiff);
        }
    };

    const navigateLeft = () => {
        if (currentPage !== 1) {
            setNextNavIndex(nextNavIndex - navDiff);
            setCurrentNavIndex(currentNavIndex - navDiff);
            setCurrentPage((nextNavIndex - navDiff) / navDiff);
        }
    };

    return (
        <>
            <div>
                <Table
                    rowSelection={{
                        selectedRowKeys,
                        onChange: onSelectChange,
                    }}
                    bordered={false}
                    dataSource={loanPackages.slice(
                        currentNavIndex,
                        nextNavIndex
                    )}
                    className="z-admin-table z-todo"
                    pagination={false}
                    rowKey="id"
                    rowClassName={(record, index) => {
                        return "z-stripped z-table-border";
                    }}
                    scroll={{ x: "220%" }}
                    style={{
                        width: "100%",
                    }}
                >
                    <Column
                        title="Mark all"
                        dataIndex="sn"
                        key="sn"
                        render={(text, record, index) => {
                            return (
                                <div className="">
                                    <p className="z-table-num z-no-mb">
                                        {Number(
                                            index + (currentPage - 1) * navDiff
                                        ) + 1}
                                    </p>
                                </div>
                            );
                        }}
                    />
                    <Column
                        title="Package name"
                        dataIndex="name"
                        key="name"
                        render={(text, record, index) => {
                            return (
                                <div className="z-flex-start">
                                    <div
                                        className={
                                            index +
                                                (currentPage - 1) * navDiff ===
                                            0
                                                ? "z-tag z-bg-pink"
                                                : index +
                                                      (currentPage - 1) *
                                                          navDiff ===
                                                  1
                                                ? "z-tag z-bg-purple"
                                                : "z-tag z-bg-black"
                                        }
                                    ></div>
                                    <div className="z-ml-6">
                                        <p className="z-table-name z-table-parag Product-sans z-font-14">
                                            {record.name}
                                        </p>
                                    </div>
                                </div>
                            );
                        }}
                    />
                    <Column
                        title="Company"
                        dataIndex="company"
                        key="company"
                        render={(text, record) => {
                            return (
                                <p className="z-table-com z-font-14">
                                    {record.company ? record.company.name : ""}
                                </p>
                            );
                        }}
                    />
                    <Column
                        title="Status"
                        dataIndex="status"
                        key="status"
                        render={(text, record) => {
                            return (
                                <p className="z-table-com z-font-14">
                                    {record.status}
                                </p>
                            );
                        }}
                    />

                    <Column
                        title="Maximum amount(&#8358;)"
                        dataIndex="amount"
                        key="amount"
                        render={(text, record) => {
                            return (
                                <p className="z-table-com z-font-14">
                                    {formatCurrency(record.amount)}
                                </p>
                            );
                        }}
                    />

                    <Column
                        title="Date Created"
                        dataIndex="created_at"
                        key="created_at"
                        render={(text, record) => {
                            return (
                                <p className="z-table-com z-font-14">
                                    {moment(record.created_at).format(
                                        "DD-MM-YYYY"
                                    )}
                                </p>
                            );
                        }}
                    />
                    <Column
                        title="Action"
                        dataIndex="action"
                        key="Action"
                        render={(i, record, index) => (
                            <>
                                <Button
                                    className="z-tablebtn"
                                    onClick={() => openLoanPackageModal(record)}
                                >
                                    {" "}
                                    view
                                </Button>
                                <DeleteLoanPackageButton
                                    id={record.id}
                                    reload={fetchLoanPackages}
                                />
                            </>
                        )}
                    />
                </Table>
            </div>
            {loanPackages.length > 0 && (
                <div className="z-admin-gr-div">
                    <NavigationTab
                        noOfPages={noOfPages}
                        currentPage={currentPage}
                        navigateLeft={navigateLeft}
                        navigateRight={navigateRight}
                    />
                </div>
            )}
            <LoanPackageModal
                isVisible={showLoanPackageModal}
                closeModal={closeLoanPackageModal}
                loanPackage={loanPackage}
                fetchLoanPackages={fetchLoanPackages}
                companies={companies}
                companyLoading={companyLoading}
            />
        </>
    );
}
