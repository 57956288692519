import React, {Component} from 'react';
import {Col, Icon, Row, message, Skeleton} from "antd";
import {getNotifications} from "../../../APIsHandler/ApiController";
import empty from "../../../assets/images/undraw_no_data_qbuo (3).svg";
import NavigationTab from "../../NavigationTab";
import overviewIcon from "../../../assets/images/dashboard/overview.svg";

export default class Notifications extends Component{

    constructor(props){
        super(props)
        this.state={
            tabNum:0,
            notifications: [],
            allNotification: [],
            navDiff: 20,
            noOfPages: 0,
            currentPage: 1,
            currentNavIndex: 0,
            nextNavIndex: 10,
            loading: true
        }
    }
    componentDidMount() {
        getNotifications().then( res => {
            let {data} = res.data;
            let num = Math.ceil(data.length / this.state.navDiff);
            this.setState({notifications: data, allNotification: data,noOfPages: num, loading: false});
        }).catch( err => {
            message.error("Unable to retrieve notifications")
        })
    }

    /*
    * Toggle tabs
    */
    setTab = (e, i) =>{
        this.setState({ tabNum: i});
        this.sortData(i)
    };

    /*
   * sort table data*/
    sortData = (tab) => {
        const{allNotification, navDiff} = this.state;
        if(tab === 1) {
            const sorted = allNotification.filter(request => {
                return request.read_at !== null
            });
            let num = Math.ceil(sorted.length / navDiff)
            this.setState({notifications: sorted, noOfPages: num})
        } else if(tab === 2) {
            const sorted = allNotification.filter(request => {
                return request.read_at === null
            });
            let num = Math.ceil(sorted.length / navDiff)
            this.setState({notifications: sorted, noOfPages: num})
        }else{
            let num = Math.ceil(allNotification.length / navDiff)
            this.setState({notifications: allNotification, noOfPages: num})
        }
    };
    navigateRight = () =>{
        const {navDiff, nextNavIndex, currentNavIndex, currentPage, noOfPages} = this.state;

        if(currentPage !== noOfPages){
            this.setState({nextNavIndex: nextNavIndex + navDiff, currentNavIndex: currentNavIndex + navDiff,
                currentPage: ((nextNavIndex + navDiff)/navDiff)})
        }
    };

    navigateLeft = () =>{
        const {navDiff, nextNavIndex, currentNavIndex, currentPage} = this.state;

        if(currentPage !== 1){
            this.setState({nextNavIndex: nextNavIndex - navDiff, currentNavIndex: currentNavIndex - navDiff,
                currentPage: ((nextNavIndex - navDiff)/navDiff)})
        }
    };

    render() {
        const {tabNum, notifications, loading} = this.state;
        return(
            <div>
                <div>
                    <h1 className="z-dashboard-label">
                        Notifications
                    </h1>
                </div>
                <Skeleton loading={loading} active>
                    <Row>
                    <Col span={18} className="z-loan-res">
                        <div className="">
                            <div className="z-flex-start z-guarantor-padding mb-3">
                                <img src={overviewIcon} className="img-fluid mr-3" alt="Overview Icon"/>
                                <span className="z-label-text">All ({notifications.length})</span>
                            </div>
                           <div className="z-flex-space z-guarantor-padding2 mb-3">
                                <div className={tabNum === 0 ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner" : "z-cursor-poiner z-width-100 z-flex-row"} onClick={e => {this.setTab(e,0)}}>
                                    <div className="z-guarantor-tab ">
                                        <p className="z-ml-8 z-notification-active">All</p>
                                    </div>
                                </div>
                                <div className={tabNum === 1 ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner" : "z-cursor-poiner z-width-100 z-flex-row"} onClick={e => {this.setTab(e,1)}}>
                                    <div className="z-guarantor-tab">
                                        <p className="z-ml-8 z-notification-active">Read</p>
                                    </div>
                                </div>
                                <div className={tabNum === 2 ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner" : "z-cursor-poiner z-width-100 z-flex-row"} onClick={e => {this.setTab(e,2)}}>
                                    <div className="z-guarantor-tab">
                                        <p className="z-ml-8 z-notification-active">Unread</p>
                                    </div>
                                </div>
                            </div>
                            <div className=" z-mt-4 ">
                                <div className="z-width-100">
                                    <div className="z-todo-div">
                                        {
                                        notifications.length < 1 ?
                                            <div className="z-empty-div z-flex-column-center">
                                                <img src={empty} alt="empty"/>
                                            </div>
                                            :
                                            notifications.map((notification, i) => {
                                            return(
                                            <div className="z-flex-start z-timeline z-mb-2" key={i}>
                                                <div className="z-notification-dIcon z-flex-column-center">
                                                    <Icon type="mail" />
                                                </div>
                                                <p>{notification.data.message}</p>
                                            </div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                            {notifications.length > 0 &&
                            <div className="z-margin-auto z-flex-center">
                                <NavigationTab noOfPages={this.state.noOfPages} currentPage={this.state.currentPage} navigateLeft={this.navigateLeft} navigateRight={this.navigateRight}/>
                            </div>}
                        </div>
                    </Col>
                </Row>
                </Skeleton>
            </div>
        )
    }
}