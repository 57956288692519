import React, { useState, useEffect } from "react";
import { Button, Popconfirm, message } from "antd";
import { updateUserStatus } from "../../../APIsHandler/ApiController";
import { connect } from "react-redux";

function UpdateAdministratorStatusButton({ userId, status, reload, user }) {
    const [isLoading, setIsLoading] = useState(false);

    const [newStatus, setNewStatus] = useState("disable");

    useEffect(() => {
        status == 1 ? setNewStatus("disable") : setNewStatus("enable");
    }, []);

    async function handleChangeStatus(status) {
        setIsLoading(true);

        console.log({ status, userId });

        try {
            await updateUserStatus(userId, status === "enable" ? true : false);

            reload(); 
            setIsLoading(false);
            message.success(`Admin has been ${newStatus}d`, 3);
        } catch (error) {
            setIsLoading(false);

            message.error(
                `Unable to ${status} user.  ${
                    error.data !== undefined ? error.data.message : ""
                }. Please try again.`,
                3
            );
        }
    }

    return (
        // show button if user is a super admin
        user.data.roles[0].name === "super-admin" && (
            <Popconfirm
                title={`Do you want to ${newStatus} this User?`}
                onConfirm={() => handleChangeStatus(newStatus)}
                okText="Yes"
                cancelText="No"
            >
                <Button
                    loading={isLoading}
                    className={`z-table-btn ${
                        newStatus === "enable" && "success"
                    }`}
                    type={newStatus === "disable" ? "danger" : "default"}
                >
                    {newStatus}
                </Button>
            </Popconfirm>
        )
    );
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(UpdateAdministratorStatusButton);
