import React, { useState, useEffect, Fragment } from "react";
import { Button, Progress, message, Skeleton } from "antd";
import Icon1 from "../../../assets/images/black icons-01.png";
import {
    getGuarantorRequests,
    getLoanRepaymentProgress,
    getUserRequest,
} from "../../../APIsHandler/ApiController";
import { formatCurrency } from "../../globalComponents/globalFunctions";
import { connect } from "react-redux";
import overviewIcon from "../../../assets/images/dashboard/overview.svg";
import applyLoan from "../../../assets/images/dashboard/applyLoan.png";
import arrow from "../../../assets/images/dashboard/arrow-.svg";
import naira from "../../../assets/images/dashboard/naira.svg";
import "../Dashboard.scss";

const DashboardHome = (props) => {
    const [collapsed, setCollapsed] = useState(false);
    const [todayDate, setTodayDate] = useState(new Date());
    const [tabNum, setTabNum] = useState(2);
    const [usersLoans, setUsersLoans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [payBack, setPayBack] = useState(0);
    const [guaranteedLoan, setGuaranteedLoan] = useState(0);
    const [progressObj, setProgressObj] = useState({
        paid: 0,
        unpaid: 0,
        pending: [],
        percentage: 0,
    });

    useEffect(() => {
        let startComponent = false;
        getUserRequest()
            .then((res) => {
                if (!startComponent) {
                    const { data } = res.data;
                    const loans = data.filter(
                        (loan) => loan.status === "approved"
                    );

                    let totalAmount = loans.reduce(function (
                        accumulator,
                        loan
                    ) {
                        const totalPayback =
                            loan.total_payback ||
                            loan.loan_package.total_payback;

                        return accumulator + totalPayback;
                    },
                    0);
                    setPayBack(Number(totalAmount));
                    setUsersLoans(data);
                    setLoading(false);

                    //get current loan progress
                    if (data.length > 0) {
                        const sortedData = data.sort(function (a, b) {
                            return (
                                new Date(b.created_at) - new Date(a.created_at)
                            );
                        });

                        getLoanRepaymentProgress().then((res) => {
                            const { data } = res.data;
                            const pendingLoan = data.filter(
                                (loan) =>
                                    loan.payment_status === "pending" ||
                                    loan.payment_status === "failed"
                            );
                            const paidLoan = data.filter(
                                (loan) => loan.payment_status === "success"
                            );

                            pendingLoan.sort((a, b) => {
                                return (
                                    new Date(a.payback_date) -
                                    new Date(b.payback_date)
                                );
                            });

                            const totalPaid = paidLoan.reduce((acc, obj) => {
                                return acc + obj.amount_due;
                            }, 0);
                            const totalUnpaid = pendingLoan.reduce(
                                (acc, obj) => {
                                    return acc + obj.amount_due;
                                },
                                0
                            );
                            const totalLoan = data.reduce((acc, obj) => {
                                return acc + obj.amount_due;
                            }, 0);
                            const percentageLoan =
                                (totalPaid / totalLoan) * 100;

                            const progressObj = {
                                paid: totalPaid,
                                unpaid: totalUnpaid,
                                pending: pendingLoan,
                                percentage: Number.isNaN(percentageLoan)
                                    ? 0
                                    : Number(percentageLoan.toFixed(0)),
                            };
                            setProgressObj(progressObj);

                            console.log(
                                "paud",
                                Number(percentageLoan.toFixed(0))
                            );
                        });
                    }
                }
            })
            .catch((err) => {
                message.error("Unable to load page", 3);
                setLoading(false);
            });


        getGuarantorRequests()
            .then((res) => {
                if (!startComponent) {
                    let guarantorRequests = res.data.data;
                    let approvedGuarantorRequests = guarantorRequests.filter(
                        (req) => {
                            return req.guarantor_approved === "approved";
                        }
                    );

                    let sumOfApprovedGuarantorLoans =
                        approvedGuarantorRequests.length > 0
                            ? approvedGuarantorRequests
                                  .map((req) => {
                                      const totalPayback =
                                          req.loan_request.total_payback ||
                                          req.loan_request.loan_package
                                              .total_payback;

                                      return totalPayback;
                                  })
                                  .reduce((prev, next) => prev + next)
                            : 0;

                    setGuaranteedLoan(
                        parseInt(sumOfApprovedGuarantorLoans, 10)
                    );
                    console.log(
                        "Guaranteed Loans",
                        approvedGuarantorRequests,
                        sumOfApprovedGuarantorLoans
                    );
                }
            })
            .catch((err) => {
                console.log("error", err);
            });
        if (!startComponent) {
            if (
                props.user &&
                props.user.data.hasOwnProperty("user_profile") &&
                props.user.data.user_profile !== null &&
                Object.keys(props.user.data.user_profile).length > 0
            ) {
                setTabNum(1);
            }
        }

        return () => {
            startComponent = true;
        };
    }, []);

    const navRight = () => {
        const { tabNum } = this.state;
        if (tabNum < 4) {
            setTabNum(tabNum + 1);
        }
    };
    const navLeft = () => {
        const { tabNum } = this.state;
        if (tabNum > 1) {
            setTabNum(tabNum - 1);
        }
    };

    /*
     * redirects to profile page*/
    const goToProfile = () => {
        localStorage.setItem("defaultSelectedNav", JSON.stringify("6"));
        window.location.href = "/dashboard/settings";
    };

    /*
     * redirects to profile page*/
    const goToApply = () => {
        localStorage.setItem("defaultSelectedNav", JSON.stringify("2"));
        window.location.href = "/dashboard/apply";
    };

    return (
        <Fragment>
            <Skeleton loading={loading} active>
                {usersLoans.length < 1 ? (
                    <div className="z-dashboard-panel z-mb-5 z-width-65">
                        <h1 className="z-dashboard-label">Dashboard</h1>
                        <div className="z-flex-start z-mb-14">
                            <div className="z-icn-div">
                                <img src={Icon1} alt="icon" />
                            </div>
                            <p className="z-label-text">Overview</p>
                        </div>
                        <div className="z-flex-space z-p-10 z-pan-top">
                            <div className="z-width-100 z-flex-row z-pamel-rev">
                                <div
                                    className="z-dashboard-tab z-flex-column-center"
                                    onClick={(e) => {
                                        setTabNum(1);
                                    }}
                                >
                                    <p>
                                        Your first <br /> Application
                                        <Button
                                            className="z-landing-btn z-tab-button z-mb-6 z-flex-row z-new-active"
                                            shape="round"
                                            size="large"
                                        >
                                            Start
                                        </Button>
                                    </p>
                                    {tabNum === 1 && (
                                        <div className="z-tab-active z-flex-column-center">
                                            <p>
                                                Your first <br /> Application
                                                <Button
                                                    className="z-landing-btn z-tab-button z-mb-6 z-button-gradient z-gradient5 z-flex-row z-tab-button-active"
                                                    shape="round"
                                                    size="large"
                                                    onClick={(e) => {
                                                        goToApply();
                                                    }}
                                                >
                                                    Start
                                                </Button>
                                            </p>
                                        </div>
                                    )}
                                </div>
                                {props.user &&
                                props.user.data.hasOwnProperty(
                                    "user_profile"
                                ) &&
                                props.user.data.user_profile !== null &&
                                Object.keys(props.user.data.user_profile)
                                    .length > 0 ? (
                                    <div className="z-dashboard-tab z-flex-column-center z-disabled">
                                        <p>
                                            Update <br /> KYC
                                            <Button
                                                className="z-landing-btn z-tab-button z-mb-6 z-new-active z-flex-row"
                                                shape="round"
                                                size="large"
                                            >
                                                Continue
                                            </Button>
                                        </p>
                                    </div>
                                ) : (
                                    <div
                                        className="z-dashboard-tab z-flex-column-center"
                                        onClick={(e) => {
                                            setTabNum(2);
                                        }}
                                    >
                                        <p>
                                            Update <br /> KYC
                                            <Button
                                                className="z-landing-btn z-tab-button z-mb-6 z-new-active z-flex-row"
                                                shape="round"
                                                size="large"
                                            >
                                                Continue
                                            </Button>
                                        </p>
                                        {tabNum === 2 && (
                                            <div className="z-tab-active z-flex-column-center">
                                                <p>
                                                    Update <br /> KYC
                                                    <Button
                                                        className="z-landing-btn z-tab-button z-mb-6 z-button-gradient z-gradient4 z-flex-row z-tab-button-active"
                                                        shape="round"
                                                        size="large"
                                                        onClick={(e) => {
                                                            goToProfile();
                                                        }}
                                                    >
                                                        Continue
                                                    </Button>
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}

                                <div
                                    className="z-dashboard-tab z-flex-column-center"
                                    onClick={(e) => {
                                        setTabNum(3);
                                    }}
                                >
                                    <p>
                                        Apply for <br /> Loan
                                        <Button
                                            className="z-landing-btn z-tab-button z-mb-6 z-new-active z-flex-row"
                                            shape="round"
                                            size="large"
                                        >
                                            Apply now
                                        </Button>
                                    </p>
                                    {tabNum === 3 && (
                                        <div className="z-tab-active z-flex-column-center">
                                            <p>
                                                Apply for <br /> Loan
                                                <Button
                                                    className="z-landing-btn z-tab-button z-mb-6 z-button-gradient z-gradient3 z-flex-row z-tab-button-active"
                                                    shape="round"
                                                    size="large"
                                                    onClick={(e) => {
                                                        goToApply();
                                                    }}
                                                >
                                                    Apply now
                                                </Button>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-md-8 z-res-w-100_">
                            <div
                                className="z-dashboard-panel z-mb-5 z-width-100 z-res-w-100 z-panel-res"
                                style={{ height: "auto" }}
                            >
                                <h1 className="z-dashboard-label">Dashboard</h1>
                                <div className="z-flex-start mb-4">
                                    <img
                                        src={overviewIcon}
                                        className="img-fluid mr-3"
                                        alt="Overview Icon"
                                    />
                                    <span className="z-label-text">
                                        Overview
                                    </span>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 z-new-tab mb-3">
                                        <div className="z-flex-space z-mb-6">
                                            <span className="text-left card-title">
                                                Total borrowed loans
                                            </span>
                                            <img
                                                src={arrow}
                                                alt="Arrow"
                                                className="img-fluid text-right"
                                                style={{ width: "20px" }}
                                            />
                                        </div>
                                        <div className="z-flex-start-base z-ml-10">
                                            <img
                                                src={naira}
                                                className="img-fluid"
                                                alt="Naira symbol"
                                            />
                                            <span className="z-new-amount">
                                                {formatCurrency(payBack)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-5 z-new-tab mb-3">
                                        <div className="z-flex-space z-mb-6">
                                            <span className="text-left card-title">
                                                Total guaranteed loans
                                            </span>
                                            <img
                                                src={arrow}
                                                alt="Arrow"
                                                className="img-fluid text-right"
                                                style={{ width: "20px" }}
                                            />
                                        </div>
                                        <div className="z-flex-start-base z-ml-10">
                                            <img
                                                src={naira}
                                                className="img-fluid"
                                                alt="Naira symbol"
                                            />
                                            <span className="z-new-amount">
                                                {formatCurrency(guaranteedLoan)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <img
                                            src={applyLoan}
                                            className="img-fluid"
                                            onClick={(e) => goToApply()}
                                            style={{ cursor: "pointer" }}
                                            alt="Apply for loan"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 z-res-w-100_">
                            <div
                                className="z-dashboard-panel z-dash-p z-res-w-100 z-panel-res"
                                style={{ height: "auto" }}
                            >
                                <div className="z-flex-start z-dashboard-side">
                                    <p className="z-label-text mt-4 mb-1">
                                        Payment progress
                                    </p>
                                </div>
                                <div className="row z-flex-start">
                                    <div className="col-md-12 z-dashboard-progress-div z-flex-column-center">
                                        <Progress
                                            type="circle"
                                            percent={progressObj.percentage}
                                            className="z-progress"
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-md-6 mt-2">
                                        <p className="z-label-small">
                                            Left to Pay
                                        </p>
                                        <p className="z-label-big">
                                            <img
                                                src={naira}
                                                className="img-fluid"
                                                alt="Naira symbol"
                                                style={{ height: "12px" }}
                                            />
                                            <span style={{ fontSize: "13px" }}>
                                                {formatCurrency(
                                                    progressObj.unpaid
                                                )}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <div className="">
                                            <p className="z-label-small">
                                                Paid
                                            </p>
                                            <p className="z-label-big">
                                                <img
                                                    src={naira}
                                                    className="img-fluid"
                                                    alt="Naira symbol"
                                                    style={{ height: "12px" }}
                                                />
                                                <span
                                                    style={{ fontSize: "13px" }}
                                                >
                                                    {formatCurrency(
                                                        progressObj.paid
                                                    )}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <p className="z-label-small">
                                            Next Installment
                                        </p>
                                        {progressObj.pending.length > 0 && (
                                            <p className="z-label-small">
                                                Date:{" "}
                                                {progressObj.pending.length >
                                                    0 &&
                                                    progressObj.pending[0]
                                                        .payback_date}
                                            </p>
                                        )}
                                        <p className="z-label-big">
                                            <img
                                                src={naira}
                                                className="img-fluid"
                                                alt="Naira symbol"
                                                style={{ height: "12px" }}
                                            />
                                            <span style={{ fontSize: "13px" }}>
                                                {progressObj.pending.length > 0
                                                    ? formatCurrency(
                                                          progressObj.pending[0]
                                                              .amount_due
                                                      )
                                                    : 0.0}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Skeleton>
        </Fragment>
    );
};
/**
 * function to map the state to props
 * */
function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(DashboardHome);
