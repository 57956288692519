import React, { useEffect } from "react";
import logo from "../../assets/images/placeholder_logo.png";
import { Layout, Menu } from "antd";
import { NavLink } from "react-router-dom";
import Icon1 from "../../assets/images/icons-12.png";
import Icon8 from "../../assets/images/white icons-14.png";
import Icon9 from "../../assets/images/white icons-10.png";
import Icon7 from "../../assets/images/white icons-07.png";
import Icon10 from "../../assets/images/white icons-15.png";
import Icon11 from "../../assets/images/white icons-11.png";
import Icon6 from "../../assets/images/icons-17.png";
import Icon5 from "../../assets/images/icons-16.png";
import { MdOutlinePlaylistRemove } from "react-icons/md";
const { Sider } = Layout;
const SubMenu = Menu.SubMenu;

export const AdminNav = (props) => {
    const {
        sideBarCollapsed,
        showFull,
        onNavClick,
        showlogModal,
        currentNav,
        currentSub,
    } = props;
    useEffect(() => {
        console.log("CurrectNav", currentNav);
    }, []);
    return (
        <Sider trigger={null} collapsible collapsed={sideBarCollapsed}>
            <div className="logo">
                <img src={logo} alt="logo" />
            </div>
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[currentNav.toString()]}
                defaultOpenKeys={[currentSub.toString()]}
            >
                <div className="z-nav-hr">
                    <hr />
                </div>
                <Menu.Item
                    key="1"
                    onClick={(e) => {
                        onNavClick(e, 1, "");
                    }}
                >
                    <NavLink to="/dashboard/admin" className="z-nav">
                        <div className="z-nav-icon">
                            <img src={Icon1} alt="icon" />
                        </div>
                        <span>Dashboard</span>
                    </NavLink>
                </Menu.Item>
                <Menu.Item
                    key="2"
                    onClick={(e) => {
                        onNavClick(e, 2, "");
                    }}
                >
                    <NavLink to="/dashboard/admin/requests" className="z-nav">
                        <div className="z-nav-icon">
                            <img src={Icon8} alt="icon" />
                        </div>
                        <span>Loan requests</span>
                    </NavLink>
                </Menu.Item>
                <SubMenu
                    key="sub1"
                    className="z-pl-33"
                    title={
                        <NavLink
                            to="/dashboard/admin/companies"
                            className="z-nav"
                        >
                            <div className="z-nav-icon">
                                <img src={Icon9} alt="icon" />
                            </div>
                            <span>Companies</span>
                        </NavLink>
                    }
                >
                    <Menu.Item
                        key="3"
                        onClick={(e) => {
                            onNavClick(e, 3, "sub1");
                        }}
                    >
                        {" "}
                        <NavLink
                            to="/dashboard/admin/companies"
                            className="z-nav"
                        >
                            View all
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item
                        key="4"
                        onClick={(e) => {
                            onNavClick(e, 4, "sub1");
                        }}
                    >
                        {" "}
                        <NavLink
                            to="/dashboard/admin/new-company"
                            className="z-nav"
                        >
                            Add new
                        </NavLink>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    key="sub4"
                    className="z-pl-33"
                    title={
                        <NavLink
                            to="/dashboard/admin/user-transactions"
                            className="z-nav"
                        >
                            <div className="z-nav-icon">
                                <img src={Icon9} alt="icon" />
                            </div>
                            <span>Transactions</span>
                        </NavLink>
                    }
                >
                    <Menu.Item
                        key="12"
                        onClick={(e) => {
                            onNavClick(e, 12, "sub4");
                        }}
                    >
                        {" "}
                        <NavLink
                            to="/dashboard/admin/user-transactions"
                            className="z-nav"
                        >
                            Users
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item
                        key="13"
                        onClick={(e) => {
                            onNavClick(e, 13, "sub4");
                        }}
                    >
                        {" "}
                        <NavLink
                            to="/dashboard/admin/system-transactions"
                            className="z-nav"
                        >
                            System
                        </NavLink>
                    </Menu.Item>
                </SubMenu>
                <Menu.Item
                    key="9"
                    onClick={(e) => {
                        onNavClick(e, 9, "");
                    }}
                >
                    <NavLink
                        to="/dashboard/admin/loan-schedules"
                        className="z-nav"
                    >
                        <div className="z-nav-icon">
                            <img src={Icon1} alt="icon" />
                        </div>
                        <span>Loan Schedules</span>
                    </NavLink>
                </Menu.Item>
                <SubMenu
                    key="sub2"
                    className="z-pl-33"
                    title={
                        <NavLink
                            to="/dashboard/admin/packages"
                            className="z-nav"
                        >
                            <div className="z-nav-icon">
                                <img src={Icon7} alt="icon" />
                            </div>
                            <span>Loan Packages</span>
                        </NavLink>
                    }
                >
                    <Menu.Item
                        key="5"
                        onClick={(e) => {
                            onNavClick(e, 5, "sub2");
                        }}
                    >
                        {" "}
                        <NavLink
                            to="/dashboard/admin/packages"
                            className="z-nav"
                        >
                            View all
                        </NavLink>
                    </Menu.Item>
                </SubMenu>
                <Menu.Item
                    key="11"
                    onClick={(e) => {
                        onNavClick(e, 11, "");
                    }}
                >
                    <NavLink to="/dashboard/admin/blacklist" className="z-nav">
                        <div className="z-nav-icon">
                            <MdOutlinePlaylistRemove />
                        </div>
                        <span>Blacklist</span>
                    </NavLink>
                </Menu.Item>
                <div className="z-nav-hr">
                    <hr />
                </div>
                <Menu.Item
                    key="7"
                    onClick={(e) => {
                        onNavClick(e, 7, "");
                    }}
                >
                    <NavLink to="/dashboard/admin/customers" className="z-nav">
                        <div className="z-nav-icon">
                            <img src={Icon10} alt="icon" />
                        </div>
                        <span>Customers</span>
                    </NavLink>
                </Menu.Item>
                <Menu.Item
                    key="8"
                    onClick={(e) => {
                        onNavClick(e, 8, "");
                    }}
                >
                    <NavLink
                        to="/dashboard/admin/administrators"
                        className="z-nav"
                    >
                        <div className="z-nav-icon">
                            <img src={Icon11} alt="icon" />
                        </div>
                        <span>Administrators</span>
                    </NavLink>
                </Menu.Item>
                <Menu.Item key="14" onClick={(e) => onNavClick(e, "14", "")}>
                    <NavLink to="/dashboard/admin/settings" className="z-nav">
                        <div className="z-nav-icon">
                            <img src={Icon5} alt="icon" />
                        </div>
                        <span>Settings</span>
                    </NavLink>
                </Menu.Item>
                <Menu.Item
                    key="10"
                    className="z-flex-start"
                    onClick={(e) => {
                        showlogModal(e);
                    }}
                >
                    <div className="z-nav-icon">
                        <img src={Icon6} alt="icon" />
                    </div>
                    <span>Log out</span>
                </Menu.Item>
            </Menu>
        </Sider>
    );
};
