import React, {Component} from 'react';
import {Button, Col, Icon, Input, message, Row, Select, Skeleton, Upload, notification} from "antd";
import avatar from '../../../assets/images/placeholder_logo2.png'
import {addCompany, getIndustries, uploadFile} from "../../../APIsHandler/ApiController";
import mystates from "../../../assets/states"
import {BASE_URL} from '../../../APIsHandler/Urls';

const { TextArea } = Input;
const Option = Select.Option;

const openNotification = (type, title, description) =>{
    notification[type]({
        message: `${title}`,
        description: `${description}`,
    });
};


export default class CompanyForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            first_name: "Chioma",
            last_name: "Marcus",
            state_of_origin: "Anambra",
            password: "passwordjcjjjjjjjjjj",
            next_of_kin: "Durotoye Benedict",
            phone: "+234-8163318812",
            bvn: "466464747442",
            industriesLoading: true,
            industries: [],
            industry: '',
            company_name: '',
            address: '',
            city: mystates.data[0].state.locals[0].name,
            company_state: mystates.data[0].state.name,
            country: "",
            description: '',
            phone_number: '',
            imageUrl: avatar,
            email_domain: '',
            loading: false,
            file: {},
            fileList: [],
            fileId: "",
            pay_day: 1,
            states: mystates.data,
            cities: mystates.data[0].state.locals,
            errorsArr: []
        }
    }

    componentDidMount() {
        getIndustries(this.ongetCompanies)
    }

    onGetStates = (status, data) =>{
        if(status){
            this.setState({states: data})
        }else{
            message.error("unable to retrieve states")
        }
    }

    //user login callback
    ongetCompanies = (status, data) => {
        if(status){
            this.setState({industries: data, industriesLoading: false});
            // console.log(data)
        }else{
            this.setState({industriesLoading: false})
        }
    };

    //handle input change
    onInputChange = (e) =>{
        this.setState({[e.target.name]: e.target.value})
    }
    handleChange = (value, type) =>{
        this.setState({errorsArr: []})
        if(type === "industry") {
            this.setState({industry: value})
        }else if(type === "city"){
            this.setState({city: value})
        }else if(type === "company_state"){
            this.setState({company_state: value})
            const states = this.state.states

        const currentState = states.filter((state) => {
        return state.state.name === value})
            this.setState({cities: currentState[0].state.locals, city: currentState[0].state.locals[0].name})// getAllCities({state: value}, this.onGetCities)
        }else if(type === "country"){
            this.setState({country: value})
        }else if(type === "pay_day"){
            this.setState({pay_day: value})
        }
    }

    onGetCities = (status, data) =>{
        if(status){
            this.setState({cities: data})
        }else{
            message.error("unable to retrieve cities")
        }
    }

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    onChange = (info) =>{

        /*this_.setState({uploadClicked: true})*/
        if (info.file.status !== 'uploading') {
            console.log("file",info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            // Get this url from response in real world.
            this.setState({fileId: info.file.response.data})
            this.getBase64(info.file.originFileObj, imageUrl => this.setState({
                imageUrl,
                loading: false,
            }));

           //console the response
           //  console.log(info.file)
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    /**
     * handle the manual upload of the id card check
     * */
    beforeUpload = (file) =>{
        // console.log(file);

        const isPNG = file.type === 'image/png';
        const isJPG = file.type === 'image/jpeg';
        if (!isJPG && !isPNG) {
            message.error('You can only upload JPG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        this.setState(state => ({
            fileList: [...state.fileList, file],
        }));
        return (isJPG || isPNG) && isLt2M;
    }

    createCompany = () => {

        const {industry, company_name, address,fileId, city,company_state, country, description, phone_number, email_domain, pay_day} = this.state
const arr = [{industry: industry}, {company_name: company_name}, {address: address}, {city:city},{company_state:company_state}, {country: country},
    {phone_number: phone_number}, {email_domain: email_domain}, {pay_day:pay_day}]
        let errorsArr= []
        for(let value of arr){
            // console.log("err",Object.values(value)[0]);
            if(Object.values(value)[0].length < 1){
                errorsArr.push(Object.keys(value)[0])
            }
            this.setState({errorsArr})

        }
        // console.log("err", errorsArr);
        if(errorsArr.length > 0){
            return
        }

        this.setState({loading: true});

        const formData = new FormData();

            this.setState({file: formData});

        formData.append('logo', fileId);
        formData.append('description', description);
        formData.append('address', address);
        formData.append('city', city);
        formData.append('country', country);
        formData.append('state', company_state);
        formData.append('name', company_name);
        formData.append('industry_id', industry);
        formData.append('phone_number', phone_number);
        formData.append('email_domain', email_domain);
        formData.append('salary_payday', pay_day);


        addCompany(formData, this.onCreate)
    }

    //company creation callback
    onCreate = (status, data) => {
        if(status){

            // console.log(data)
            this.setState({industry: '', company_name: '', additional_notes:'', address: '', city: '', company_state: '',
                country: '', description: '', email_domain: '',  fileList: [], imageUrl: avatar, loading: false});
            openNotification("success", "Company created", "A new company was successfully created.")
        }else{
            this.setState({loading: false});
            openNotification("error", "Company creation failed", "Unable to create company."+data.data.message)
        }
    };

    uploadFile = (info) => {
        console.log('Inside custom value');
        console.log(info);
        let formData = new FormData();
        formData.append('uploadFile', info.file);
        uploadFile(formData).then( res => {
            info.onSuccess(res.data, info.file);
        }).catch( err => {
            console.log(err);
            info.onError('File upload issue', info.file);
        });
    };


    render() {
        const {industries, industriesLoading, industry, company_name, address, city,company_state, country, description, imageUrl,
            email_domain, loading, pay_day, fileId, states, cities, errorsArr, phone_number} = this.state;

        console.log("state", this.state);
        const uploadProps = {
            name: 'uploadFile',
            beforeUpload: this.beforeUpload,
            onChange: this.onChange,
            customRequest: this.uploadFile
        };
        return(
            <div>
                <div>
                    <h1 className="z-dashboard-label">
                        Companies
                    </h1>
                </div>
                <Row>
                    <Col span={22}>
                        <div className="z-dashboard-panel z-height-45 z-dash-p z-profile-card z-company-card">
                            <div className="z-flex-start z-fill-content z-mb-10">
                                <Icon type="copy" className="z-label-icon"/>
                                <p className="z-label-text">
                                    Add new company
                                </p>
                            </div>
                            <div className="z-form-label z-mb-5">
                                <p>Company Details</p>
                                <hr/>
                            </div>
                            <div className="z-pro-mar">
                                {/*<div>
                                <p className="z-profile-small">
                                    change picture
                                </p>
                            </div>*/}
                                <div className="z-mt-10">
                                    <Row className="z-p-p">
                                        <Col span={12} className="z-div-space">
                                            <Row>
                                                <Col span={24} className="z-mb-10">
                                                    <div className="z-testimonial-avatar z-profile-avatar z-mb-1 z-form-avatar">
                                                        <img src={imageUrl} alt="avatar"/>
                                                        <Upload {...uploadProps} className="z-upload-flex">
                                                            <div className="z-new-dIcon z-profile-dIcon">
                                                                <Icon type="arrows-alt" />
                                                            </div>
                                                        </Upload>
                                                    </div>
                                                </Col>
                                                <Col span={24}  className="z-mb-8">
                                                    <div className="">
                                                        <p className="z-profile-label z-mb--3">Company Name*</p>
                                                        <Input size="large" className="z-text-area" value={company_name} name="company_name" onChange={e => {this.onInputChange(e)}}/>
                                                        {errorsArr.includes("company_name") && <p className="color-red">This field is required</p>}
                                                    </div>
                                                </Col>
                                                <Col span={24}  className="z-mb-8">
                                                    <div className="">
                                                        <p className="z-profile-label z-mb--3">Industry*</p>
                                                        <Skeleton loading={industriesLoading} active>

                                                        <Select value={industry} style={{ width: "100%" }} onChange={e =>{this.handleChange(e, "industry")}}  className="z-text-area2">
                                                            {industries.map((coy, i) =>{
                                                                return(
                                                                    <Option value={coy.id} key={i}>{coy.name}</Option>
                                                                )
                                                            })}
                                                        </Select>
                                                            {errorsArr.includes("industry") && <p className="color-red">This field is required</p>}
                                                        </Skeleton>
                                                    </div>
                                                </Col>
                                                <Col span={24}  className="z-mb-8">
                                                    <div className="">
                                                        <p className="z-profile-label z-mb--3">Website*</p>
                                                        <Input size="large" className="z-text-area" value={email_domain} name="email_domain" onChange={e => {this.onInputChange(e)}}/>
                                                        {errorsArr.includes("email_domain") && <p className="color-red">This field is required</p>}
                                                    </div>
                                                </Col>
                                                <Col span={24}  className="z-mb-8">
                                                    <div className="">
                                                        <p className="z-profile-label z-mb--3">Phone Number*</p>
                                                        <TextArea autoSize={{ minRows: 1, maxRows: 6 }} className="z-text-area" name="phone_number"
                                                                  value={phone_number} onChange={e => {this.onInputChange(e)}}/>
                                                        {errorsArr.includes("phone_number") && <p className="color-red">This field is required</p>}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={12} className="z-div-space-right">
                                            <Row>
                                                <Col span={24} className="z-mb-8">
                                                    <div className="">
                                                        <p className="z-profile-label z-mb--3">HQ Address*</p>
                                                        <TextArea autoSize={{ minRows: 4, maxRows: 6 }} className="z-text-area" name="address"
                                                                  value={address} onChange={e => {this.onInputChange(e)}}/>
                                                        {errorsArr.includes("address") && <p className="color-red">This field is required</p>}
                                                    </div>
                                                </Col>
                                                <Col span={24}  className="z-mb-9">
                                                    <div className="">
                                                        <p className="z-profile-label z-mb--3">Country*</p>
                                                        <Select value={country} style={{ width: "100%" }}
                                                                onChange={e =>{this.handleChange(e, "country")}}  className="z-text-area2">
                                                            <Option value="Nigeria">Nigeria</Option>
                                                        </Select>
                                                        {errorsArr.includes("country") && <p className="color-red">This field is required</p>}
                                                    </div>
                                                </Col>
                                                <Col span={24}  className="z-mb-8">
                                                    <div className="z-flex-space">
                                                        <div className="">
                                                            <p className="z-profile-label z-mb-9">State*</p>
                                                            <Select value={company_state} style={{ width: "130px" }} onChange={e =>{this.handleChange(e, "company_state")}}  className="z-text-area2">
                                                                {states.map((state, i )=> (
                                                                    <Option value={state.state.name} key={i}>{state.state.name}</Option>))}

                                                            </Select>
                                                            {errorsArr.includes("company_state") && <p className="color-red">This field is required</p>}
                                                        </div>
                                                        <div className="">
                                                            <p className="z-profile-label z-mb-8">City*</p>
                                                            <Select value={city} name="city" style={{ width: "130px" }} onChange={e =>{this.handleChange(e,"city")}}  className="z-text-area2">
                                                                {cities.map((city, i )=> (
                                                                    <Option value={city.name} key={i}>{city.name}</Option>))}
                                                            </Select>
                                                            {errorsArr.includes("city") && <p className="color-red">This field is required</p>}
                                                        </div>

                                                    </div>
                                                </Col>

                                                <Col span={24}  className="z-mb-8">
                                                    <div className="">
                                                        <p className="z-profile-label z-mb--3">Short Description</p>
                                                        <TextArea autoSize={{ minRows: 2, maxRows: 6 }} name="description"  onChange={e => {this.onInputChange(e)}}
                                                                  className="z-text-area" value={description}/>
                                                    </div>
                                                </Col>
                                                <Col span={24}  className="z-mb-8">
                                                    <div className="">
                                                        <p className="z-profile-label z-mb--3">Pay Day (Day of the Month)*</p>
                                                        <Select value={pay_day} style={{ width: "100%" }}
                                                                onChange={e =>{this.handleChange(e, "pay_day")}}  className="z-text-area2">
                                                            <Option value="1">1</Option>
                                                            <Option value="2">2</Option>
                                                            <Option value="3" >3</Option>
                                                            <Option value="4">4</Option>
                                                            <Option value="5">5</Option>
                                                            <Option value="6" >6</Option>
                                                            <Option value="7">7</Option>
                                                            <Option value="8">8</Option>
                                                            <Option value="9" >9</Option>
                                                            <Option value="10">10</Option>
                                                            <Option value="11">11</Option>
                                                            <Option value="12" >12</Option>
                                                            <Option value="13">13</Option>
                                                            <Option value="14">14</Option>
                                                            <Option value="15" >15</Option>
                                                            <Option value="16">16</Option>
                                                            <Option value="17">17</Option>
                                                            <Option value="18" >18</Option>
                                                            <Option value="19">19</Option>
                                                            <Option value="20">20</Option>
                                                            <Option value="21" >21</Option>
                                                            <Option value="22">22</Option>
                                                            <Option value="23">23</Option>
                                                            <Option value="24" >24</Option>
                                                            <Option value="25">25</Option>
                                                            <Option value="26" >26</Option>
                                                            <Option value="27">27</Option>
                                                            <Option value="28">28</Option>
                                                            <Option value="29" >29</Option>
                                                            <Option value="30">30</Option>
                                                            <Option value="31" >31</Option>
                                                        </Select>
                                                        {errorsArr.includes("pay_day") && <p className="color-red">This field is required</p>}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col offset={5} span={16} className="z-mt-13">
                                            <Row>
                                                <Col span={12} >
                                                    <Button className="z-landing-btn z-section-btn z-profile-button z-button-plain2 z-company-button" shape="round" size="large">Cancel</Button>
                                                </Col>
                                                <Col span={12} >
                                                    <Button className="z-landing-btn z-section-btn z-profile-button z-company-gradient z-company-button"
                                                            shape="round" size="large" loading={loading} onClick={e => {this.createCompany(e)}}
                                                    disabled={fileId.length < 0 || pay_day.length < 0 || description.length < 0 || address.length < 0 ||
                                                    city.length < 0 || country.length < 0 || company_name.length < 0 || company_state.length < 0 ||
                                                    phone_number.length < 0 || email_domain.length < 0 || industry.length < 0}>Add Company</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}