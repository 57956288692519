import React, {Fragment} from "react";

import logo from "../../assets/images/placeholder_logo.png";
import {Icon, Layout, Menu} from "antd";
import {NavLink} from "react-router-dom";
import Icon1 from "../../assets/images/icons-12.png";
import Icon2 from "../../assets/images/icons-13.png";
import Icon7 from "../../assets/images/white icons-07.png";
import Icon3 from "../../assets/images/icons-14.png";
import Icon4 from "../../assets/images/icons-15.png";
import Icon5 from "../../assets/images/icons-16.png";
import Icon6 from "../../assets/images/icons-17.png";
import DefaultAvatar from "../../assets/images/unisex_avatar_icon.png";
import { FaPeopleArrows } from "react-icons/fa";

const { Sider } = Layout;
const UserNav = (props) => {
    const {
        sideBarCollapsed,
        showFull,
        showDrp,
        onNavClick,
        currentNav,
        showlogModal,
        gotoProfile,
        user,
        closeDetails,
        showDetails,
    } = props;
    return (
        <Fragment>
            <Sider
                trigger={null}
                collapsible
                collapsed={sideBarCollapsed}
                className={showFull && "z-show"}
            >
                <div className="logo">
                    <img src={logo} alt="logo" />
                </div>
                <div>
                    <div className="z-flex-start z-mb-4">
                        <div className="z-testimonial-avatar z-dashboard-avatar">
                            <img
                                src={
                                    user.data.user_profile !== null
                                        ? user.data.user_profile.avatar
                                            ? user.data.user_profile.avatar
                                            : DefaultAvatar
                                        : DefaultAvatar
                                }
                                alt="logo"
                            />
                        </div>
                        {showDrp ? (
                            <div
                                className={
                                    showFull || window.innerWidth >= 1000
                                        ? "z-nav-i z-cursor-poiner"
                                        : "z-nav-i z-cursor-poiner z-nav-none"
                                }
                                onClick={(e) => closeDetails()}
                            >
                                {" "}
                                <Icon type="right" />
                            </div>
                        ) : (
                            <div
                                className={
                                    showFull || window.innerWidth >= 1000
                                        ? "z-nav-i z-cursor-poiner"
                                        : "z-nav-i z-cursor-poiner z-nav-none"
                                }
                                onClick={(e) => showDetails()}
                            >
                                {" "}
                                <Icon type="down" />
                            </div>
                        )}
                    </div>
                    {showDrp && (
                        <div className="z-nav-dropdown">
                            <p className="z-header-dropdown">
                                {user.data.firstname} {user.data.lastname}
                            </p>
                            <p className="z-header-dropdown-p">
                                {user.data.company.name}
                            </p>
                            <p className="z-header-dropdown-p">
                                {user.data.email}
                            </p>
                            <p
                                className="z-profile-small z-left z-no-margin"
                                onClick={(e) => gotoProfile()}
                            >
                                Edit profile
                            </p>
                        </div>
                    )}
                </div>
                <Menu theme="dark" mode="inline">
                    <div className="z-nav-hr">
                        <hr />
                    </div>
                    <Menu.Item
                        key="1"
                        onClick={(e) => {
                            onNavClick(e, "1", "");
                        }}
                    >
                        <NavLink to="/dashboard" className="z-nav">
                            <div className="z-nav-icon">
                                <img src={Icon1} alt="icon" />
                            </div>
                            <span>Dashboard</span>
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item
                        key="2"
                        onClick={(e) => {
                            onNavClick(e, "2", "");
                        }}
                    >
                        <NavLink
                            to="/dashboard/repayment-options"
                            className="z-nav"
                        >
                            <div className="z-nav-icon">
                                <img src={Icon2} alt="icon" />
                            </div>
                            <span>Apply</span>
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item
                        key="3"
                        onClick={(e) => {
                            onNavClick(e, "3", "");
                        }}
                    >
                        <NavLink to="/dashboard/loans" className="z-nav">
                            <div className="z-nav-icon">
                                <img src={Icon7} alt="icon" />
                            </div>
                            <span>My Loans</span>
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item
                        key="4"
                        onClick={(e) => {
                            onNavClick(e, "4", "");
                        }}
                    >
                        <NavLink to="/dashboard/guarantor" className="z-nav">
                            <div className="z-nav-icon">
                                <img src={Icon3} alt="icon" />
                            </div>
                            <span>Requests</span>
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item
                        key="5"
                        onClick={(e) => {
                            onNavClick(e, "5", "");
                        }}
                    >
                        <NavLink
                            to="/dashboard/notifications"
                            className="z-nav"
                        >
                            <div className="z-nav-icon">
                                <img src={Icon4} alt="icon" />
                            </div>
                            <span>Notifications</span>
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="6" onClick={(e) => onNavClick(e, "6", "")}>
                        <NavLink to="/dashboard/wallet" className="z-nav">
                            <div className="z-nav-icon">
                                <img src={Icon4} alt="icon" />
                            </div>
                            <span>Wallet</span>
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="9" onClick={(e) => onNavClick(e, "9", "")}>
                        <NavLink to="/dashboard/referral" className="z-nav">
                            <div className="z-nav-icon">
                                <FaPeopleArrows />
                            </div>
                            <span>Referrals</span>
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="7" onClick={(e) => onNavClick(e, "7", "")}>
                        <NavLink to="/dashboard/settings" className="z-nav">
                            <div className="z-nav-icon">
                                <img src={Icon5} alt="icon" />
                            </div>
                            <span>Settings</span>
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item
                        key="8"
                        className="z-flex-start"
                        onClick={(e) => showlogModal(e)}
                    >
                        <div className="z-nav-icon">
                            <img src={Icon6} alt="icon" />
                        </div>
                        <span>Log out</span>
                    </Menu.Item>
                </Menu>
            </Sider>
        </Fragment>
    );
};

export default UserNav;