import {Button, Modal} from "antd";
import React from "react";


export const LogOutModal = (props) => {
    const {logOut, logUserOut, userLogOut} = props;
    return (
        <Modal className="z-custom z-log-modal z-flex-column-center"
               visible={logOut}
               title={null}
               // onOk={}
               onCancel={e => {
                   logUserOut(e)
               }}
               footer={null}
        >
            <div className="">
                <p className="z-modal-text">
                    Are you sure you want to log out?
                </p>
            </div>
            <div className="z-flex-row z-mt-8 ">
                <Button
                    loading={!logOut}
                    className="z-landing-btn z-modal-btn z-mr-5"
                    shape="round" size="large" onClick={e => {
                    userLogOut(e)
                }}>Yes</Button>
                <Button
                    className="z-landing-btn z-modal-btn z-btn-nobg"
                    shape="round" size="large" onClick={e => {
                   logUserOut(e)
                }}>No</Button>
            </div>
        </Modal>
    )
}