import React, {useState, useEffect, Fragment} from 'react';
import {Icon, Row, Col, DatePicker, message, Skeleton} from "antd";
import {getLoanRequest} from "../../../APIsHandler/ApiController";
import {formatCurrency, naira} from "../../globalComponents/globalFunctions";
import Icon1 from "../../../assets/images/black icons-01.png";
import overviewIcon from "../../../assets/images/dashboard/overview.svg";

const {RangePicker} = DatePicker;
const AdminHome = (props) => {
    const [collapsed, setCollapsed] = useState(false);
    const [todayDate, setTodayDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [usersLoans, setUsersLoans] = useState([]);
    const [approvedLoans, setApprovedLoans] = useState(0);
    const [deniedLoans, setDeniedLoans] = useState(0);
    const [pendingLoans, setPendingLoans] = useState(0);
    const [payback, setPayback] = useState(0);
    const [totalPaid, setTotalPaid] = useState(0);
    const [paidCustomers, setPaidCustomers] = useState(0);
    const [allCustomers, setAllCustomers] = useState(0);

    useEffect(() => {
        getLoanRequest().then( res => {
            let {data} = res.data;
            const loans =  data.filter(loan => loan.status === "approved");
            const loansDenied =  data.filter(loan => loan.status === "declined");
            const loansPending =  data.filter(loan => loan.status === "pending");
            const loansPaid =  data.filter(loan => loan.payment_status === "paid");

            let totalAmount = loans.reduce(function (accumulator, loan) {
                return accumulator + loan.loan_package.total_payback;
            }, 0);

            let totalPaid = loansPaid.reduce(function (accumulator, loan) {
                return accumulator + loan.loan_package.total_payback;
            }, 0);

            setPayback(Number(totalAmount));
            setAllCustomers(loans.length);
            setPaidCustomers(loansPaid.length);
            setTotalPaid(Number(totalPaid));
            setDeniedLoans(loansDenied !== null ? loansDenied.length : 0);
            setPendingLoans(loansPending !== null ? loansPending.length : 0);
            setApprovedLoans(loans !== null ? loans.length : 0);
            setUsersLoans(data);
            setLoading(false);
        }).catch( err => {
            message.error('Unable to load page', 3);
            setLoading(false);
        })
    }, []);

    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    const data = [
            {
                name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
            },
            {
                name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
            },
            {
                name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
            },
            {
                name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
            },
            {
                name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
            },
            {
                name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
            },
            {
                name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
            },
        ];

    return (
        <Fragment>
            <Skeleton loading={loading} active>
                <div>
                    <div className="row mt-4">
                        <div className="col-md-8">
                            <div className="z-dashboard-panel z-mb-5 z-width-100 z-res-w-100 z-admin-panel" style={{ height: "auto"}}>
                                <h1 className="z-dashboard-label">
                                    Dashboard
                                </h1>
                                <div className="z-flex-start mb-4">
                                    <img src={overviewIcon} className="img-fluid mr-3" alt="Overview Icon"/>
                                    <span className="z-label-text">
                                        Overview
                                    </span>
                                </div>
                                <div className="z-flex-end">
                                </div>
                                <div className="z-flex-space">
                                    <div className="z-width-100 z-flex-row mb-4">
                                        <div className="z-admin-tab mb-3" >
                                            <div className="z-flex-space z-mb-8">
                                                <p>
                                                    Loaned
                                                </p>
                                                <div className="z-flex-column-center z-icon-skeleton">
                                                    <Icon type="arrow-up" />
                                                </div>
                                            </div>
                                            <div className="z-flex-start-base z-mb-25">
                                                <p>
                                                    {naira}
                                                </p>
                                                <p className="z-new-amount z-letter-spacing">
                                                    {formatCurrency(payback)}
                                                </p>
                                            </div>
                                            <div className="z-bottom-label">
                                                <p>{allCustomers} customer (s)</p>
                                            </div>
                                        </div>
                                        <div className="z-admin-tab mb-3" >
                                            <div className="z-flex-space z-mb-8">
                                                <p>Paid</p>
                                                <div className="z-flex-column-center z-icon-skeleton z-icon-purple">
                                                    <Icon type="arrow-down" />
                                                </div>
                                            </div>
                                            <div className="z-flex-start-base z-mb-25">
                                                <p>{naira}</p>
                                                <p className="z-new-amount z-letter-spacing ">
                                                    {formatCurrency(totalPaid)}
                                                </p>
                                            </div>
                                            <div className="z-bottom-label">
                                                <p>{paidCustomers} customer (s)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="z-dashboard-panel z-dash-p z-ml-5 z-admin-panel z-res-w-100" style={{ height: "auto"}}>
                                <div className="z-flex-start z-mb-10 z-dashboard-side">
                                    <p className="z-label-text">
                                        Loan Requests
                                    </p>
                                </div>
                                <div className="z-flex-space z-mb-7">
                                    <div className="z-admin-tab z-admin-tab-small" >
                                        <div className="z-mb-7">
                                            <p className="z-color-gray2">
                                                All requests
                                            </p>
                                        </div>
                                        <div className="z-flex-space">
                                            <p className="z-new-amount z-letter-spacing ">
                                                {usersLoans.length}
                                            </p>
                                            <div className="z-new-dIcon z-bg-purple z-admin-i">
                                                <Icon type="copy" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="z-admin-tab z-admin-tab-small" >
                                        <div className="z-mb-7">
                                            <p className="z-color-gray2">
                                                Pending
                                            </p>
                                        </div>
                                        <div className="z-flex-space">
                                            <p className="z-new-amount z-letter-spacing ">
                                                {pendingLoans}
                                            </p>
                                            <div className="z-new-dIcon z-bg-skyblue z-admin-i">
                                                <Icon type="clock-circle" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="z-flex-space">
                                    <div className="z-admin-tab z-admin-tab-small" >
                                        <div className="z-mb-7">
                                            <p className="z-color-gray2">
                                                Approved
                                            </p>
                                        </div>
                                        <div className="z-flex-space">
                                            <p className="z-new-amount z-letter-spacing ">
                                                {approvedLoans}
                                            </p>
                                            <div className="z-new-dIcon z-bg-green z-admin-i">
                                                <Icon type="like" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="z-admin-tab z-admin-tab-small" >
                                        <div className="z-mb-7">
                                            <p className="z-color-gray2">
                                                Declined
                                            </p>
                                        </div>
                                        <div className="z-flex-space">
                                            <p className="z-new-amount z-letter-spacing ">
                                                {deniedLoans}
                                            </p>
                                            <div className="z-new-dIcon z-bg-blue z-admin-i">
                                                <Icon type="dislike" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Skeleton>
        </Fragment>
    )
};
export default AdminHome;