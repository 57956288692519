import React, {Fragment, useState} from "react";
import GettingStarted from "./GettingStarted";
import ApplyLoan from "./ApplyForLoans/ApplyLoan";
import GuarantorGuide from "./GuarantorGuide/GuarantorGuide";
import "../../homepage/HowTos/Main.scss"

export default function Main(props) {
    const [pageContent, setPageContent] = useState('gettingStarted');


    let page;
    if (pageContent === "gettingStarted") {
        page  = <GettingStarted {...props} setPageContent={setPageContent}/>
    }

    if(pageContent === "applyLoan") {
        page = <ApplyLoan {...props} setPageContent={setPageContent}/>
    }

    if(pageContent === "guarantorGuide") {
        page = <GuarantorGuide {...props} setPageContent={setPageContent}/>
    }

    return (
        <Fragment>
            {page}
        </Fragment>

    )
}