import React, {Fragment} from 'react';
import Header from "../GuarantorGuide/Header";
import Stepper from "./Stepper";
import guarantorGuideBG from "../../../../assets/images/getting-help/guarantorGuide3.png";

const ThirdStep = (props) => {
    const {setCurrentPageStep, setPageContent} = props;

    return (
        <Fragment>
            <div className="page">
                <Header setPageContent={setPageContent}/>
                <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="circleNum mb-4">3</div>
                        <div className="help-topic">Add Bank Details</div>
                        <p className="help-content">
                            Proceed to add your salary and card details
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-7">
                        <div>
                            <img src={guarantorGuideBG} alt="Background Image" className="img-fluid top-img"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Stepper setCurrentPageStep={setCurrentPageStep} active="3"/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ThirdStep;