import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import { Provider } from 'react-redux'
import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import combinedReducers from "./reduxController/reducers";
import {setCurrentUser} from "./reduxController/actions/userActions";
import {setAuthorisationToken} from "./APIsHandler/ApiController";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//create the redux store
const store = createStore(
    combinedReducers, composeEnhancers(applyMiddleware(thunk))
);

//load the user data from local storage to redux
if (localStorage.getItem('sfs_tk') && localStorage.getItem('sfs_tk')!== undefined && localStorage.getItem('sfs_user') &&
    localStorage.getItem('sfs_user') !== undefined) {
        console.log('Authorized');
        setAuthorisationToken(localStorage.getItem('sfs_tk'));
        store.dispatch(setCurrentUser(JSON.parse(localStorage.getItem('sfs_user'))));
} else {
    console.log('Unauthorized');
    setAuthorisationToken();
}
function noop() {}

if (process.env.NODE_ENV !== 'development') {
    console.log = noop;
    console.warn = noop;
    console.error = noop;
}
toast.configure();


ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <App/>
        </Provider>
    </BrowserRouter>, document.getElementById('root'));
serviceWorker.unregister();