import React, {Fragment} from "react";
import applyLoanBG from "../../../../assets/images/getting-help/applyLoan2.png";
import Stepper from "./Stepper";
import Header from "./Header";

const SecondStep = (props) => {
    const {setCurrentPageStep, setPageContent} = props;

    return (
        <Fragment>
            <div className="page">
                <Header setPageContent={setPageContent} />
                <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="circleNum mb-4">2</div>

                        <div className="help-topic">Fill in your information</div>
                        <p className="help-content">After selecting your company name, fill in your full name, Email (Email must
                            correspond to your company) and password.
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-7">
                        <div>
                            <img src={applyLoanBG} alt="Background Image" className="img-fluid top-img"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Stepper setCurrentPageStep={setCurrentPageStep} active="2"/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default SecondStep;