import React, {Fragment} from 'react';
import Header from "../GuarantorGuide/Header";
import Stepper from "./Stepper";
import guarantorGuideBG from "../../../../assets/images/getting-help/guarantorGuide2.png";

const SecondStep = (props) => {
    const {setCurrentPageStep, setPageContent} = props;
    return (
        <Fragment>
            <div className="page">
                <Header setPageContent={setPageContent} />
                <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="circleNum mb-4">2</div>

                        <div className="help-topic">See all requests</div>
                        <p className="help-content">On your dashboard,
                            click on "request" from the sidebar and
                            then "view request" from the list of pending requests.
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-7">
                        <div>
                            <img src={guarantorGuideBG} alt="Background Image" className="img-fluid top-img"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Stepper setCurrentPageStep={setCurrentPageStep} active="2"/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SecondStep;