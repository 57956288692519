import React, { useEffect, useState } from "react";
import { Button, Icon, Modal, Popconfirm, message, Row } from "antd";
import { cancelLoanRequest } from "../../../APIsHandler/ApiController";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";
import PaybackSummary from "./PaybackSummary";
import UserLoanMainDetails from "./UserLoanMainDetails";

export default function UserLoanDetails({
    isVisible,
    closeModal,
    usersLoan,
    fetchLoanRequest,
}) {
    const [loadingCancelLoan, setLoadingCancelLoan] = useState(false);
    const [tab, setTab] = useState("main");

    useEffect(() => {
        // when the modal opens or closes it should default back to the main tab
        setTab("main");
    }, [isVisible]);

    function handleCancelLoan(loanRequestId) {
        setLoadingCancelLoan(true);

        cancelLoanRequest(loanRequestId)
            .then(() => {
                setLoadingCancelLoan(false);

                closeModal();
                fetchLoanRequest();
                message.success("Your loan request has been cancelled");
            })
            .catch((error) => {
                setLoadingCancelLoan(false);

                message.error(
                    `Unable to cancel loan request.  ${
                        error.data !== undefined ? error.data.message : ""
                    }. Please try again.`,
                    3
                );
            });
    }

    function isCancelButtonDisabled(usersLoan) {
        return usersLoan
            ? ["approved", "cancelled", "declined"].includes(usersLoan.status)
                ? true
                : false
            : false;
    }

    function changeTab(tabId) {
        setTab(tabId);
    }

    return (
        <Modal
            className="z-custom z-custom2 z-custom3 "
            visible={isVisible}
            title={"w"}
            onCancel={closeModal}
            footer={[
                <Button
                    className="z-landing-btn z-section-btn z-profile-button z-loan-btn"
                    shape="round"
                    size="large"
                    onClick={closeModal}
                    key={1}
                >
                    Close
                </Button>,
            ]}
        >
            {usersLoan && (
                <>
                    <div className="z-flex-space z-modal-label-div">
                        <div>
                            <p className="z-loan-header">Loan Summary</p>
                        </div>
                        <div>
                            <p className="z-loan-header z-loan-header-small">
                                {usersLoan.loan_package.name}
                            </p>
                        </div>
                    </div>
                    <Row>
                        <div className="z-flex-space z-gr-p z-mt-3 z-mb-3">
                            <div>
                                <div className="z-aside-tab z-flex-even z-ml-5">
                                    <div className="z-flex-column-center">
                                        {" "}
                                        <p className="z-loan-p3">Loan amount</p>
                                        <p className="z-loan-p">
                                            {formatCurrency(
                                                usersLoan.amount ||
                                                    usersLoan.loan_package
                                                        .amount
                                            )}{" "}
                                            &nbsp;&nbsp;
                                            <span className="z-loan-p3 z-font-xs">
                                                {naira}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    data-preset="fan"
                                    className="ldBar label-center"
                                    data-value="0"
                                >
                                    <Popconfirm
                                        title="Are you sure you want to cancel this Loan Request?"
                                        onConfirm={() =>
                                            handleCancelLoan(usersLoan.id)
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                        key={usersLoan ? usersLoan.id : 0}
                                        disabled={isCancelButtonDisabled(
                                            usersLoan
                                        )}
                                    >
                                        <Button
                                            loading={loadingCancelLoan}
                                            type="danger"
                                            className="z-table-btn z-font-13"
                                            disabled={isCancelButtonDisabled(
                                                usersLoan
                                            )}
                                        >
                                            Cancel Loan
                                        </Button>
                                    </Popconfirm>
                                    ,
                                </div>
                            </div>
                        </div>
                        <div className="z-flex-space z-guarantor-padding2 z-mb-3">
                            <div
                                className={
                                    tab === "main"
                                        ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                        : "z-cursor-poiner z-width-100 z-flex-row"
                                }
                                onClick={() => changeTab("main")}
                            >
                                <p className="z-guarantor-tab z-ml-8 z-notification-active">
                                    Main
                                </p>
                            </div>
                            <div
                                className={
                                    tab === "summary"
                                        ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                        : "z-cursor-poiner z-width-100 z-flex-row"
                                }
                                onClick={() => changeTab("summary")}
                            >
                                <p className="z-guarantor-tab z-ml-8 z-notification-active">
                                    Payback Schedule
                                </p>
                            </div>
                        </div>
                        {tab === "main" ? (
                            <UserLoanMainDetails
                                currentSalary={usersLoan.current_salary}
                                companyLevel={usersLoan.company_level.name}
                                guarantorEmail={usersLoan.guarantor_email}
                            />
                        ) : (
                            <PaybackSummary
                                allSummary={usersLoan.loan_payment_schedule}
                            />
                        )}
                    </Row>
                </>
            )}
        </Modal>
    );
}
