import React, {Component} from 'react';
import {Button, Col, Input, Modal, Row} from "antd";
import "../../assets/css/main.css";



export default class EmailVerificationModal extends Component{

    render() {
        const {state, resendEmailVerification , showWelcomeModal, handleForm, closeEmailVerificationModal} = this.props;
        return(
            <div>
                <Modal className="z-custom z-modal-signup"
                       visible={state.resendEmailVerification}
                       title={null}
                       onOk={this.handleOk}
                       onCancel={e => {closeEmailVerificationModal()}}
                       footer={null}
                >
                    <div>
                        <Row>

                            <Col offset={12} span={12} className="z-mt-3 z-login-res">
                                <div className="z-flex-baseline z-mr-25">
                                    <div className="z-testimonial">
                                        <h2 className="z-login-active z-mr--25">
                                            Resend Email Verification Token
                                        </h2>
                                    </div>
                                </div>
                                <div className="z-signup-hr">
                                    <hr className={ "z-hr-login"}/>
                                </div>
                            </Col>


                            <Col offset={12} span={12} className="z-login-mt z-login-res">
                                <div className="z-flex-column-center z-mt-10_">
                                    <Input size="large" placeholder="Enter your email" name="email" onChange={e => {handleForm(e)}}
                                           className="z-footer-input z-signup-company z-login-mb z-login-input"
                                           value={state.email}
                                           required={true}
                                       />
                                   <div className="z-mt-5 z-b-flex">
                                        <Button className="z-landing-btn z-section-btn z-login-btn z-gradient" shape="round" size="large"
                                                loading={state.resetLoading}  onClick={e => {resendEmailVerification (e)}}>
                                            Resend Email Verification Code</Button>
                                    </div>
                                    <div className="z-mt-6">
                                        <p className="z-p-small z-cursor-pointer" onClick={e => {showWelcomeModal(e, 0)}}>Go back</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        )
    }
}