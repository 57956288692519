import React from 'react';

const Stepper = (props) => {
    const {setCurrentPageStep, active} = props;
    const activeClass = (num) => {
        return active === num ? "nav-horizontal active" : "nav-horizontal";
    };
    return (
        <div className="d-flex">
            <a className={activeClass("1")} onClick={ () => setCurrentPageStep('first')} />
            <a className={activeClass("2")} onClick={ () => setCurrentPageStep('second')} />
            <a className={activeClass("3")} onClick={ () => setCurrentPageStep('third')} />
            <a className={activeClass("4")} onClick={ () => setCurrentPageStep('fourth')} />
        </div>
    );
};

export default Stepper;