import React, {Component} from "react";
import {Button, Col, Row, Skeleton} from "antd";
import Icon1 from "../../assets/images/undraw_file_searching_duff (1).svg";
import Icon2 from "../../assets/images/undraw_confirmed_81ex.svg";
import Icon3 from "../../assets/images/forward icon.png";
import {verifyEmail, getAuthUserCustom} from "../../APIsHandler/ApiController";
import {connect} from "react-redux";

class VerifyEmail extends Component{

    constructor(props){
        super(props);
        this.state ={
            token: "",
            loading: true,
            verified: false

        }
    }

    componentDidMount() {
        localStorage.clear();
       const queryString =  new URLSearchParams(window.location.search);
       const token = queryString.get("token");
       this.setState({token});
       verifyEmail({}, this.onVerify, token)
    }

    /*
    * Callback upon email verification*/
    onVerify = (status, data) =>{
        if(status){
            this.setState({verified: true, loading: false})
        }else{
            this.setState({loading: false})
        }
    };

    goToSite = () =>{
        window.location.href = window.location.origin
    }
    goToDashboard = () =>{
        window.location.href = window.location.origin+"?redirect=true"
       // this.props.getAuthUserCustom(this.state.token, this.onGetUser)
    }

    onGetUser = (status, data) =>{
        if(status){
            window.location.href = window.location.origin+"/dashboard"
        }
    }

    render() {
        const{loading, verified} = this.state;
        return(
            <div>
                <Skeleton loading={loading} active>
                    {verified ?
                        <Row className="z-height-100_ ">
                            <Col span={24}
                                 className="z-testimonial z-welcome z-flex-column-center z-height-100 z-verify-card">
                                <h2 className="z-no-mb">
                                    Email verification successful!
                                </h2>
                                <div className="z-welcome-icon z-success-icon">
                                    <img src={Icon2} alt="thumbs-up"/>
                                </div>
                                <div>
                                    <p className="z-complete-p">You can now proceed to your dashboard and get started.
                                        Enjoy!</p>
                                </div>
                                <div className="z-flex-column-center z-mt-3_">
                                    <Button
                                        className="z-landing-btn z-complete-btn z-button-gradient z-flex-row z-verify-btn"
                                        shape="round" size="large" onClick={e => {this.goToDashboard(e)}}>Go to dashboard
                                        <img className="z-btn-img" src={Icon3} alt="next"/></Button>
                                </div>
                            </Col>
                        </Row> :
                        <Row className="z-height-100_ ">
                            <Col span={24}
                                 className="z-testimonial z-welcome z-flex-column-center z-height-100 z-verify-card">
                                <h2 className="z-no-mb">
                                    We are unable to verify email!
                                </h2>
                                <div className="z-welcome-icon z-success-icon">
                                    <img src={Icon1} alt="thumbs-up"/>
                                </div>
                                <div>
                                    <p className="z-complete-p">Either token has expired or user doesn't exist.</p>
                                </div>
                                <div className="z-flex-column-center z-mt-3_">
                                    <Button
                                        className="z-landing-btn z-complete-btn z-button-gradient z-flex-row z-verify-btn"
                                        shape="round" size="large" onClick={e => {this.goToSite(e)}}>Go to site
                                        <img className="z-btn-img" src={Icon3} alt="next"/></Button>
                                </div>
                            </Col>
                        </Row>
                    }
                </Skeleton>
            </div>
        )
    }
}



export default connect(null, {getAuthUserCustom})(VerifyEmail)