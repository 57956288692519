import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    Button,
    Icon,
    Input,
    Row,
    message,
    Modal,
    Popconfirm,
    Upload,
} from "antd";
import moment from "moment";
import {
    approveCompany,
    editCompany,
    uploadFile,
} from "../../../APIsHandler/ApiController";
import DefaultIcon from "../../../assets/images/defaultCompanyIcon.png";

export default function CompanyViewModal({
    company,
    isOpen,
    closeModal,
    fetchCompanies,
}) {
    const user = useSelector((state) => state.user);

    const [editClicked, setEditClicked] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    // const [approve, setApprove] = useState(false);
    const [approveRequest, setApproveRequest] = useState(false);
    const [declineRequest, setDeclineRequest] = useState(false);

    const [name, setName] = useState("");
    const [pay_day, setPayDay] = useState("");
    const [domain, setDomain] = useState("");
    const [address, setAddress] = useState("");
    const [fileUrl, setFileUrl] = useState("");

    useEffect(() => {
        if (company && company.logo) setFileUrl(company.logo);
        else setFileUrl(DefaultIcon);
    }, [company]);

    useEffect(() => {
        if (company) {
            setName(company.name);
            setPayDay(company.salary_payday);
            setDomain(company.email_domain);
            setAddress(company.address);
        }
    }, [editClicked]);

    /*
     * approve company*/
    const handleApproveCompany = (e, type) => {
        if (type === "approve") {
            setApproveRequest(true);
            approveCompany({ id: company.id, status: "approved" }, onApprove);
        } else {
            setDeclineRequest(true);
            approveCompany({ id: company.id, status: "declined" }, onDecline);
        }
    };

    const onApprove = (status, data) => {
        if (status) {
            fetchCompanies(false);
            message.success("Company approved.", 3);
            setApproveRequest(false);
            closeModal();
        } else {
            setApproveRequest(false);
            message.error("Unable to approve company.", 3);
        }
    };

    const onDecline = (status, data) => {
        if (status) {
            fetchCompanies(false);
            message.success("Company request declined.", 3);
            setDeclineRequest(false);
            closeModal();
        } else {
            setDeclineRequest(false);
            message.error("Unable to decline request.", 3);
        }
    };

    const updateCompanyFxn = () => {
        setLoadingUpdate(true);
        let formData = new FormData();
        formData.append("id", company.id);
        formData.append("name", name);
        formData.append("salary_payday", pay_day);
        formData.append("email_domain", domain);
        formData.append("address", address);
        formData.append("logo", fileUrl);
        formData.append("_method", "PUT");

        editCompany(company.id, formData)
            .then((res) => {
                message.success("Company successfully updated");
                setLoadingUpdate(false);
                setEditClicked(false);
                fetchCompanies(false);
            })
            .catch((err) => {
                console.log(err);
                message.error("Unable to update company");
                setLoadingUpdate(false);
            });
    };

    const imageUpload = (info) => {
        console.log("Inside custom value");
        console.log(info);

        let formData = new FormData();
        formData.append("uploadFile", info.file);

        uploadFile(formData)
            .then((res) => {
                info.onSuccess(res.data, info.file);
            })
            .catch((err) => {
                console.log(err);
                info.onError("File upload issue", info.file);
            });
    };

    /**
     * handle the manual upload of the id card check
     * */
    const beforeUpload = (file) => {
        const isJpgOrPng =
            file.type === "image/png" || file.type === "image/jpeg";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }
        return isJpgOrPng && isLt2M;
    };

    const onPixChange = (info) => {
        const status = info.file.status;
        if (status !== "uploading") {
            console.log("Uploading");
            // setLoading(true);
        }
        if (status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
            // Get the url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl) => {
                setFileUrl(info.file.response.data);
            });
        } else if (status === "error") {
            console.log("Error: ", info.file);
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const upload = {
        name: "avatar",
        beforeUpload: beforeUpload,
        onChange: onPixChange,
        customRequest: imageUpload,
    };

    return (
        <Modal
            className="z-custom z-custom2 "
            visible={isOpen}
            title={"w"}
            onOk={(e) => {
                handleApproveCompany(e);
            }}
            onCancel={closeModal}
            footer={[
                company ? (
                    editClicked ? (
                        <div className="z-flex-row" key="index">
                            <Button
                                className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row"
                                shape="round"
                                size="large"
                                onClick={(e) => setEditClicked(false)}
                                key={0}
                            >
                                Cancel
                            </Button>
                            ,
                            <Button
                                className={
                                    "z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg"
                                }
                                shape="round"
                                size="large"
                                key={8}
                                onClick={(e) => {
                                    updateCompanyFxn();
                                }}
                                loading={loadingUpdate}
                            >
                                Update
                            </Button>
                        </div>
                    ) : company.status === "approved" ? (
                        <p className="z-accepted" key={8}>
                            Approved
                        </p>
                    ) : company && company.status === "declined" ? (
                        <p className="z-declined" key={7}>
                            Declined
                        </p>
                    ) : (
                        <div className="z-flex-row" key="index">
                            <Popconfirm
                                title="Are you sure you want to decline the company?"
                                onConfirm={(e) => {
                                    handleApproveCompany(e, "decline");
                                }}
                                onCancel={(e) => closeModal()}
                                okText="Decline"
                                cancelText="No"
                                key="1"
                            >
                                <Button
                                    className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row"
                                    shape="round"
                                    size="large"
                                    key="3"
                                    loading={declineRequest}
                                >
                                    Decline
                                    <Icon type="close" />
                                </Button>
                            </Popconfirm>
                            ,
                            <Popconfirm
                                title="Are you sure you want to approve the company?"
                                onConfirm={(e) => {
                                    handleApproveCompany(e, "approve");
                                }}
                                onCancel={(e) => closeModal()}
                                okText="Accept"
                                cancelText="No"
                                key="2"
                            >
                                <Button
                                    className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg"
                                    shape="round"
                                    size="large"
                                    key="4"
                                    loading={approveRequest}
                                >
                                    Approve
                                    <Icon type="check" />
                                </Button>
                            </Popconfirm>
                        </div>
                    )
                ) : (
                    ""
                ),
            ]}
        >
            <div>
                <div className="z-flex-space z-admin-label-div">
                    {user.data.roles[0].name === "super-admin" && (
                        <div>
                            <Button
                                type="primary"
                                className={
                                    editClicked
                                        ? "z-btn-company-active z-font-13"
                                        : "z-tab-btn z-font-13"
                                }
                                onClick={(e) => setEditClicked(true)}
                            >
                                Edit <Icon type="edit" />
                            </Button>
                        </div>
                    )}
                </div>
                <Row>
                    <div className="z-flex-space z-p-3">
                        <div className="z-testimonial-avatar z-profile-avatar z-mb-1 z-img-100">
                            <img src={fileUrl} alt="avatar" />
                            {company && editClicked ? (
                                <Upload {...upload} className="z-upload-flex">
                                    <p>Update Image</p>
                                </Upload>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="z-width-60">
                            <div>
                                {company ? (
                                    editClicked ? (
                                        <Input
                                            className="z-comp-input z-package-input input-margin"
                                            value={name}
                                            name="name"
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    ) : (
                                        <p className="z-modal-header">
                                            {company.name}
                                        </p>
                                    )
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="z-width-100 z-ml-2 z-mt-8">
                                <div className="z-flex-space-start">
                                    <div className="z-modal-left">
                                        <p>Date created </p>
                                        <p>Domain</p>
                                        <p>Pay day</p>
                                        <p>Address</p>
                                        <p>Status</p>
                                    </div>
                                    <div className="z-modal-right">
                                        <p>
                                            {company &&
                                                moment(
                                                    company.created_at
                                                ).format("Do MMMM YYYY")}
                                        </p>
                                        {editClicked ? (
                                            <Input
                                                className="z-comp-input z-package-input input-margin"
                                                value={domain}
                                                name="domain"
                                                onChange={(e) =>
                                                    setDomain(e.target.value)
                                                }
                                            />
                                        ) : (
                                            <p>
                                                {company &&
                                                    company.email_domain}
                                            </p>
                                        )}
                                        {editClicked ? (
                                            <Input
                                                className="z-comp-input z-package-input input-margin"
                                                value={pay_day}
                                                name="pay_day"
                                                onChange={(e) =>
                                                    setPayDay(e.target.value)
                                                }
                                            />
                                        ) : (
                                            <p>
                                                {company &&
                                                    (company.salary_payday ===
                                                    null
                                                        ? "Not specified"
                                                        : company.salary_payday)}
                                            </p>
                                        )}
                                        {editClicked ? (
                                            <Input
                                                className="z-comp-input z-package-input input-margin"
                                                value={address}
                                                name="address"
                                                onChange={(e) =>
                                                    setAddress(e.target.value)
                                                }
                                            />
                                        ) : (
                                            <p>{company && company.address}</p>
                                        )}
                                        <p>{company && company.status}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        </Modal>
    );
}
