import {SET_AUTH_USER, GET_AUTH_USER, UPDATE_AUTH_USER} from "../ActionTypes";

/**
 * Action to get the current user into redux
 * */
export function getCurrentUser(){
    return {
        type: GET_AUTH_USER
    }
}

/**
 * Action to set the current user into redux
 * */
export function setCurrentUser(user){
    localStorage.setItem('sfs_user',JSON.stringify(user));
    return {
        type: SET_AUTH_USER,
        payload: user
    }
}

/**
 * Action to the user profile
 * */
export function updateCurrentUser(user){
    return {
        type: UPDATE_AUTH_USER,
        payload: user
    }
}