import React, {Component} from "react";
import {Button, Col, Input, Modal, Row, message} from "antd";
import Icon2 from "../../assets/images/undraw_done_a34v (1).svg";
import Icon3 from "../../assets/images/forward icon.png";
import {verifyPasswordLink} from "../../APIsHandler/ApiController";

class PasswordResetPage extends Component{

    constructor(props){
        super(props);
        this.state ={
            token: "",
            loading: true,
            verified: false,
            resetPassword: true,
            resetLoading: false,
            email: "",
            password: ""
        }
    }

    componentDidMount() {
        const queryString =  new URLSearchParams(window.location.search);
        const token = queryString.get("token");
        this.setState({token})
        /*verifyEmail({}, this.onVerify, token)*/
    }

    /*
   * function to send password rest link*/
    resetPassword = () => {
        const {email, password, token} = this.state;
        this.setState({resetLoading: true})
        verifyPasswordLink({email, password, token}, this.onResetPassword)
    }

    /*
    * Callback function to resend password*/
    onResetPassword = (status, data) => {
        if(status){
            this.setState({resetLoading: false, resetPassword: false, loading: false})
            message.success('Your password was successfully changed.', 3);
        }else{
            message.error('Unable to reset password. '+ data.data.message , 3);
            this.setState({resetLoading: false})
        }
    }
    goToSite = () =>{
        window.location.href = window.location.origin+"?redirect=true"
    }

    //handle input change
    handleForm = (e) => {
        this.setState({[e.target.name]: e.target.value})
    };

    render() {
        const{loading, resetPassword, resetLoading, email, password} = this.state;
        return(
            <div>
                    {!loading ?
                        <Row className="z-height-100_ ">
                            <Col span={24}
                                 className="z-testimonial z-welcome z-flex-column-center z-height-100 z-verify-card">
                                <h2 className="z-no-mb">
                                    Password reset successful!
                                </h2>
                                <div className="z-welcome-icon z-success-icon">
                                    <img src={Icon2} alt="thumbs-up"/>
                                </div>
                                <div>
                                    <p className="z-complete-p">Please proceed to the home page to login with your new credentials.
                                        Enjoy!</p>
                                </div>
                                <div className="z-flex-column-center z-mt-3_">
                                    <Button
                                        className="z-landing-btn z-complete-btn z-button-gradient z-flex-row z-verify-btn"
                                        shape="round" size="large" onClick={e => {this.goToSite(e)}}>Go to site
                                        <img className="z-btn-img" src={Icon3} alt="next"/></Button>
                                </div>
                            </Col>
                        </Row> :
                        <Row className="z-height-100_ ">

                        </Row>
                    }
                <Modal className="z-custom z-modal-signup"
                       visible={resetPassword}
                       title={null}
                       onOk={this.handleOk}
                       onCancel={null}
                       footer={null}
                >
                    <div>
                        <Row>

                            <Col offset={12} span={12} className="z-mt-3 z-login-res">
                                <div className="z-flex-baseline z-mr-25">
                                    <div className="z-testimonial">
                                        <h2 className="z-login-active z-mr--25">
                                            Reset Password
                                        </h2>
                                    </div>
                                </div>
                                <div className="z-signup-hr">
                                    <hr className={ "z-hr-login"}/>
                                </div>
                            </Col>


                            <Col offset={12} span={12} className="z-login-mt z-login-res">
                                <div className="z-flex-column-center">
                                    <Input size="large" placeholder="email" name="email" onChange={e => {this.handleForm(e)}}
                                           className="z-footer-input z-signup-company z-login-mb z-login-input" value={email}/>
                                    <Input.Password size="large" placeholder="password" name="password" onChange={e => {this.handleForm(e)}}
                                                    className="z-signup-password no-mb z-login-input2" value={password}
                                    />
                                    <div className="z-mt-15 z-b-flex">
                                        <Button className="z-landing-btn z-section-btn z-login-btn z-gradient" shape="round" size="large"
                                                loading={resetLoading}  onClick={e => {this.resetPassword(e)}}>
                                            Reset Password</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default PasswordResetPage