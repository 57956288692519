import React, { useState } from "react";
import { Button, Icon, Input, Modal, Row, Select, message } from "antd";
import {
    approveLoanPackage,
    getComanyLevelByCompanyId,
    updateLoanPackage,
} from "../../../APIsHandler/ApiController";
import { connect } from "react-redux";
import { useEffect } from "react";
import { getCurrency, numberWithCommas } from "../../../utils/functions";
import DefaultCompanyAvatar from "../../../assets/images/defaultCompanyIcon.png";

const Option = Select.Option;

function LoanPackageModal({
    isVisible,
    closeModal,
    loanPackage,
    fetchLoanPackages,
    companies,
    companyLoading,
    user,
}) {
    const [disapproving, setDisapproving] = useState();
    const [approving, setApproving] = useState();
    const [editClicked, setEditClicked] = useState(false);
    const [isUpdatingLoan, setIsUpdatingLoan] = useState(false);
    const [companyLevelLoading, setCompanyLevelLoading] = useState(false);
    const [allCompanyLevels, setAllCompanyLevels] = useState([]);

    const [status, setStatus] = useState();
    const [loanPackageId, setLoanPackageId] = useState();

    const [name, setName] = useState();
    const [loan, setLoan] = useState(500000);
    const [rate, setRate] = useState(50);
    const [tenure, setTenure] = useState(0);
    const [companyId, setCompanyId] = useState();
    const [companyLevels, setCompanyLevels] = useState();

    useEffect(() => {
        console.log(loanPackage);
        if (loanPackage) {
            setName(loanPackage.name);
            setTenure(loanPackage.tenure);
            setLoan(loanPackage.amount);
            setRate(loanPackage.interest_rate);
            setLoanPackageId(loanPackage.id);
            setCompanyId(loanPackage.company_id);
            setStatus(loanPackage.status);

            getAllCompanyLevels(loanPackage.company_id);

            // Covert Company Levels to an array of company IDs
            let company_levels = [];

            if (
                loanPackage.company_levels &&
                loanPackage.company_levels.length > 0
            )
                company_levels = loanPackage.company_levels.map(({ id }) => id);

            setCompanyLevels(company_levels);
        }
    }, [loanPackage]);

    /**
     * adds company's ID to state
     * @param {int} id company ID
     */
    function onCompanyChange(id) {
        setCompanyId(id);
        setCompanyLevels([]);

        getAllCompanyLevels(id);
    }

    // Fetch Company Level from server
    function getAllCompanyLevels(companyId) {
        getComanyLevelByCompanyId(companyId)
            .then((resp) => {
                // setCompanyLoading(false);
                setAllCompanyLevels(resp.data.data);
            })
            .catch(() => {
                setCompanyLevelLoading(false);
                message.error(
                    "Unable to retrieve company level at the moment.",
                    3
                );
            });
    }

    function onCompanyLevelChange(id) {
        setCompanyLevels(id);
    }

    function handleOnLoanChange(e) {
        const value = e.target.value;

        if (value.length === 0 || value === "" || value === null) {
            setLoan(value);
        } else {
            setLoan(numberWithCommas(getCurrency(value)));
        }
    }

    // approve loan package
    function approvePackage(status) {
        status === "approved" ? setApproving(true) : setDisapproving(true);

        approveLoanPackage({ loanPackageId, status }, onApprovePackage);
    }

    function onApprovePackage(status, data) {
        if (status) {
            setApproving(false);
            message.success("Loan package status updated", 5);
            fetchLoanPackages();
        } else {
            setApproving(false);
            message.error("Unable to approve package!");
        }
    }

    function handleUpdateLoanPackage() {
        setIsUpdatingLoan(true);

        let formData = new FormData();

        formData.append("loanPackageId", loanPackageId);
        formData.append("name", name);
        formData.append("amount", getCurrency(loan.toString()));
        formData.append("rate", rate);
        formData.append("tenure", tenure);
        formData.append("company_id", companyId);
        formData.append("_method", "PUT");

        companyLevels.forEach((id) => {
            formData.append("company_level[]", id);
        });

        updateLoanPackage(formData)
            .then((res) => {
                message.success("loan package successfully updated");
                setIsUpdatingLoan(false);
                fetchLoanPackages();
                setEditClicked(false);
            })
            .catch((err) => {
                message.error("Unable to update loan package");
                setIsUpdatingLoan(false);
            });
    }

    const isDisabled = () => (editClicked && companyId ? false : true);

    return (
        <Modal
            className="z-custom z-custom2 z-custom3 "
            visible={isVisible}
            title={"w"}
            onCancel={closeModal}
            footer={[
                status === "approved" || status === "disapproved" ? (
                    ""
                ) : user.data.roles[0].name === "super-admin" &&
                  status === "pending" ? (
                    <Button
                        className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row z-btn-company z-border-active"
                        shape="round"
                        size="large"
                        onClick={() => approvePackage("declined")}
                        loading={disapproving}
                        key={9}
                    >
                        Disapprove
                    </Button>
                ) : editClicked ? (
                    <Button
                        className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row z-btn-company z-border-active"
                        shape="round"
                        size="large"
                        onClick={(e) => setEditClicked(false)}
                        key={0}
                    >
                        Cancel
                    </Button>
                ) : (
                    <Button
                        className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row z-btn-company"
                        shape="round"
                        size="large"
                        onClick={closeModal}
                        key={0}
                    >
                        Cancel
                    </Button>
                ),

                (user.data.roles[0].name === "admin" && status === "pending") ||
                user.data.roles[0].name === "super-admin" ? (
                    <Button
                        className={
                            editClicked
                                ? "z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg z-btn-company2-active"
                                : "z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg z-btn-company2"
                        }
                        shape="round"
                        size="large"
                        key={8}
                        onClick={handleUpdateLoanPackage}
                        loading={isUpdatingLoan}
                    >
                        Update
                    </Button>
                ) : user.data.roles[0].name === "super-admin" &&
                  status === "pending" ? (
                    <Button
                        onClick={() => approvePackage("approved")}
                        loading={approving}
                        className={
                            "z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg z-btn-company2-active"
                        }
                        shape="round"
                        size="large"
                        key={1}
                    >
                        Approve
                    </Button>
                ) : (
                    ""
                ),
            ]}
        >
            <div>
                <div className="z-flex-space z-admin-label-div">
                    {((user.data.roles[0].name === "admin" &&
                        status === "pending") ||
                        user.data.roles[0].name === "super-admin") && (
                        <div>
                            <Button
                                type="primary"
                                className={
                                    editClicked
                                        ? "z-btn-company-active z-font-13"
                                        : "z-tab-btn z-font-13"
                                }
                                onClick={() => setEditClicked(true)}
                            >
                                Edit <Icon type="edit" />
                            </Button>
                        </div>
                    )}
                    <div>
                        <p className="z-loan-header z-loan-header-small">
                            {name}
                        </p>
                    </div>
                </div>
                <Row>
                    <div className="z-flex-column-center z-com-p2 loan-package-modal">
                        <div className="z-admin-left z-flex-space z-com-div z-mb-4">
                            <div>
                                <p>Select Company</p>
                            </div>
                            <div>
                                <Select
                                    defaultValue="Company"
                                    size="default"
                                    className="z-comp-input z-package-input"
                                    onChange={(id) => onCompanyChange(id)}
                                    disabled={!editClicked}
                                    value={companyId || 0}
                                >
                                    {companyLoading ? (
                                        <Option value="loading...">
                                            <Icon type="loading" />
                                        </Option>
                                    ) : (
                                        companies.map((company, i) => {
                                            return (
                                                <Option
                                                    value={company.id}
                                                    className="z-select-input"
                                                    key={i}
                                                >
                                                    <img
                                                        src={
                                                            company.logo
                                                                ? company.logo
                                                                : DefaultCompanyAvatar
                                                        }
                                                        alt="logo"
                                                    />{" "}
                                                    {company.name}
                                                </Option>
                                            );
                                        })
                                    )}
                                </Select>
                            </div>
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div z-mb-4">
                            <div>
                                <p>Select Level</p>
                            </div>
                            <div>
                                <Select
                                    mode="multiple"
                                    defaultValue="Level"
                                    placeholder="Select a Level"
                                    size="default"
                                    className="z-comp-input z-package-input"
                                    onChange={(id) => onCompanyLevelChange(id)}
                                    disabled={isDisabled()}
                                    value={companyLevels}
                                >
                                    {companyLevelLoading ? (
                                        <Option value="loading...">
                                            <Icon type="loading" />
                                        </Option>
                                    ) : (
                                        allCompanyLevels.map((level, i) => {
                                            return (
                                                <Option
                                                    value={level.id}
                                                    className="z-select-input"
                                                    key={i}
                                                >
                                                    {level.name}
                                                </Option>
                                            );
                                        })
                                    )}
                                </Select>
                            </div>
                        </div>

                        <div className="z-admin-left z-flex-space z-com-div z-mb-6">
                            <div>
                                <p>Loan package name</p>
                            </div>
                            <div>
                                <Input
                                    className="z-comp-input z-package-input"
                                    value={name}
                                    name="name"
                                    disabled={!editClicked}
                                    onChange={(e) => setName(e.target.name)}
                                />
                            </div>
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div z-mb-6">
                            <div>
                                <p>Loan maximum</p>
                            </div>
                            <div>
                                <Input
                                    className="z-comp-input z-package-input2"
                                    value={loan}
                                    name="loan"
                                    disabled={!editClicked}
                                    onChange={handleOnLoanChange}
                                />
                            </div>
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div z-mb-10">
                            <div>
                                <p>Interest rate</p>
                            </div>
                            <div>
                                <Input
                                    className="z-comp-input z-package-input2"
                                    value={rate}
                                    name="rate"
                                    disabled={!editClicked}
                                    onChange={(e) => setRate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div ">
                            <div>
                                <div className="z-mb-15">
                                    <p>Payment tenure</p>
                                </div>
                                <div>
                                    <Select
                                        value={tenure.toString()}
                                        style={{ width: 120 }}
                                        onChange={(value) => setTenure(value)}
                                        className="z-package-input2"
                                        disabled={!editClicked}
                                    >
                                        <Option value="1">1 month</Option>
                                        <Option value="2">2 months</Option>
                                        <Option value="3">3 months</Option>
                                        <Option value="3">4 months</Option>
                                        <Option value="3">5 months</Option>
                                        <Option value="6">6 months</Option>
                                        <Option value="9">9 months</Option>
                                        <Option value="12">12 months</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        </Modal>
    );
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(LoanPackageModal);
