import React, {Fragment, useState} from 'react';
import {Button, Col, Row} from "antd";
import "../../assets/css/main.css";
import "../../assets/css/responsiveness.css";
import {LandingPageBody} from "./LandingPageBody";
import Header from "./Header";
import GettingStarted from "./HowTos/GettingStarted";
import Main from "./HowTos/Main";


const LandingPage = (props) => {
    const [currentContent, setCurrentContent] = useState('landingPageBody');
    const {showSignupModal} = props;
    const changePage = (e, value) => {
        e.preventDefault();
        setCurrentContent(value);
    };
    return(
        <Fragment>
            <div className="z-screen-height">
                <Row className={currentContent === "landingPageBody" ? "z-height-inherit" : ""}>
                    <Col span={24} className={currentContent === "landingPageBody" ? "z-height-inherit" : ""}>
                        <div className={currentContent === "landingPageBody" ? "z-landing-image" : "z-getting_help-image"}>
                           <Header {...props} changePage={changePage}/>
                            { currentContent === "landingPageBody" ?
                                <LandingPageBody {...props} currentContent={currentContent}/> : <Main {...props} currentContent={currentContent}/>
                             }
                        </div>
                    </Col>
                </Row>
            </div>
        </Fragment>

    )
};

export default LandingPage;