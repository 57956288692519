import React, {Fragment} from "react";
import Stepper from "./Stepper";
import Header from "./Header";
import applyLoanBG from "../../../../assets/images/getting-help/applyLoan5.png"

const FifthStep = (props) => {
    const {setCurrentPageStep, setPageContent} = props;

    return (
        <Fragment>
            <div className="page">
                <Header setPageContent={setPageContent}/>
                <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="circleNum mb-4">5</div>
                        <div className="help-topic">Your loan application</div>
                        <p className="help-content">Fill in your salary, select the loan amount from the drop-down list,
                            Bank details and your guarantor's mail (Guarantor's email must correspond to the
                            selected company at registration)
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-7">
                        <div>
                            <img src={applyLoanBG} alt="Background Image" className="img-fluid top-img"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Stepper setCurrentPageStep={setCurrentPageStep} active="5"/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FifthStep;