import React, {Fragment, useState} from "react";
import {Button} from "antd";
import Stepper from "./Stepper";
import Header from "./Header";
import applyLoanBG from "../../../../assets/images/getting-help/applyLoan7.png";

const SeventhStep = (props) => {
    const {setCurrentPageStep, setPageContent, showSignupModal} = props;
    return (
        <Fragment>
            <div className="page">
                <Header setPageContent={setPageContent}/>
                <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="circleNum mb-4">7</div>
                        <div className="help-topic">Withdraw funds after loan has been approved</div>
                        <p className="help-content">
                            After approval, proceed to your wallet to withdraw your fund
                        </p>
                        <br/>
                        <Button className="ant-btn-round apply-btn mt-4 mb-4" onClick={e => {showSignupModal(e, 0)}}>
                            Start applying now
                        </Button>
                    </div>
                    <div className="col-sm-12 col-md-7">
                        <div>
                            <img src={applyLoanBG} alt="Background Image" className="img-fluid top-img"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Stepper setCurrentPageStep={setCurrentPageStep} active="7"/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SeventhStep;