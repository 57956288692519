import React from "react";
import {Button, Col, Row} from "antd";

export const LandingPageBody = (props) => {
    const {showWelcomeModal} = props;
    return (
        <Row>
            <Col span={16} className="z-divl-height">
                <div className="z-landing-div z-flex-left">
                    <h1>
                        Borrow money <br/>easy. fast & seamless
                    </h1>
                    <Button className="z-landing-btn" shape="round" size="large" onClick={e => {showWelcomeModal(e, 0)}}>Get a loan</Button>
                </div>
            </Col>
        </Row>

    );
};

