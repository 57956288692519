import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Skeleton, Table } from "antd";

import {
    getAllLoanSchedules,
    markLoanScheduleAsPaid,
    payLoanSchedule,
} from "../../../APIsHandler/ApiController";
import Column from "antd/lib/table/Column";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";
import moment from "moment";
import NavigationTab from "../../NavigationTab";
import overviewIcon from "../../../assets/images/dashboard/overview.svg";
import { toast } from "react-toastify";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const LoanSchedules = (props) => {
    const { loanRequestId } = useParams();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [fetchingLoanSchedules, setFetchingloanSchedules] = useState(true);
    const [loanPaybackSchedules, setloanPaybackSchedules] = useState([]);
    const [currentNavIndex, setCurrentNavIndex] = useState(0);
    const [nextNavIndex, setNextNavIndex] = useState(10);
    const [navDiff, setNavDiff] = useState(10);
    const [noOfPages, setNoOfPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentLoanInterest, setCurrentLoanInterest] = useState("");
    const [loadingMarkedAsPaid, setLoadingMarkedAsPaid] = useState(null);
    const [loadingPayNow, setLoadingPayNow] = useState(null);
    const [loadingChargeGuarantor, setLoadingChargeGuarantor] = useState(null);

    useEffect(() => {
        fetchLoanSchedules();
    }, []);

    function fetchLoanSchedules() {
        console.log("loanRequestId: ", loanRequestId);
        loanRequestId
            ? loadLoanSchedules(loanRequestId)
            : loadLoanSchedules("");
    }

    const loadLoanSchedules = (propsLoanRequestId) => {
        setCurrentLoanInterest(propsLoanRequestId);
        getAllLoanSchedules(propsLoanRequestId)
            .then((res) => {
                console.log("Current Loan Request", propsLoanRequestId);
                let { data } = res.data;
                console.log("Loan schedules", data);
                let num = Math.ceil(data.length / navDiff);
                setloanPaybackSchedules(data);
                setNoOfPages(num);
                setFetchingloanSchedules(false);
            })
            .catch((err) => {
                setFetchingloanSchedules(false);
            });
    };

    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    const goBack = () => {
        window.location.href = "/dashboard/admin/requests";
    };

    const refresh = () => {
        loadLoanSchedules("");
    };

    const onSelectChange = (selectedRowKeys) => {
        console.log("selectedRowKeys changed: ", selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    const navigateRight = () => {
        if (currentPage !== noOfPages) {
            setNextNavIndex(nextNavIndex + navDiff);
            setCurrentNavIndex(currentNavIndex + navDiff);
            setCurrentPage((nextNavIndex + navDiff) / navDiff);
        }
    };

    const navigateLeft = () => {
        if (currentPage !== 1) {
            setNextNavIndex(nextNavIndex - navDiff);
            setCurrentNavIndex(currentNavIndex - navDiff);
            setCurrentPage((nextNavIndex - navDiff) / navDiff);
        }
    };

    const payCurrentLoanSchedule = (value, chargeGuarantor) => {
        // loading indicator
        chargeGuarantor === "no"
            ? setLoadingPayNow(value)
            : setLoadingChargeGuarantor(value);

        payLoanSchedule(value, chargeGuarantor)
            .then((res) => {
                let { data, message } = res.data;

                // close loading indicator
                setLoadingPayNow(null);
                setLoadingChargeGuarantor(null);

                toast.success(
                    message !== undefined ? message : "Transaction error"
                );
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((err) => {
                // close loading indicator

                setLoadingPayNow(null);
                setLoadingChargeGuarantor(null);

                toast.success("Error making payment");
            });
    };

    const markAsPaid = (LoanSchedulesId) => {
        setLoadingMarkedAsPaid(LoanSchedulesId);
        markLoanScheduleAsPaid(LoanSchedulesId)
            .then((res) => {
                let { data, message } = res.data;
                console.log("Success", data);
                toast.success(
                    message !== undefined ? message : "Transaction error"
                );
                setLoadingMarkedAsPaid(null);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((err) => {
                setLoadingMarkedAsPaid(null);
                console.log("Error with Marked as Paid", err);
                toast.success("Error making payment");
            });
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <div>
            <Skeleton loading={fetchingLoanSchedules} active>
                <div className="row">
                    <div className="col-md-12">
                        <div
                            className="z-dashboard-panel z-mb-5 z-width-100 z-admin-panel2"
                            style={{ height: "100%" }}
                        >
                            <div className="mb-4">
                                <h1 className="z-dashboard-label">
                                    Loan Repayment Schedules
                                </h1>
                                <div className="z-flex-start">
                                    <img
                                        src={overviewIcon}
                                        className="img-fluid mr-3"
                                        alt="Overview Icon"
                                    />
                                    <span className="z-label-text">
                                        Loan Payback Schedules
                                    </span>
                                </div>
                                <div>
                                    <Button
                                        type="primary"
                                        icon="arrow-left"
                                        className="z-tab-btn"
                                        onClick={(e) => goBack()}
                                    >
                                        Go back to Loan Request Page
                                    </Button>
                                </div>
                                <br />
                                <div>
                                    <Button
                                        type="primary"
                                        icon="arrow-up"
                                        className="z-tab-btn"
                                        onClick={(e) => refresh()}
                                    >
                                        Load All Loan Schedules
                                    </Button>
                                </div>
                            </div>

                            <div>
                                <Table
                                    bordered={false}
                                    dataSource={loanPaybackSchedules.slice(
                                        currentNavIndex,
                                        nextNavIndex
                                    )}
                                    className="z-admin-table z-todo"
                                    pagination={false}
                                    rowKey="id"
                                    rowClassName={(record, index) => {
                                        if (index % 2 === 0) {
                                            return "z-stripped";
                                        }
                                    }}
                                    scroll={{ x: "220%" }}
                                >
                                    <Column
                                        title="S/N"
                                        dataIndex="sn"
                                        key="sn"
                                        render={(text, record, index) => {
                                            return (
                                                <div className="">
                                                    <p className="z-table-num z-no-mb">
                                                        {Number(
                                                            index +
                                                                (currentPage -
                                                                    1) *
                                                                    navDiff
                                                        ) + 1}
                                                    </p>
                                                </div>
                                            );
                                        }}
                                    />
                                    <Column
                                        title="User"
                                        dataIndex="user"
                                        key="user"
                                        render={(text, record) => {
                                            return (
                                                <div>
                                                    <p className="z-table-status z-table-parag2">
                                                        {record.user
                                                            ? record.user
                                                                  .full_name
                                                            : record.model
                                                            ? record.model.user
                                                                  .full_name
                                                            : ""}
                                                    </p>
                                                </div>
                                            );
                                        }}
                                    />
                                    <Column
                                        title="Loan Package"
                                        dataIndex="loanPackage"
                                        key="loanPackage"
                                        render={(text, record) => {
                                            return (
                                                <div className="z-flex-start">
                                                    <div className="z-ml-7">
                                                        <p className="z-table-name z-table-parag ">
                                                            {record.loan_request
                                                                ? record
                                                                      .loan_request
                                                                      .loan_package
                                                                      .name
                                                                : ""}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    />
                                    <Column
                                        title="Loan Request ID"
                                        dataIndex="loanRequest"
                                        key="loanRequest"
                                        render={(text, record) => {
                                            return (
                                                <div>
                                                    <p className="z-table-status z-table-parag2">
                                                        {record.loan_request_id}
                                                    </p>
                                                </div>
                                            );
                                        }}
                                    />
                                    <Column
                                        title="Amount"
                                        dataIndex="amount"
                                        key="amount"
                                        render={(text, record) => {
                                            return (
                                                <p className="z-table-text z-table-parag">
                                                    {naira}{" "}
                                                    {formatCurrency(
                                                        Number(
                                                            record.amount_due
                                                        )
                                                    )}
                                                </p>
                                            );
                                        }}
                                    />
                                    <Column
                                        title="Payback Date"
                                        dataIndex="payback_date"
                                        key="payback_date"
                                        render={(text, record) => {
                                            return (
                                                <p className="z-table-text2 z-table-parag2">
                                                    {record.payback_date !==
                                                    null
                                                        ? moment(
                                                              record.payback_date
                                                          ).format("DD-MM-YYYY")
                                                        : "No Date"}
                                                </p>
                                            );
                                        }}
                                    />
                                    <Column
                                        title="Extended Payback Date"
                                        dataIndex="extended_payback_date"
                                        key="extended_payback_date"
                                        render={(text, record) => {
                                            return (
                                                <p className="z-table-text2 z-table-parag2">
                                                    {record.extended_payback_date !==
                                                    null
                                                        ? moment(
                                                              record.extended_payback_date
                                                          ).format("DD-MM-YYYY")
                                                        : "No Date"}
                                                </p>
                                            );
                                        }}
                                    />
                                    <Column
                                        title="Status"
                                        dataIndex="status"
                                        key="status"
                                        render={(text, record) => {
                                            return (
                                                <p
                                                    className={
                                                        record.payment_status ===
                                                        "success"
                                                            ? "z-table-status z-table-parag2 z-color-green2"
                                                            : record.payment_status ===
                                                              "pending"
                                                            ? "z-table-status z-table-parag2 z-color-yellow"
                                                            : "z-table-status z-table-parag2"
                                                    }
                                                >
                                                    {record.payment_status}
                                                </p>
                                            );
                                        }}
                                    />
                                    <Column
                                        title="Charge Borrower's Card"
                                        dataIndex="payNow"
                                        key="payNow"
                                        render={(text, record) => {
                                            return record.payment_status ===
                                                "pending" ||
                                                record.payment_status ===
                                                    "failed" ? (
                                                <Popconfirm
                                                    title="Are you sure you want to pay this loan schedule now?"
                                                    onConfirm={(e) =>
                                                        payCurrentLoanSchedule(
                                                            record.id,
                                                            "no"
                                                        )
                                                    }
                                                    onCancel=""
                                                    okText="Pay"
                                                    cancelText="No"
                                                    key={record.id}
                                                >
                                                    <Button
                                                        loading={
                                                            loadingPayNow ==
                                                            record.id
                                                        }
                                                        className="secondary"
                                                    >
                                                        Pay Now
                                                    </Button>
                                                </Popconfirm>
                                            ) : (
                                                ""
                                            );
                                        }}
                                    />
                                    <Column
                                        title="Charge Guarantor's card"
                                        dataIndex="chargeGuarantor"
                                        key="chargeGuarantor"
                                        width="12rem"
                                        render={(text, record) => {
                                            return record.payment_status ===
                                                "pending" ||
                                                record.payment_status ===
                                                    "failed" ? (
                                                <Popconfirm
                                                    title="Are you sure you want to pay this loan schedule now?"
                                                    onConfirm={(e) =>
                                                        payCurrentLoanSchedule(
                                                            record.id,
                                                            "yes"
                                                        )
                                                    }
                                                    onCancel=""
                                                    okText="Charge Guarantor"
                                                    cancelText="No"
                                                    key={record.id}
                                                >
                                                    <Button
                                                        loading={
                                                            loadingChargeGuarantor ==
                                                            record.id
                                                        }
                                                        className="primary"
                                                    >
                                                        Charge Guarantor
                                                    </Button>
                                                </Popconfirm>
                                            ) : (
                                                ""
                                            );
                                        }}
                                    />
                                    <Column
                                        title="Mark Loan Schedule as Paid"
                                        dataIndex="markPaid"
                                        key="markPaid"
                                        width="10rem"
                                        render={(text, record) => {
                                            return record.payment_status ===
                                                "pending" ||
                                                record.payment_status ===
                                                    "failed" ? (
                                                <Popconfirm
                                                    title="Are you sure you want to mark this payment schedule as paid?"
                                                    onConfirm={(e) =>
                                                        markAsPaid(record.id)
                                                    }
                                                    onCancel=""
                                                    okText="Pay"
                                                    cancelText="No"
                                                    key={record.id}
                                                >
                                                    <Button
                                                        loading={
                                                            loadingMarkedAsPaid ==
                                                            record.id
                                                        }
                                                        className="secondary"
                                                    >
                                                        Mark As Paid
                                                    </Button>
                                                </Popconfirm>
                                            ) : (
                                                ""
                                            );
                                        }}
                                    />
                                </Table>
                            </div>
                            {loanPaybackSchedules.length > 0 && (
                                <div className="z-admin-gr-div">
                                    <NavigationTab
                                        noOfPages={noOfPages}
                                        currentPage={currentPage}
                                        navigateLeft={navigateLeft}
                                        navigateRight={navigateRight}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Skeleton>
        </div>
    );
};

export default LoanSchedules;
