import React, {Fragment} from 'react';
import "../../assets/css/main.css"
import {Button, Col, Icon, Input, Row} from "antd";
import logo from "../../assets/images/placeholder_logo.png";


const Footer = () => {
    return(
        <Fragment>
            <footer>
                <div className="row z-home-footer"
                     data-aos-anchor-placement="center-bottom"
                     data-aos-duration="1000">
                    <div className="col-md-12 z-height-100 z-test-res">
                                    <div className="z-flex-footer">
                                        <div className="z-navbar-logo z-mr-17 z-res-navbar-logo">
                                            <img src={logo} alt="logo"/>
                                        </div>
                                        <div className="z-flex-space2">
                                            <div className="z-flex-column z-footer-res-margin">
                                                <p className="z-footer-header">
                                                    Legal
                                                </p>
                                                <p className="z-footer-terms">
                                                    Terms & Conditions
                                                </p>
                                                <p className="z-footer-terms">
                                                    Privacy
                                                </p>
                                            </div>
                                            <div className="z-footer-res-margin">
                                                <p className="z-footer-header">
                                                    Social
                                                </p>
                                                <div className="z-flex-space z-footer-social">
                                                    <Icon type="instagram" theme="filled" />
                                                    <Icon type="facebook" theme="filled" />
                                                    <Icon type="twitter" />
                                                    <Icon type="linkedin" theme="filled" />
                                                </div>
                                            </div>
                                            <div>
                                                <p className="z-footer-header">
                                                    FAQS
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col offset={7} span={17}  className="z-footer-res  z-footer-bm">
                                            <div className="z-footer-subscribe">
                                                <p>
                                                    Subscribe to our newsletter
                                                </p>
                                                <div>
                                                    <Input size="large" placeholder="Email Address" className="z-footer-input"/>
                                                    <Button className="z-footer-button" type="primary" shape="round" size="large">Subscribe</Button>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                </div>
            </footer>
        </Fragment>
    )
};

export default Footer;