import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Column from "antd/lib/table/Column";
import { Button, Table } from "antd";
import moment from "moment";
import DefaultIcon from "../../../assets/images/defaultCompanyIcon.png";
import NavigationTab from "../../NavigationTab";
import CompanyViewModal from "./CompanyViewModal";
import DisapproveCompanyButton from "./DisapproveCompanyButton";

export default function CompaniesList({ companies, fetchCompanies }) {
    const history = useHistory();

    const [currentNavIndex, setCurrentNavIndex] = useState(0);
    const [nextNavIndex, setNextNavIndex] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [navDiff, setNavDiff] = useState(10);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [noOfPages, setNoOfPages] = useState(0);
    const [isOpenModel, setIsOpenModel] = useState(false);
    const [company, setCompany] = useState(null);

    /**
     * redirects to the comany level page
     * @param {int} id company ID
     */
    const redirectToComanyLevelPage = (id) => {
        history.push(`/dashboard/admin/company-level/${id}`);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const navigateRight = () => {
        if (currentPage !== noOfPages) {
            setNextNavIndex(nextNavIndex + navDiff);
            setCurrentNavIndex(currentNavIndex + navDiff);
            setCurrentPage((nextNavIndex + navDiff) / navDiff);
        }
    };

    const navigateLeft = () => {
        if (currentPage !== 1) {
            setNextNavIndex(nextNavIndex - navDiff);
            setCurrentNavIndex(currentNavIndex - navDiff);
            setCurrentPage((nextNavIndex - navDiff) / navDiff);
        }
    };

    const onSelectChange = (selectedRowKeys) => {
        console.log("selectedRowKeys changed: ", selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    /** Show modal **/
    const showModal = (company) => {
        setIsOpenModel(true);
        setCompany(company);
    };

    return (
        <>
            <Table
                rowSelection={rowSelection}
                bordered={false}
                dataSource={companies.slice(currentNavIndex, nextNavIndex)}
                className="z-admin-table z-todo"
                pagination={false}
                rowKey="id"
                rowClassName={(record, index) => {
                    return "z-stripped z-table-border";
                }}
                scroll={{ x: "220%" }}
            >
                <Column
                    title="Mark all"
                    dataIndex="sn"
                    key="sn"
                    render={(text, record, index) => {
                        return (
                            <div className="">
                                <p className="z-table-num z-no-mb">
                                    {Number(
                                        index + (currentPage - 1) * navDiff
                                    ) + 1}
                                </p>
                            </div>
                        );
                    }}
                />
                <Column
                    title="Company Name"
                    dataIndex="name"
                    key="name"
                    render={(text, record) => {
                        return (
                            <div className="z-flex-start">
                                <div className="z-testimonial-avatar z-table-avatar z-mr-10">
                                    <img
                                        src={
                                            record.logo
                                                ? record.logo
                                                : DefaultIcon
                                        }
                                        alt="logo"
                                    />
                                </div>
                                <div>
                                    <p className="z-table-com z-font-14">
                                        {record.name}
                                    </p>
                                </div>
                            </div>
                        );
                    }}
                />
                <Column
                    title="Description"
                    dataIndex="description"
                    key="description"
                    render={(text, record) => {
                        return (
                            <p className="z-table-com">{record.description}</p>
                        );
                    }}
                />
                <Column
                    title="Status"
                    dataIndex="status"
                    key="status"
                    render={(text, record) => {
                        return <p className="z-table-com">{record.status}</p>;
                    }}
                />
                <Column
                    title="Date created"
                    dataIndex="created"
                    key="created"
                    render={(text, record) => {
                        return (
                            <p className="z-table-com">
                                {moment(record.created_at).format("DD-MM-YYYY")}
                            </p>
                        );
                    }}
                />
                <Column
                    title="Action"
                    dataIndex="action"
                    key="Action"
                    render={(i, record, index) => (
                        <>
                            <Button
                                className="z-tablebtn"
                                onClick={(e) => {
                                    showModal(record);
                                }}
                            >
                                {" "}
                                View
                            </Button>
                            <Button
                                className="z-tablebtn"
                                onClick={() =>
                                    redirectToComanyLevelPage(record.id)
                                }
                            >
                                {" "}
                                Level
                            </Button>

                            {record.status === "approved" && <DisapproveCompanyButton
                                id={record.id}
                                reload={fetchCompanies}
                            />}
                        </>
                    )}
                />
            </Table>
            {companies.length > 0 && (
                <div className="z-admin-gr-div">
                    <NavigationTab
                        // {...props}
                        currentPage={currentPage}
                        noOfPages={noOfPages}
                        navigateLeft={navigateLeft}
                        navigateRight={navigateRight}
                    />
                </div>
            )}
            <CompanyViewModal
                company={company}
                isOpen={isOpenModel}
                fetchCompanies={fetchCompanies}
                closeModal={() => setIsOpenModel(false)}
            />
        </>
    );
}
