import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Dropdown,
    Icon,
    Input,
    Menu,
    Row,
    Skeleton,
    Table,
    message,
    Modal,
    Popconfirm,
    Upload,
} from "antd";
import overviewIcon from "../../../assets/images/dashboard/overview.svg";
import { getCompanies } from "../../../APIsHandler/ApiController";
import CompaniesList from "./CompaniesList";

export default function Companies() {
    const [companyLoading, setCompanyLoading] = useState(true);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = (showLoading = true) => {
        setCompanyLoading(showLoading);

        getCompanies()
            .then((res) => {
                // let { data } = res.data;
                // let num = Math.ceil(data.length / navDiff);
                setCompanies(res.data.data);
                // setNoOfPages(num);
                setCompanyLoading(false);
            })
            .catch((err) => {
                setCompanyLoading(false);
                message.error("Unable to retrieve companies at the moment.", 3);
            });
    }

    const handleMenuClick = (e) => {
        console.log("click", e);
    };

    /*
     * navigates to add new company page*/
    const addNewCompany = () => {
        localStorage.setItem("defaultSelectedNav", JSON.stringify("4"));
        localStorage.setItem("defaultSelectedSub", JSON.stringify("sub1"));
        // TODO fix
        window.location.href = "/dashboard/admin/new-company";
    };

    return (
        <div>
            <Skeleton loading={companyLoading} active>
                <div>
                    <Row>
                        <Col span={24}>
                            <div
                                className="z-dashboard-panel z-mb-5 z-width-100 z-admin-panel2"
                                style={{ height: "100%" }}
                            >
                                <div className="z-mb-6">
                                    <h1 className="z-dashboard-label">
                                        Companies
                                    </h1>
                                    <div className="z-flex-start z-fill-content mb-3">
                                        <img
                                            src={overviewIcon}
                                            className="img-fluid mr-3"
                                            alt="Overview Icon"
                                        />
                                        <span className="z-label-text">
                                            All companies
                                        </span>
                                    </div>
                                    <div>
                                        <Button
                                            type="primary"
                                            icon="plus"
                                            className="z-tab-btn ml-3"
                                            onClick={(e) => {
                                                addNewCompany();
                                            }}
                                        >
                                            Add new company
                                        </Button>
                                    </div>
                                    <div className="z-flex-space z-width-100">
                                        <div className="z-flex-start z-ml-30">
                                            <p className="z-search-label">
                                                Sort by
                                            </p>
                                            <Dropdown
                                                overlay={
                                                    <Menu
                                                        onClick={(e) =>
                                                            handleMenuClick(e)
                                                        }
                                                    >
                                                        <Menu.Item key="1">
                                                            1st item
                                                        </Menu.Item>
                                                        <Menu.Item key="2">
                                                            2nd item
                                                        </Menu.Item>
                                                        <Menu.Item key="3">
                                                            3rd item
                                                        </Menu.Item>
                                                    </Menu>
                                                }
                                                className="z-table-dropdown"
                                            >
                                                <Button>
                                                    Actions <Icon type="down" />
                                                </Button>
                                            </Dropdown>
                                        </div>
                                        <div>
                                            <Input
                                                className="z-nav-search z-table-search"
                                                prefix={
                                                    <Icon
                                                        type="search"
                                                        style={{
                                                            color: "#131C30",
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <CompaniesList companies={companies} fetchCompanies={fetchCompanies} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Skeleton>
        </div>
    );
}
