import React, { useEffect, useState } from "react";
import { addNewAdmin, getUsers } from "../../../APIsHandler/ApiController";
import {
    Button,
    Col,
    Icon,
    Input,
    Modal,
    Row,
    Radio,
    Skeleton,
    message,
} from "antd";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";
import AdministratorTable from "./AdministratorTable";
import CreateAdministratorModal from "./CreateAdministratorModal";

const { Search } = Input;

export default function Administrator() {
    const [navDiff, setNavDiff] = useState(10);
    const [noOfPages, setNoOfPages] = useState(0);

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    const [fetchingUsers, setFetchingUsers] = useState(true);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetchUsers();
    }, []);

    function fetchUsers() {
        setFetchingUsers(true);
        getUsers()
            .then((res) => {
                let { data } = res.data;
                const loanUsers = data.filter((user) => {
                    return user.roles[0].name !== "user";
                });
                let num = Math.ceil(loanUsers.length / navDiff);

                setUsers(loanUsers);
                setNoOfPages(num);
                setFetchingUsers(false);
            })
            .catch((err) => {
                setFetchingUsers(false);
            });
    }

    function handleMenuClick(e) {
        console.log("click", e);
    }

    // closes add new admin modal
    function closeCreateModal(e) {
        setIsCreateModalOpen(false);
    }

    // shows new admin modal
    function showCreateModal(e) {
        setIsCreateModalOpen(true);
    }

    function handleSearch(val) {
        console.log(val);
    }

    return (
        <>
            <CreateAdministratorModal
                closeModal={closeCreateModal}
                isOpen={isCreateModalOpen}
                fetchUsers={fetchUsers}
            />
            <div>
                <h1 className="z-dashboard-label">Administrators</h1>
            </div>
            <Skeleton loading={fetchingUsers} active>
                <div>
                    <Row>
                        <Col span={24}>
                            <div
                                className="z-dashboard-panel z-mb-5 z-width-100 z-admin-panel2"
                                style={{ height: "100%" }}
                            >
                                <div className="z-flex-space z-mb-6">
                                    <div className="z-width-65 z-flex-space">
                                        <div className="z-flex-start z-fill-content">
                                            <Icon
                                                type="copy"
                                                className="z-label-icon"
                                            />
                                            <p className="z-label-text">
                                                Administrators
                                            </p>
                                        </div>
                                        <div>
                                            <Button
                                                type="primary"
                                                icon="plus"
                                                className="z-tab-btn"
                                                onClick={showCreateModal}
                                            >
                                                Add administrators
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="z-flex-space z-width-100">
                                        <div className="z-flex-start z-ml-30">
                                            <p className="z-search-label">
                                                Sort by
                                            </p>
                                            <Dropdown
                                                overlay={
                                                    <Menu
                                                        onClick={
                                                            handleMenuClick
                                                        }
                                                    >
                                                        <Menu.Item key="1">
                                                            1st item
                                                        </Menu.Item>
                                                        <Menu.Item key="2">
                                                            2nd item
                                                        </Menu.Item>
                                                        <Menu.Item key="3">
                                                            3rd item
                                                        </Menu.Item>
                                                    </Menu>
                                                }
                                                className="z-table-dropdown"
                                            >
                                                <Button>
                                                    Actions <Icon type="down" />
                                                </Button>
                                            </Dropdown>
                                        </div>
                                        <div>
                                            <Search
                                                className="z-nav-search z-table-search"
                                                placeholder="input search text"
                                                onSearch={handleSearch}
                                                style={{ width: 200 }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <AdministratorTable
                                    navDiff={navDiff}
                                    noOfPages={noOfPages}
                                    users={users}
                                    fetchUsers={fetchUsers}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Skeleton>
        </>
    );
}
