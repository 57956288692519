import React from "react";
import { Icon, Row } from "antd";
import card from "../../../assets/images/undraw_credit_card_df1m (1).svg";
import cardchip from "../../../assets/images/sim-card-chip.png";
import verve from "../../../assets/images/verve.png";
import visa from "../../../assets/images/visa.jpg";
import mastercard from "../../../assets/images/mastercard_vrt_pos_92px_2x.png";

export default ({
    cardDetails,
    cards,
    currentCard,
    navLeft,
    navRight,
    launchGateway,
}) => (
    <Row>
        <div>
            {cardDetails.toString().length > 0 ? (
                <Row>
                    <p className="z-apply-label-small z-no-mb z-textp">
                        Please, select a card
                    </p>
                    <Icon
                        type="left"
                        className={
                            currentCard <= 0
                                ? "z-card-arrowl z-disabled"
                                : "z-card-arrowl"
                        }
                        onClick={(e) => {
                            navLeft(e);
                        }}
                    />
                    <div className="z-card-debit z-card-debit2">
                        <div className="z-chip">
                            <img src={cardchip} alt="cardchip" />
                        </div>
                        <div>
                            <p className="z-apply-label-small z-no-mb z-modal-parag-small z-modal-parag-small2">
                                ****
                                &nbsp;&nbsp;&nbsp;&nbsp;****&nbsp;&nbsp;&nbsp;&nbsp;
                                **** &nbsp;&nbsp;&nbsp;&nbsp;
                                {cards[currentCard].last4}
                            </p>
                        </div>

                        <div className="z-chip z-chip2">
                            <img
                                src={
                                    cards[currentCard].card_type.includes(
                                        "verve"
                                    )
                                        ? verve
                                        : cards[currentCard].card_type.includes(
                                              "visa"
                                          )
                                        ? visa
                                        : cards[currentCard].card_type.includes(
                                              "master"
                                          )
                                        ? mastercard
                                        : ""
                                }
                                alt="cardchip"
                            />
                        </div>
                    </div>
                    <p className="z-navp">
                        {currentCard + 1}/{cards.length}
                    </p>
                    <Icon
                        type="right"
                        className={
                            currentCard >= cards.length - 1
                                ? "z-card-arrowR z-disabled"
                                : "z-card-arrowR"
                        }
                        onClick={(e) => {
                            navRight(e);
                        }}
                    />
                    {cards.length > 0 && (
                        <div
                            className="z-flex-center"
                            onClick={(e) => {
                                launchGateway();
                            }}
                        >
                            {/*<Checkbox className="z-ml-12 z-checkbox" onChange={e => {this.handleRadio(e)}}/>*/}
                            <p className="z-apply-label-small z-no-mb z-modal-parag-small z-card-label-small">
                                Use a new card
                            </p>
                        </div>
                    )}
                </Row>
            ) : (
                <div>
                    <div className="z-icon-modal">
                        <img src={card} alt="card" />
                    </div>
                    <p className="z-apply-label-small z-modal-parag">
                        In order to stand in as a guarantor, you need to have a
                        card on the platform. A sum of 50 naira will be deducted
                        which will be deposited in your wallet after a
                        successful debit.
                    </p>
                </div>
            )}
        </div>
    </Row>
);
