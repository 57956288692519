import React, {Component} from 'react';
import {Col, Icon,Row, Skeleton} from "antd";

import {getAllSystemTransaction} from "../../../APIsHandler/ApiController";
import NavigationTab from "../../NavigationTab";
import TransactionRows from "../../globalComponents/TransactionRows";
import Input from "antd/es/input";


export default class SystemTransaction extends Component{

    constructor(props){
        super(props);

        this.state = {
            collapsed: false,
            todayDate: new Date(),
            selectedRowKeys: [],
            fetchingTransactions: true,
            transactions:[],
            currentNavIndex: 0,
            nextNavIndex: 10,
            navDiff: 10,
            noOfPages: 0,
            currentPage: 1,
            viewUser: false,
            currentIndex: 0
        }
    }

    componentDidMount() {
        getAllSystemTransaction().then( res => {
            let {data} =res.data;
            console.log("System Trans", data);
            let num = Math.ceil(data.length / this.state.navDiff);
            this.setState({transactions: data, noOfPages: num, fetchingTransactions: false})
        }).catch( err => {
            this.setState({fetchingTransactions: false})
        });
    }

    onChange = (date, dateString)=> {
        console.log(date, dateString);
    };

    onSelectChange = (selectedRowKeys) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    navigateRight = () =>{
        const {navDiff, nextNavIndex, currentNavIndex, currentPage, noOfPages} = this.state;

        if(currentPage !== noOfPages){
            this.setState({nextNavIndex: nextNavIndex + navDiff, currentNavIndex: currentNavIndex + navDiff,
                currentPage: ((nextNavIndex + navDiff)/navDiff)})
        }
    };

    navigateLeft = () =>{
        const {navDiff, nextNavIndex, currentNavIndex, currentPage} = this.state;

        if(currentPage !== 1){
            this.setState({nextNavIndex: nextNavIndex - navDiff, currentNavIndex: currentNavIndex - navDiff,
                currentPage: ((nextNavIndex - navDiff)/navDiff)})
        }
    };



    render() {
        const {selectedRowKeys, transactions, fetchingTransactions, currentNavIndex, nextNavIndex, navDiff, currentPage} = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return (
            <div>
                <div>
                    <h1 className="z-dashboard-label">
                        System Transactions
                    </h1>
                </div>

                <Skeleton loading={fetchingTransactions} active>
                    <div>
                        <Row>
                            <Col span={24}>
                                <div className="z-dashboard-panel z-mb-5 z-width-100 z-admin-panel2" style={{height: "100%"}}>
                                    <div className="z-flex-start z-mb-6">
                                        <Icon type="copy" className="z-label-icon"/>
                                        <p className="z-label-text">
                                            System Transactions
                                        </p>
                                    </div>
                                    <div>
                                        <TransactionRows
                                            currentNavIndex={currentNavIndex}
                                            nextNavIndex={nextNavIndex}
                                            rowSelection={rowSelection}
                                            transactions={transactions}
                                            currentPage={currentPage}
                                            navDiff={navDiff}
                                        />
                                    </div>

                                    {transactions.length > 0 &&
                                    <div className="z-admin-gr-div">
                                        <NavigationTab noOfPages={this.state.noOfPages}
                                                       currentPage={this.state.currentPage}
                                                       navigateLeft={this.navigateLeft}
                                                       navigateRight={this.navigateRight}/>
                                    </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Skeleton>
            </div>
        )
    }

}