import React, {Component} from 'react';
import {connect} from "react-redux";
import {getAuthUser, saveCard} from "../../APIsHandler/ApiController";
import {message} from "antd";
import { PAYSTACK_PK } from '../../constants';

class PaystackGateway extends Component{

    componentDidMount() {

        window.payWithPaystack(PAYSTACK_PK,
            this.props.user.data.email, this.props.user.data.first_name, this.props.user.data.last_name, this.props.amount, "NGN", this.authorizeCard, this.props.referenceId)
    }

    /*
    * authorize user card on paystack
    * */
   authorizeCard = (response) => {
       this.saveCardDetails(response, this.onVerify)
   };

   onVerify = (status, data) => {

        if(status){
            this.props.cardDetailsSuccess(data);
            message.success('Card successfully authorized.', 3);
        }else{
            message.error('Unable to authorize card. '+ (data.data) ? data.data.message : '', 3);
           this.props.cardDetailsError()
        }
   };

    /*
    * save data to db*/
    saveCardDetails = (response) => {
        saveCard({ref_code: response.reference}, this.onVerify)
    };

    render() {
        return(
            <div>

            </div>
        )
    }
}
/**
 * function to map the state to props
 * */
function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps, {getAuthUser})(PaystackGateway);
