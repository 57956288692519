import React, { useEffect, useState } from "react";
import { Button, message, Popconfirm, Skeleton } from "antd";
import {
    formatCurrency,
    formatCurrencyNZ,
    naira,
} from "../../globalComponents/globalFunctions";
import {
    convertReferalPoints,
    getReferal,
    redeemReferal,
} from "../../../APIsHandler/ApiController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { FaCoins, FaRegClipboard } from "react-icons/fa";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function Referral() {
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [redeeming, setRedeeming] = useState(false);
    const [converting, setConverting] = useState(false);
    const [referalDetail, setReferalDetail] = useState(null);
    const [referalLogs, setReferalLogs] = useState([]);

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        setLoading(true);
        getReferal()
            .then((response) => {
                setReferalDetail(response.data.data);
                setReferalLogs(response.data.data.referal_logs);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                message.error(
                    `Unable to get referal details  ${
                        error.data !== undefined ? error.data.message : ""
                    }. Please try again.`,
                    3
                );
            });
    };

    const handleRedeem = () => {
        setRedeeming(true);

        redeemReferal()
            .then(() => {
                setRedeeming(false);
                message.success(
                    "Referral amount redeemed successfully to your wallet"
                );
                history.push(`/dashboard/wallet`);
            })
            .catch((error) => {
                setRedeeming(false);
                message.error(
                    `Unable to redeem referal amount  ${
                        error.data !== undefined ? error.data.message : ""
                    }. Please try again.`,
                    3
                );
            });
    };

    const handleConvert = () => {
        setConverting(true);
        convertReferalPoints()
            .then(() => {
                setConverting(false);
                message.success(
                    "Referral points has been successfully coverted"
                );
                fetch();
            })
            .catch((error) => {
                setConverting(false);
                message.error(
                    `Unable to convert referal point  ${
                        error.data !== undefined ? error.data.message : ""
                    }. Please try again.`,
                    3
                );
            });
    };

    const copyToClipboard = (value) => {
        navigator.clipboard.writeText(value);

        message.success("Saved to Clipboard");
    };

    const isDisabled = () => {
        if (referalDetail && referalDetail.point < 1) {
            return true;
        }

        return false;
    };

    return (
        <Skeleton loading={loading} active>
            <div className="row z-wallet-flex">
                <div className="col-md-7">
                    <h1 className="z-dashboard-label">Referrals</h1>
                    <p className="z-apply-label-small z-modal-parag">
                        Share your Referral code or Referral Link with friends
                        and family, and earn points.
                    </p>
                    <h2 className="z-referral-code">
                        {referalDetail && referalDetail.referal_code}
                        <span
                            className="z-referral-copy-btn"
                            onClick={() =>
                                copyToClipboard(referalDetail.referal_code)
                            }
                        >
                            <FaRegClipboard />
                        </span>
                    </h2>
                    <div className="z-refarral-section">
                        <Button
                            className="z-landing-btn z-section-btn z-login-btn z-wallet-btn mb-3"
                            shape="round"
                            size="large"
                            onClick={() =>
                                copyToClipboard(referalDetail.referal_link)
                            }
                        >
                            <span className="z-mr-2">
                                <FaRegClipboard />
                            </span>
                            Copy Referral Link
                        </Button>
                        <div className="z-referral-card-group">
                            <div className="z-notification-div wallet-card mb-4 referral-card">
                                <div className="z-wallet-card">
                                    <div className="z-flex-space z-wallet-card-tag">
                                        <p>{naira}</p>
                                        <p>.00</p>
                                    </div>
                                    <div>
                                        <div className="z-wallet-input">
                                            {referalDetail &&
                                                formatCurrencyNZ(
                                                    referalDetail.amount
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="z-notification-div wallet-card mb-4 referral-card">
                                <div className="z-wallet-card">
                                    <div className="z-flex-space z-wallet-card-tag">
                                        <p>
                                            <FaCoins />
                                        </p>
                                        <p>.00</p>
                                    </div>
                                    <div>
                                        <div className="z-wallet-input">
                                            {referalDetail &&
                                                formatCurrencyNZ(
                                                    referalDetail.point
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Popconfirm
                            title="Are you sure you want to covert referral points to amount?"
                            onConfirm={handleConvert}
                            onCancel={() => {
                                console.log("cancel...");
                            }}
                            okText="Yes"
                            cancelText="No"
                            disabled={isDisabled()}
                        >
                            <Button
                                className="z-landing-btn z-section-btn z-login-btn z-wallet-btn mb-3"
                                shape="round"
                                size="large"
                                loading={converting}
                            >
                                Convert
                            </Button>
                        </Popconfirm>
                        <Button
                            className="z-landing-btn z-section-btn z-login-btn z-wallet-btn mb-3"
                            shape="round"
                            size="large"
                            loading={redeeming}
                            onClick={handleRedeem}
                            disabled={referalDetail && referalDetail.amount < 1}
                        >
                            Redeem
                        </Button>
                    </div>
                </div>
                <div className="col-md-5">
                    <p className="mb-3">
                        <span className="trans-header">History</span>{" "}
                        &nbsp;&nbsp;
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            size="xs"
                            className="trans-icon"
                        />
                    </p>

                    {referalLogs.map((log, i) => {
                        return (
                            <div
                                className="z-flex-space z-loan-padding z-table-row"
                                key={i + 1}
                            >
                                <div className="z-width-30">
                                    <div className="table-index">{i + 1}</div>
                                </div>
                                <div className="z-width-100 ">
                                    <div className="z-flex-column-start">
                                        <p className="trans-row">
                                            {log.description}
                                        </p>
                                        <p className="z-table-label">
                                            {moment(log.created_at).format(
                                                "Do MMMM YYYY"
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="z-width-100">
                                    <div className="">
                                        <p className="z-table-row-p">
                                            {/* {} */}
                                            {formatCurrency(log.point)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Skeleton>
    );
}
