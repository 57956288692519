import React, {Fragment, useEffect, useState} from 'react';
import {Button, Icon, message, Skeleton, Modal, Table, Badge} from "antd";
import {formatCurrency, formatCurrencyNZ, naira} from "../../globalComponents/globalFunctions";
import {
    addNewBank,
    getBankNames,
    getUserRequest, getUsersBanks,
    getWalletBalance,
    getWalletTransactions, resolveAccountCn,
    transferFundRequest
} from "../../../APIsHandler/ApiController";
import WalletModal from "./WalletModal";
import {getCurrency, numberWithCommas} from "../../../utils/functions";
import "../Dashboard.scss";
import AddBankModal from "./AddBankModal";
import {toast} from "react-toastify";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";

const UserWallet = (props) => {
        const [userWallet, setUserWallet] = useState({});
        const [loading, setLoading ] = useState(true);
        const [accountOption, setAccountOption] = useState(0);
        const [viewWalletModal, setViewWalletModal ] = useState(false);
        const [viewAddBankModal, setViewAddBankModal] = useState(false);
        const [accounts, setAccounts ] = useState([]);
        const [acctLoading, setAcctLoading ] = useState(false);
        const [banks, setBanks] = useState([]);
        const [amount, setAmount] = useState(0);
        const [transferring, setTransferring] = useState(false);

        const [accountNumber, setAccountNumber] = useState("");
        const [accountName, setAccountName] = useState("");
        const [bankName, setBankName] = useState("");
        const [nameLoading, setNameLoading] = useState(false);
        const [password, setPassword] = useState("");

        const [addBankLoading, setAddBankLoading] = useState(false);

        const [walletTransactions, setWalletTransactions] = useState([]);
        const [visible, setVisible] = useState(false);

        useEffect(() => {
            getWalletBalance().then( res => {
                let {data} = res.data;
                setUserWallet(data);
                setLoading(false);
            }).catch( err => {
                message.error("Unable to retrieve user wallet")
            });

            getBankNames().then( res => {
                let {data} = res.data;
                setBanks(data);
            }).catch( err => {
            });

            getWalletTransactions().then( res => {
                let {data} = res.data;
                setWalletTransactions(data);
            })
        }, []);
        const getUnique = (arr, comp) => {
            const unique = arr
                .map(e => e[comp])
                // store the keys of the unique objects
                .map((e, i, final) => final.indexOf(e) === i && i)
                // eliminate the dead keys & store unique objects
                .filter(e => arr[e]).map(e => arr[e]);

            return unique;
    };

        const handleOk = e => {
            console.log(e);
            setVisible(false);
        };

        const onRadioChange = (e) => {
            setAccountOption(e.target.value);
        };

        const withdraw = () => {
        setTransferring( true);
        const newAmount =  getCurrency(amount);
        const param = {bank_name: accounts[accountOption].bank_name, account_number:accounts[accountOption].account_number, amount: newAmount }

        transferFundRequest(param).then( res => {
            let wallet = userWallet;
            const numAmount =  getCurrency(amount);
            wallet.balance = wallet.balance - numAmount;
            setUserWallet(wallet);
            setTransferring(false);
            setViewWalletModal(false);
            message.success("Your account was successfully credited.", 5)
        }).catch ( err => {
            setTransferring(false);
            message.error("Unable to complete transaction. Please, try again later.", 3)
        })
    };

        const closeWalletModal = () =>{
            setViewWalletModal(false);
        };

        const showWalletModal = () => {
            setViewWalletModal(true);
            setAcctLoading(true);
            getUsersBanks().then( res => {
                const {data} = res.data;
                let accounts = [];
                const newBanks = getUnique(data, "account_number");
                for(let bank of newBanks) {
                    const institution = banks.filter((bName) => {
                        return Number(bName.code) === Number(bank.bank_code)
                    });
                    accounts.push({account_number: bank.account_number, bank_name:bank.bank_code,
                        institution: institution.length > 0 ? institution[0].name : "Bank"})
                }
                setAccounts(accounts);
                setAcctLoading(false);
            }).catch( err => {
                message.error("unable to retrieve bank accounts")
            });
        };
        const closeAddBankModal = () =>{
            setViewAddBankModal(false);
        };
        const showAddBankModal = () => {
            setViewAddBankModal(true);
        };

        const handleBankName = (value) => {
            console.log('Resolve account', value);
            setBankName(value);
            if(accountNumber.length > 9){
                console.log('Set Name loading', value, accountNumber);
                setNameLoading(true);
                resolveAccountCn({account: accountNumber, bank: value}).then( res => {
                    const {data} =  res.data;
                    setNameLoading(false);
                    setAccountName(data.account_name);
                }).catch( err => {
                    setNameLoading(false);
                    message.error('Unable to resolve account. Please check the account details.', 3);
                })
            }
        };

        const addBank = () => {
            setAddBankLoading(true);
            let formData = new FormData();
            formData.append('account_number', accountNumber);
            formData.append('password', password);
            formData.append('bank_name', bankName);

            addNewBank(formData).then( res => {
                setAddBankLoading(false);
                toast.success('Bank Account was added successfully')
            }).catch( err => {
                setAddBankLoading(false);
                toast.error((err.data !== undefined) ? (err.data.message !== undefined ? err.data.message : 'Unable to add bank account') : 'An issue was encountered' );
            })
        };

        const onInputChange = (e) =>{
            if(e.target.value.length === 0 || e.target.value === "" || e.target.value === null){
                setAmount(0);
            }else {
                setAmount(numberWithCommas(getCurrency(e.target.value)));
            }
        };

        let walletTrans = (trans, index) => {
            return (
                <tr key={index} scope="row">
                    <td>{index + 1}</td>
                    <td>{trans.model_type}</td>
                    <td>{trans.type}</td>
                    <td>{trans.amount}</td>
                </tr>
            )
        };

        return(
            <Skeleton loading={loading} active>
                <div>
                    <div>
                        <h1 className="z-dashboard-label">
                            Wallet
                        </h1>
                    </div>
                    <div className="row z-wallet-flex">
                        <div className="col-md-7">
                            <div className="z-notification-div wallet-card mb-4">
                                <div className="z-wallet-card">
                                    <div className="z-flex-space z-wallet-card-tag">
                                        <p>{naira}</p>
                                        <p>.00</p>
                                    </div>
                                    <div>
                                        <div className="z-wallet-input" >
                                            {Object.keys(userWallet).length > 0 && formatCurrencyNZ(userWallet.balance)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-12">
                                    <Button className="z-landing-btn z-section-btn z-login-btn z-wallet-btn mb-3" shape="round" size="large"
                                               onClick={e => {showWalletModal()}} >
                                        Withdraw from your wallet
                                    </Button>
                                    <Button className="z-landing-btn z-section-btn z-login-btn z-wallet-btn mb-3" shape="round" size="large"
                                            onClick={e => {showAddBankModal()}} >
                                        Add Bank
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <p className="mb-3">
                                <span className="trans-header">History</span> &nbsp;&nbsp;
                                <FontAwesomeIcon icon={faArrowRight} size="xs" className="trans-icon"/>
                            </p>

                                    { walletTransactions.map(((trans, i) => {
                                        return (
                                            <div className="z-flex-space z-loan-padding z-table-row" key={i + 1}>
                                                <div className="z-width-30">
                                                    <div className="table-index">{i + 1}</div>
                                                </div>
                                                <div className="z-width-100 ">
                                                    <div className="z-flex-column-start">
                                                        <p className="trans-row">
                                                            {
                                                                trans.model_type === "App\\Transaction" ?
                                                                    trans.model.description :
                                                                    trans.model_type.split('\\').pop().replace(/([A-Z])/g, ' $1').trim()
                                                        }</p>
                                                        <p className="z-table-label">{moment(trans.created_at).format("Do MMMM YYYY")}</p>
                                                    </div>
                                                </div>
                                                <div className="z-width-100">
                                                    <div className="">
                                                        <p className="z-table-row-p">{naira}{formatCurrency(trans.amount)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }))

                                    }
                        </div>
                    </div>
                </div>

                <Modal className="z-custom z-custom2 z-wall"
                       visible={viewWalletModal}
                       title="Withdraw"
                       onOk={handleOk}
                       onCancel={e => {closeWalletModal(e)}}
                       footer={[
                                   <Button className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn"
                                           shape="round"
                                           size="large"
                                           key="back"
                                           onClick={e => {closeWalletModal(e)}}
                                   >
                                       Cancel
                                       <Icon type="close" />
                                   </Button>,
                                   <Button className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg" shape="round"
                                           size="large"
                                           key="submit"
                                           loading={transferring}
                                           disabled={amount.length < 3 || amount === 0 }
                                           onClick={e =>{withdraw(e)}}
                                   >
                                           Withdraw
                                       <Icon type="check" />
                                   </Button>
                                 ]} >
                        <div>
                            <WalletModal onRadioChange={onRadioChange}
                                         accountOption={accountOption}
                                         accounts={accounts}
                                         banks={banks}
                                         showAddBankModal={showAddBankModal}
                                         accountNumber={accountNumber}
                                         accountName={accountName}
                                         bankName={bankName}
                                         transferring={transferring}
                                         acctLoading={acctLoading}
                                         amount={amount}
                                         nameLoading={nameLoading}
                                         onInputChange={onInputChange}
                            />
                        </div>
                </Modal>

                <Modal className="add-bank-modal"
                       visible={viewAddBankModal}
                       title="Add Bank"
                       onOk={handleOk}
                       onCancel={e => {closeAddBankModal(e)}}
                       footer={[
                           <Button className="z-landing-btn z-section-btn z-wallet-btn"
                                   shape="round"
                                   size="large"
                                   key="back"
                                   onClick={e => {closeAddBankModal(e)}}
                           >
                               Cancel
                               <Icon type="close" />
                           </Button>,
                           <Button className="z-landing-btn z-section-btn z-wallet-btn" shape="round"
                                   size="large"
                                   key="submit"
                                   loading={addBankLoading}
                                   disabled={accountNumber.length < 9 || bankName === '' || accountName === ''}
                                   onClick={e => addBank(e) }
                           >
                               Add Bank
                               <Icon type="check" />
                           </Button>
                       ]} >
                    <div>
                        <AddBankModal {...props}
                                      addBank={addBank}
                                      setAccountNumber={setAccountNumber}
                                      setAccountName={setAccountName}
                                      handleBankName={handleBankName}
                                      setNameLoading={setNameLoading}
                                      setPassword={setPassword}
                                      accountName={accountName}
                                      accountNumber={accountNumber}
                                      bankName={bankName}
                                      nameLoading={nameLoading}
                                      banks={banks}
                        />
                    </div>
                </Modal>
            </Skeleton>
        )
};

export default UserWallet
