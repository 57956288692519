import React from 'react';
import {Drawer} from "antd";
import {AdminNav} from "./AdminNav";
import UserNav from "./UserNav";

const NavDrawer = (props) => {
    const {showDrawer, userRole ,onNavClick, showDrp,showlogModal, user, changeDrawer, gotoProfile,sideBarCollapsed, currentNav,setDrawer ,drawer, closeDetails, showDetails} = props;
    return(
        <div>
            <Drawer
                title={null}
                placement="left"
                closable={false}
                onClose={showDrawer}
                visible={drawer}
                className="z-drawer"
            >
                {userRole === "admin" ?
                    <AdminNav sideBarCollapsed={sideBarCollapsed} currentNav={currentNav}  onNavClick={onNavClick} showlogModal={showlogModal} showFull={true}/> :
                    <UserNav sideBarCollapsed={sideBarCollapsed}
                             showDrp={showDrp}
                             currentNav={currentNav}
                             onNavClick={onNavClick} showlogModal={showlogModal}
                             user={user}
                             drawer={drawer}
                             showDetails={showDetails}
                             closeDetails={closeDetails}
                             gotoProfile={gotoProfile} showFull={true}/>
                }
            </Drawer>
        </div>
    )
};

export default NavDrawer;