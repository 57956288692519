import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Icon,
    Input,
    Row,
    Dropdown,
    Menu,
    Skeleton,
    message,
} from "antd";
import CreateLoanPackage from "./CreateLoanPackage";
import { getCompanies, getPackages } from "../../../APIsHandler/ApiController";
import LoanPackagesTable from "./LoanPackagesTable";

export default function LoanPackages() {
    const [fetchingLoanPackages, setFetchingLoanPackages] = useState(false);
    const [loanPackages, setLoanPackages] = useState([]);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [companyLoading, setCompanyLoading] = useState(false);
    const [navDiff, setNavDiff] = useState(10);
    const [noOfPages, setNoOfPages] = useState(0);

    useEffect(() => {
        /** get all loan packages */
        getLoanPackages();

        /** get all companies */
        getAllCompanies();
    }, []);

    /**
     * get all companies
     */
    function getAllCompanies() {
        setCompanyLoading(true);
        getCompanies()
            .then((res) => {
                let { data } = res.data;
                const filteredData = data.filter(
                    (company) => company.status === "approved"
                );

                setCompanies(filteredData);
                setCompanyLoading(false);
            })
            .catch((err) => {
                setCompanyLoading(false);
                message.error("Unable to retrieve companies at the moment.", 3);
            });
    }

    /** add loan packages*/
    function getLoanPackages() {
        setFetchingLoanPackages(true);
        getPackages((status, data) => {
            if (status) {
                let num = Math.ceil(data.length / navDiff);
                setLoanPackages(data);
                setNoOfPages(num);
                setFetchingLoanPackages(false);
            } else {
                setFetchingLoanPackages(false);
                message.error("Unable to load packages at the moment!");
            }
        });
    }

    function closeCreateModal() {
        setShowCreateModal(false);
    }

    return (
        <>
            <div>
                <h1 className="z-dashboard-label">Loan Packages</h1>
            </div>
            <Skeleton loading={fetchingLoanPackages} active>
                <div>
                    <Row>
                        <Col span={24}>
                            <div
                                className="z-dashboard-panel z-mb-5 z-width-100 z-admin-panel2"
                                style={{ height: "100%" }}
                            >
                                <div className="z-flex-space z-mb-6">
                                    <div className="z-width-65 z-flex-space">
                                        <div className="z-flex-start z-fill-content">
                                            <Icon
                                                type="copy"
                                                className="z-label-icon"
                                            />
                                            <p className="z-label-text">
                                                All Loan Packages
                                            </p>
                                        </div>
                                        <div>
                                            <Button
                                                type="primary"
                                                icon="plus"
                                                className="z-tab-btn"
                                                onClick={() =>
                                                    setShowCreateModal(true)
                                                }
                                            >
                                                Add new package
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="z-flex-space z-width-100">
                                        <div className="z-flex-start z-ml-30">
                                            <p className="z-search-label">
                                                Sort by
                                            </p>
                                            <Dropdown
                                                overlay={
                                                    <DropdownOptions
                                                        handleMenuClick={() =>
                                                            console.log("click")
                                                        }
                                                    />
                                                }
                                                className="z-table-dropdown"
                                            >
                                                <Button>
                                                    Actions <Icon type="down" />
                                                </Button>
                                            </Dropdown>
                                        </div>
                                        <div>
                                            <Input
                                                className="z-nav-search z-table-search"
                                                prefix={
                                                    <Icon
                                                        type="search"
                                                        style={{
                                                            color: "#131C30",
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <LoanPackagesTable
                                    loanPackages={loanPackages}
                                    fetchLoanPackages={getLoanPackages}
                                    companies={companies}
                                    companyLoading={companyLoading}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Skeleton>
            <CreateLoanPackage
                isVisible={showCreateModal}
                closeModal={closeCreateModal}
                companies={companies}
                companyLoading={companyLoading}
                fetchLoanPackages={getLoanPackages}
            />
        </>
    );
}

const DropdownOptions = ({ handleMenuClick }) => (
    <Menu onClick={handleMenuClick}>
        <Menu.Item key="1">1st item</Menu.Item>
        <Menu.Item key="2">2nd item</Menu.Item>
        <Menu.Item key="3">3rd item</Menu.Item>
    </Menu>
);

