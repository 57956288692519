import React, { Component } from "react";
import { withRouter } from "react-router";
import {
    Button,
    Col,
    Icon,
    message,
    Input,
    Row,
    Upload,
    Skeleton,
    notification,
    Select,
    Checkbox,
} from "antd";
import { Redirect } from "react-router-dom";
import {
    getAllCompanyLevels,
    getBankNames,
    getPackages,
    getUserCardById,
    getUserRequest,
    requestLoan,
    resolveAccountCn,
} from "../../../APIsHandler/ApiController";
import PaystackGateway from "../../paymentDir/PaystackGateway";
import { naira } from "../../globalComponents/globalFunctions";
import { getCurrency, numberWithCommas } from "../../../utils/functions";
import UserLoanPackages from "./UserLoanPackages";
import overviewIcon from "../../../assets/images/dashboard/overview.svg";
import LoanPaybackSummary from "./LoanPaybackSummary";
import UserApplicationGuarrantorForm from "./UserApplicationGuarrantorForm";

const { Option } = Select;
const openNotification = (type, title, description) => {
    notification[type]({
        message: `${title}`,
        description: `${description}`,
    });
};

const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
};

class UserApplication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentAmount: "",
            loanPackageId: null,
            loanPackages: [],
            fetchingLoan: true,
            guarantorEmail: "",
            loanLoading: true,
            loans: [],
            showPaymentModal: false,
            launchPaystack: false,
            authorizedCard: "",
            accountNumber: "",
            accountName: "",
            bankName: "",
            showLoanModal: false,
            banksLoading: true,
            banks: [],
            initReference: "",
            initAmount: 0,
            requestAmount: "",
            companyLevels: [],
            companyLevelId: null,
            companyLevelLoading: true,
            redirectToHome: false,
            showPaybackSummaryModal: false,
            isAcknowleged: false,
            guarantorFirstname: "",
            guarantorLastname: "",
            guarantorPhone: "",
            guarantorLevel: null,
            guarantorAccountNumber: "",
            guarantorBankName: "",
            guarantorAccountName: "",
            guarantorHasAccount: true,
        };
    }

    componentDidMount() {
        this.setCard(this.props.match.params.cardAuthId);

        /*
         * Get loan package*/
        this.getLoanPackage();
        this.getUserLoans();
        getBankNames()
            .then((res) => {
                let { data } = res.data;
                this.setState({ banks: data, banksLoading: false });
            })
            .catch((err) => {
                this.setState({ banksLoading: false });
                message.error(`Unable to retrieve banks.`);
            });

        this.getCompanyLevels();
    }

    setCard = (cardAuthId) => {
        getUserCardById(cardAuthId, (status, data) => {
            if (status) {
                this.setState({
                    selectedCard: data.last4,
                    authorizedCard: cardAuthId,
                });
            } else {
                message.error(`Unable to retrieve card.`);
            }
        });
    };

    openPaybackSummaryModal = () => {
        this.setState({
            showPaybackSummaryModal: true,
        });
    };

    closePaybackSummaryModal = () => {
        this.setState({
            showPaybackSummaryModal: false,
        });
    };

    setGuarantorAccountName = (acountName) => {
        this.setState({
            guarantorAccountName: acountName,
        });
    };

    setGuarantorHasAccount = (hasAccount) => {
        this.setState({
            guarantorHasAccount: hasAccount,
        });
    };

    /*
     * handles loan radio input*/
    onRadioChange = (e) => {
        this.setState({
            loanPackageId: e.target.value,
        });
    };

    //handle slider value change an updates the input change
    changeInput = (value) => {
        this.setState({ currentAmount: value });
    };

    //handle the input change
    changeInput2 = (e) => {
        if (
            e.target.name === "currentAmount" ||
            e.target.name === "requestAmount"
        ) {
            if (
                e.target.value.length === 0 ||
                e.target.value === "" ||
                e.target.value === null
            ) {
                this.setState({ [e.target.name]: 0 });
            } else {
                this.setState({
                    [e.target.name]: numberWithCommas(
                        getCurrency(e.target.value)
                    ),
                });
            }
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    };

    changeInput3 = (e) => {
        const { bankName } = this.state;
        this.setState({ [e.target.name]: e.target.value }, () => {
            if (this.state.accountNumber.length > 9 && bankName.length > 0) {
                this.resolveAccount();
            }
        });
    };

    //scrolls input values down
    navDown = () => {
        this.setState({ showLoanModal: true });
    };

    //scrolls input values up
    navUp = () => {
        this.setState({ showLoanModal: true });
    };

    /*
     * add loan packages*/
    getLoanPackage = () => {
        getPackages(this.ongetLoan);
    };

    getCompanyLevels = () => {
        getAllCompanyLevels()
            .then((resp) => {
                this.setState({
                    companyLevels: resp.data.data,
                    companyLevelLoading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    companyLevelLoading: false,
                });
                console.log(error);
            });
    };

    getCompanyLevelNameFromCompanyLevelId = (companyLevelId) => {
        const filtered = this.state.companyLevels.filter(
            (companyLevel) => companyLevel.id == companyLevelId
        );

        return filtered.length > 0 ? filtered[0].name : "";
    };

    /*
     * callback for add packages*/
    ongetLoan = (status, data) => {
        if (status) {
            const approved = data.filter(
                (loanpackage) => loanpackage.status === "approved"
            );
            this.setState({ loanPackages: approved, fetchingLoan: false });
        } else {
            this.setState({ fetchingLoan: false });
        }
    };

    /*
     * To submit loan application*/
    submitApplication = () => {
        const {
            loanPackageId,
            currentAmount,
            guarantorEmail,
            accountName,
            accountNumber,
            bankName,
            requestAmount,
            companyLevelId,
            // isAcknowleged,
            guarantorFirstname,
            guarantorLastname,
            guarantorPhone,
            guarantorLevel,
            guarantorAccountNumber,
            guarantorBankName,
            guarantorAccountName,
            guarantorHasAccount,
        } = this.state;

        this.setState({ loading: true });

        const formData = new FormData();

        formData.append("loan_package_id", loanPackageId);
        formData.append(
            "current_salary",
            getCurrency(currentAmount).toString()
        );
        formData.append("guarantor_email", guarantorEmail);
        formData.append("description", "");
        formData.append("account_name", accountName);
        formData.append("account_number", accountNumber);
        formData.append("bank_name", bankName);
        formData.append("gw_authorization_code", this.state.authorizedCard);
        formData.append("amount", getCurrency(requestAmount).toString());
        formData.append("company_level_id", companyLevelId);
        formData.append("guarantor_has_account", guarantorHasAccount);

        if (!guarantorHasAccount) {
            formData.append("guarantor_firstname", guarantorFirstname);
            formData.append("guarantor_lastname", guarantorLastname);
            formData.append("guarantor_phone", guarantorPhone);
            formData.append("guarantor_level", guarantorLevel);
            formData.append("guarantor_account_number", guarantorAccountNumber);
            formData.append("guarantor_bank_name", guarantorBankName);
            formData.append("guarantor_account_name", guarantorAccountName);
        }

        requestLoan(formData, this.onCreate);
    };

    isApplyButtonDisabled = () => {
        const {
            loanPackageId,
            currentAmount,
            guarantorEmail,
            accountName,
            accountNumber,
            bankName,
            requestAmount,
            companyLevelId,
            isAcknowleged,
            guarantorFirstname,
            guarantorLastname,
            guarantorPhone,
            guarantorLevel,
            guarantorAccountNumber,
            guarantorBankName,
            guarantorAccountName,
            guarantorHasAccount,
        } = this.state;

        if (!guarantorHasAccount) {
            if (
                guarantorFirstname.length < 1 ||
                guarantorLastname.length < 1 ||
                guarantorPhone.length < 1 ||
                guarantorAccountNumber.length < 1 ||
                guarantorBankName.length < 1 ||
                guarantorAccountName.length < 1 ||
                !guarantorLevel
            ) {
                return true;
            }
        }

        if (
            guarantorEmail.length > 0 &&
            currentAmount.length > 0 &&
            requestAmount.length > 0 &&
            accountName.length > 0 &&
            bankName.length > 0 &&
            accountNumber.length > 0 &&
            loanPackageId &&
            companyLevelId &&
            isAcknowleged
        ) {
            return false;
        }

        return true;
    };

    //Loan application callback
    onCreate = (status, data) => {
        const { loans } = this.state;
        if (status) {
            loans.unshift(data);
            openNotification(
                "success",
                "Request Successful",
                "You have successfully created a loan request."
            );
            this.setState({
                loans,
                loanPackageId: null,
                currentAmount: 0,
                guarantorEmail: "",
                companyLevelId: null,
                loading: false,
                redirectToHome: true,
            });
        } else {
            this.setState({ loading: false });
            openNotification(
                "error",
                "Creation failed",
                "Unable to create loan request. " + data.data.message
            );
        }
    };

    /*
     * callback when card has been added*/
    onAddCard = (status, data) => {
        if (status) {
            this.setState({ loading: false });
            openNotification(
                "success",
                "Request Successful",
                "Credit card successfully added to loan request."
            );
        } else {
            this.setState({ loading: false });
            openNotification(
                "error",
                "Creation failed",
                "Unable to add credit card to loan request." + data.data.message
            );
        }
    };

    /*
     * get user loans*/
    getUserLoans = () => {
        getUserRequest()
            .then((res) => {
                const { data } = res.data;
                this.setState({ loans: data });
            })
            .catch((err) => {
                this.setState({ loanLoading: false });
                message.error("Unable to retrieve loans at the moment.", 3);
            });
    };

    //closes payment modal
    closeModal = (e) => {
        this.setState({ showPaymentModal: false });
    };

    //closes loan modal
    closeModal2 = (e) => {
        this.setState({ showLoanModal: false });
    };

    /*
     * Resolve account name*/
    resolveAccount = () => {
        const { accountNumber, bankName } = this.state;
        if (accountNumber.length > 9 && bankName.length > 0) {
            this.setState({ nameLoading: true });
            resolveAccountCn({ account: accountNumber, bank: bankName })
                .then((res) => {
                    const { data } = res.data;
                    this.setState({
                        accountName: data.account_name,
                        nameLoading: false,
                    });
                })
                .catch((err) => {
                    this.setState({ nameLoading: false });
                    message.error(
                        "Unable to resolve account. Please check the account details.",
                        3
                    );
                });
        }
    };

    /*bank name select handler*/
    onSelectChange = (value) => {
        const { accountNumber } = this.state;
        this.setState({ bankName: value }, () => {
            if (accountNumber.length > 9) {
                this.resolveAccount();
            }
        });
    };

    onCompnayLevelChange = (value) => {
        this.setState({
            companyLevelId: value,
        });
    };

    /*
     * show payment modat*/
    showPayment = () => {
        this.setState({ showPaymentModal: true });
    };

    getLoanPackageById = (loanPackages, loanPackageId) => {
        const filteredLoanPackages = loanPackages.filter((loanPackage) => {
            return loanPackage.id === loanPackageId;
        });

        if (filteredLoanPackages.length > 0) {
            return filteredLoanPackages[0].name;
        }

        return "Select Loan";
    };

    render() {
        const {
            currentAmount,
            loanPackageId,
            loanPackages,
            fetchingLoan,
            guarantorEmail,
            loading,
            loans,
            loanLoading,
            showPaymentModal,
            launchPaystack,
            accountNumber,
            accountName,
            banks,
            showLoanModal,
            nameLoading,
            bankName,
            initReference,
            initAmount,
            requestAmount,
            companyLevels,
            companyLevelId,
            companyLevelLoading,
            redirectToHome,
            showPaybackSummaryModal,
            isAcknowleged,
            selectedCard,
        } = this.state;

        return (
            <div>
                <Row>
                    <Col span={12} className="z-res-12">
                        <div className="z-height-100_ z-dash-p z-apply-shadow z-dash-apply">
                            <h1 className="z-dashboard-label">Apply</h1>
                            <div className="z-flex-start z-dashboard-side mb-3">
                                <img
                                    src={overviewIcon}
                                    className="img-fluid mr-3"
                                    alt="Overview Icon"
                                />
                                <span className="z-label-text">
                                    New Application
                                </span>
                            </div>
                            <div className="">
                                <Row>
                                    <Col
                                        span={12}
                                        className="z-width-44 z-mr-6"
                                    >
                                        <p className="z-apply-label">
                                            Current monthly salary
                                        </p>
                                        <div className="z-apply-card">
                                            <div className="z-flex-space z-apply-tag">
                                                <p>{naira}</p>
                                                <p>.00</p>
                                            </div>
                                            <div>
                                                <Input
                                                    size="large"
                                                    placeholder="1000"
                                                    value={currentAmount}
                                                    name="currentAmount"
                                                    className="z-footer-input z-apply-input z-noborder"
                                                    onChange={(e) => {
                                                        this.changeInput2(e);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12} className="z-width-44">
                                        <p className="z-apply-label-small">
                                            Select Level
                                        </p>
                                        <Skeleton
                                            loading={companyLevelLoading}
                                            active
                                        >
                                            <div>
                                                <Select
                                                    className="z-apply-select"
                                                    style={{ width: "100%" }}
                                                    placeholder="Select a Level"
                                                    onChange={
                                                        this
                                                            .onCompnayLevelChange
                                                    }
                                                >
                                                    {companyLevels.map(
                                                        (companyLevel, i) => {
                                                            return (
                                                                <Option
                                                                    value={
                                                                        companyLevel.id
                                                                    }
                                                                    key={i}
                                                                >
                                                                    {
                                                                        companyLevel.name
                                                                    }
                                                                </Option>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </div>
                                        </Skeleton>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="z-width-44">
                                        <p className="z-apply-label">
                                            Select loan package
                                        </p>
                                        <Skeleton loading={fetchingLoan} active>
                                            <div className="z-apply-card z-flex-space">
                                                <div>
                                                    <Input
                                                        size="large"
                                                        placeholder="Low"
                                                        value={this.getLoanPackageById(
                                                            loanPackages,
                                                            loanPackageId
                                                        )}
                                                        disabled={
                                                            companyLevelId ===
                                                            null
                                                        }
                                                        className="z-footer-input z-apply-input z-noborder"
                                                        onClick={(e) => {
                                                            this.navUp(e);
                                                        }}
                                                    />
                                                </div>
                                                <div className="z-div-arrow z-mr-15">
                                                    <Icon
                                                        type="up"
                                                        onClick={(e) => {
                                                            this.navUp(e);
                                                        }}
                                                    />
                                                    <Icon
                                                        type="down"
                                                        className={"z-disabled"}
                                                        onClick={(e) => {
                                                            this.navDown(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Skeleton>
                                    </Col>
                                    <Col
                                        span={12}
                                        className="z-width-44 z-ml-6"
                                    >
                                        <p className="z-apply-label">
                                            Loan Amount
                                        </p>
                                        <div className="z-apply-card">
                                            <div className="z-flex-space z-apply-tag">
                                                <p>{naira}</p>
                                                <p>.00</p>
                                            </div>
                                            <div>
                                                <Input
                                                    size="large"
                                                    placeholder="1000"
                                                    value={requestAmount}
                                                    name="requestAmount"
                                                    className="z-footer-input z-apply-input z-noborder"
                                                    onChange={(e) => {
                                                        this.changeInput2(e);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="z-mt-7">
                                    <Col
                                        span={12}
                                        className="z-width-44 z-mr-6"
                                    >
                                        <p className="z-apply-label-small">
                                            Account Number
                                        </p>
                                        <div>
                                            <Input
                                                size="large"
                                                placeholder="Account Number"
                                                name="accountNumber"
                                                value={accountNumber}
                                                onChange={(e) => {
                                                    this.changeInput3(e);
                                                }}
                                                className="z-footer-input z-apply-input z-apply-input-small"
                                            />
                                        </div>
                                    </Col>
                                    <Col span={12} className="z-width-44">
                                        <p className="z-apply-label-small">
                                            Bank Name
                                        </p>
                                        <div>
                                            <Select
                                                className="z-apply-select"
                                                showSearch
                                                style={{ width: "100%" }}
                                                placeholder="Select a bank"
                                                optionFilterProp="children"
                                                onChange={this.onSelectChange}
                                                filterOption={(input, option) =>
                                                    option.props.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                            >
                                                {banks.map((bank, i) => {
                                                    return (
                                                        <Option
                                                            value={bank.code}
                                                            key={i}
                                                        >
                                                            {bank.name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="z-mt-7">
                                    <Col
                                        span={12}
                                        className="z-width-44 z-mr-6"
                                    >
                                        <p className="z-apply-label-small">
                                            Account Name
                                        </p>
                                        <Skeleton loading={nameLoading} active>
                                            <div>
                                                <Input
                                                    size="large"
                                                    placeholder="Account Name"
                                                    name="accountName"
                                                    value={accountName}
                                                    onChange={(e) => {
                                                        this.changeInput2(e);
                                                    }}
                                                    className="z-footer-input z-apply-input z-apply-input-small"
                                                    disabled={
                                                        accountName.length > 2
                                                    }
                                                />
                                            </div>
                                        </Skeleton>
                                    </Col>
                                    <Col span={12} className="z-width-44">
                                        <p className="z-apply-label-small">
                                            Input guarantor’s email
                                        </p>
                                        <div>
                                            <Input
                                                size="large"
                                                placeholder="email"
                                                name="guarantorEmail"
                                                value={guarantorEmail}
                                                onChange={(e) => {
                                                    this.changeInput2(e);
                                                }}
                                                className="z-footer-input z-apply-input z-apply-input-small z-lowercase"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <UserApplicationGuarrantorForm
                                    companyLevelLoading={companyLevelLoading}
                                    companyLevels={companyLevels}
                                    banks={banks}
                                    state={this.state}
                                    handleField={this.changeInput2}
                                    setGuarantorAccountName={
                                        this.setGuarantorAccountName
                                    }
                                    setGuarantorHasAccount={
                                        this.setGuarantorHasAccount
                                    }
                                />
                                <Row className="z-mt-7">
                                    <Col
                                        span={12}
                                        className="z-width-44 z-mr-6"
                                    >
                                        <p className="z-apply-label-small">
                                            Selected card
                                        </p>
                                        <div className="z-footer-input z-apply-input z-apply-input-small z-lowercase">
                                            {selectedCard &&
                                                `**** **** **** ${selectedCard}`}
                                        </div>
                                        <div className="z-apply-label-large z-label-custom">
                                            <Checkbox
                                                className="z-no-mb z-modal-parag-small z-mt-5"
                                                onChange={() => {
                                                    this.setState({
                                                        isAcknowleged:
                                                            !isAcknowleged,
                                                    });
                                                }}
                                                checked={isAcknowleged}
                                                name="acknowlege"
                                            >
                                                I understand and confirm that
                                                the debit card added for the
                                                loan repayment is the same card
                                                linked to my salary account
                                            </Checkbox>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="z-mt-15_">
                                    <Col span={24} className="z-flex-start">
                                        <Button
                                            className="z-landing-btn z-section-btn z-login-btn z-btn-medium "
                                            shape="round"
                                            size="large"
                                            onClick={
                                                this.openPaybackSummaryModal
                                            }
                                            disabled={this.isApplyButtonDisabled()}
                                        >
                                            Apply
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>

                {launchPaystack ? (
                    <PaystackGateway
                        amount={initAmount}
                        referenceId={initReference}
                        cardDetailsSuccess={this.cardDetailsSuccess}
                        cardDetailsError={this.cardDetailsError}
                    />
                ) : null}

                <UserLoanPackages
                    closeModal2={this.closeModal2}
                    currentAmount={currentAmount}
                    loanPackageId={loanPackageId}
                    loanPackages={loanPackages}
                    onRadioChange={this.onRadioChange}
                    radioStyle={radioStyle}
                    showLoanModal={showLoanModal}
                    companyLevelId={companyLevelId}
                />
                <LoanPaybackSummary
                    amountRequested={getCurrency(requestAmount)}
                    loanpackageId={loanPackageId}
                    closeModal={this.closePaybackSummaryModal}
                    showModal={showPaybackSummaryModal}
                    onApprove={this.submitApplication}
                    isApproving={loading}
                    packageName={this.getLoanPackageById(
                        loanPackages,
                        loanPackageId
                    )}
                    amount={getCurrency(requestAmount).toString()}
                    currentSalary={getCurrency(currentAmount)}
                    companyLevel={this.getCompanyLevelNameFromCompanyLevelId(
                        companyLevelId
                    )}
                    guarantorEmail={guarantorEmail}
                />
                {redirectToHome && <Redirect to="/dashboard" />}
            </div>
        );
    }
}

export default withRouter(UserApplication);
