import React from "react";
import { Icon } from "antd";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";

export default function UserLoanMainDetails({
    currentSalary,
    companyLevel,
    guarantorEmail,
}) {
    return (
        <>
            <div className="z-flex-row z-loan-padding2 z-table-row z-mb-2">
                <div className="z-width-100 z-flex-start">
                    <div className="z-new-dIcon z-loan-drIcon">
                        <Icon type="cloud" />
                    </div>
                    <div className="z-width-100">
                        <p className="z-table-row-p">Current salary</p>
                    </div>
                </div>
                <div className="z-width-100">
                    <div className="">
                        <p className="z-table-row-p">
                            {naira}
                            {formatCurrency(currentSalary)}
                        </p>
                    </div>
                </div>
            </div>
            <div className="z-flex-row z-loan-padding2 z-table-row z-mb-2">
                <div className="z-width-100 z-flex-start">
                    <div className="z-new-dIcon z-loan-drIcon">
                        <Icon type="cloud" />
                    </div>
                    <div className="z-width-100">
                        <p className="z-table-row-p">Current Level</p>
                    </div>
                </div>
                <div className="z-width-100">
                    <div className="">
                        <p className="z-table-row-p">{companyLevel}</p>
                    </div>
                </div>
            </div>
            <div className="z-flex-space z-loan-padding2 z-table-row">
                <div className="z-width-100 z-flex-start">
                    <div className="z-new-dIcon z-loan-drIcon">
                        <Icon type="cloud" />
                    </div>
                    <div className="z-width-100">
                        <p className="z-table-row-p">Guarantor email </p>
                    </div>
                </div>
                <div className="z-width-100">
                    <div className="">
                        <p className="z-table-row-p">{guarantorEmail}</p>
                    </div>
                </div>
            </div>
        </>
    );
}
