import React, { useState } from "react";
import { Button, Icon, Input, Modal, Row, message } from "antd";
import { editCompanyLevel } from "../../../APIsHandler/ApiController";

export default function EditCompanyLevel({ companyLevel, reload }) {
    const companyLevelId = companyLevel.id;
    const companyId = companyLevel.company_id;
    const companyName = companyLevel.company.name;

    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [name, setName] = useState(companyLevel.name);

    function handleClick() {
        if (name.length < 1) return message.error("Level name is required");

        setIsLoading(true);

        editCompanyLevel(companyLevelId, { name, company_id: companyId })
            .then(() => {
                setIsLoading(false);
                toggleModal();
                reload();
            })
            .catch((error) => {
                setIsLoading(false);
                message.error(
                    `Unable to edit company level.  ${
                        error.data !== undefined ? error.data.message : ""
                    }. Please try again.`,
                    3
                );
            });
    }

    function toggleModal() {
        setIsVisible(!isVisible);
    }

    return (
        <>
            <Button
                // type="success"
                icon="edit"
                className="z-table-btn success"
                onClick={toggleModal}
            >
                Edit
            </Button>
            <Modal
                className="z-custom z-custom2 z-custom3 z-custom4"
                visible={isVisible}
                title={"w"}
                onCancel={toggleModal}
                footer={[
                    <Button
                        className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row z-btn-company z-border-active"
                        shape="round"
                        size="large"
                        onClick={toggleModal}
                        key={1}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key={2}
                        className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg z-btn-company2-active"
                        shape="round"
                        size="large"
                        onClick={handleClick}
                        loading={isLoading}
                    >
                        Edit
                    </Button>,
                ]}
            >
                <div>
                    <div className="z-flex-space z-package-label-div">
                        <div>
                            <p className="z-loan-header z-package-header">
                                {`Edit ${companyName} Level`}
                            </p>
                        </div>
                        <div>
                            <p className="z-loan-header z-loan-header-small">
                                EDIT LEVEL
                            </p>
                        </div>
                    </div>
                    <Row>
                        <div className="z-flex-column-center z-com-p2">
                            <div className="z-admin-left z-com-div z-mb-6">
                                <div>
                                    <p>level Name</p>
                                </div>
                                <div>
                                    <Input
                                        className="z-comp-input z-backlist-input"
                                        value={name}
                                        name="name"
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            </Modal>
        </>
    );
}
