

export const formatCurrency = (num) => {
    if(num === 'undefined' || num === null || num.length < 1){
        return 0
    }else {
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
};

export const formatCurrencyNZ = (num) => {
    if(num === 'undefined' || num.length < 1){
        return 0
    }else {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
}

export const naira = '₦'

