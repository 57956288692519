import React from "react";
import {Badge, Icon} from "antd";
import Icon4 from "../../assets/images/icons-15.png";

export default ({notifications, deleteNotification, deleteNote, markAll, badge}) => (

        <div className="z-nav-icon z-nav-search-image z-more">
            <img src={Icon4} alt="icon"/>
            {badge > 0 && <Badge count={badge} className="z-badge">
                <a href={void(0)} onClick={(e) => {  e.preventDefault() }} className="head-example" />
            </Badge>}
            <div className="z-more-top z-nav-search-image-more z-scroll-bar" onMouseEnter={e => {markAll()}}>
                {notifications.length > 0 && <p className="z-p-notification">Notifications [{notifications.length}]</p>}
                {notifications.length < 1 && <p className="z-p-notification">Notifications</p>}
                {notifications.map((notification,  i) => {
                    return(
                        <div className="z-flex-end" key={i}>
                            <div className="z-flex-start z-timeline z-mb-3 z-row-height" key={i}>
                                <div className="z-notification-dIcon z-icon-circle z-flex-column-center">
                                    <Icon type="mail"/>
                                </div>
                                <p className="z-not-p">{notification.data.message}</p>

                            </div>
                            <Icon type={deleteNote.includes(notification.id) ? "loading":"close-circle"} className="close-white" onClick={e=>{deleteNotification(notification.id)}}/>
                        </div>
                    )})
                }

            </div>
        </div>

)