import React, { useState } from "react";
import {
    Button,
    Col,
    Icon,
    Input,
    Modal,
    Row,
    Select,
    Slider,
    notification,
    message,
} from "antd";
import {
    addLoanPackage,
    getComanyLevelByCompanyId,
} from "../../../APIsHandler/ApiController";
import { getCurrency, numberWithCommas } from "../../../utils/functions";
import DefaultCompanyAvatar from "../../../assets/images/defaultCompanyIcon.png";

const Option = Select.Option;

const openNotification = (type, title, description) => {
    notification[type]({
        message: `${title}`,
        description: `${description}`,
    });
};

export default function CreateLoanPackage({
    isVisible,
    closeModal,
    companyLoading,
    companies,
    fetchLoanPackages
}) {
    const [name, setName] = useState();
    const [loan, setLoan] = useState(500000);
    const [rate, setRate] = useState(50);
    const [tenure, setTenure] = useState(0);
    const [companyId, setCompanyId] = useState();
    const [companyLevelId, setCompanyLevelId] = useState();

    const [isLoading, setIsLoading] = useState(false);
    const [companyLevelLoading, setCompanyLevelLoading] = useState(false);
    const [allCompanyLevels, setAllCompanyLevels] = useState([]);

    /** add loan packages*/
    function handleAddLoanPackage() {
        setIsLoading(true);

        let amount = getCurrency(loan);

        const params = {
            name: name,
            tenure: tenure,
            interest_rate: rate,
            amount,
            company_id: companyId,
            company_level: companyLevelId,
        };

        addLoanPackage(params, onAddLoan);
    }

    /** callback for add packages*/
    function onAddLoan (status, data) {
        
        if (status) {
            setIsLoading(false);

            fetchLoanPackages()
            setName("");
            setRate(0);
            setLoan(0);
            setTenure("");
            setCompanyId(null);
            setAllCompanyLevels([]);
            
            closeModal();
            
            openNotification(
                "success",
                "Package created",
                "Loan package successfully created."
            );
        } else {
            this.setState({ creation: false });
            openNotification(
                "error",
                "Package creation failed",
                "Unable to create loan package. Please, try again later."
            );
        }
    };

    /**
     * Get Company Level from server
     */
    function getAllCompanyLevels(companyId) {
        getComanyLevelByCompanyId(companyId)
            .then((resp) => {
                setCompanyLevelLoading(false);
                setAllCompanyLevels(resp.data.data);
            })
            .catch((error) => {
                console.log(error);
                setCompanyLevelLoading(false);
                message.error(
                    "Unable to retrieve company level at the moment.",
                    3
                );
            });
    }

    /**
     * adds company's ID to state
     * @param {int} id company ID
     */
    function onCompanyChange(id) {
        setCompanyId(id);
        setAllCompanyLevels([]);

        // fetch all Company levels
        getAllCompanyLevels(id);
    }

    function onCompanyLevelChange(id) {
        setCompanyLevelId(id);
    }

    function handleOnLoanChange(e) {
        const value = e.target.value;

        if (value.length === 0 || value === "" || value === null) {
            setLoan(value);
        } else {
            setLoan(numberWithCommas(getCurrency(value)));
        }
    }

    return (
        <Modal
            className="z-custom z-custom2 z-custom3 "
            visible={isVisible}
            title={"w"}
            onCancel={closeModal}
            footer={[
                <Button
                    className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row z-btn-company z-border-active"
                    shape="round"
                    size="large"
                    onClick={closeModal}
                    key={1}
                >
                    Cancel
                </Button>,
                <Button
                    key={2}
                    className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg z-btn-company2-active"
                    shape="round"
                    size="large"
                    onClick={handleAddLoanPackage}
                    loading={isLoading}
                >
                    Add
                </Button>,
            ]}
        >
            <div>
                <div className="z-flex-space z-package-label-div">
                    <div>
                        <p className="z-loan-header z-package-header">
                            Add new loan package
                        </p>
                    </div>
                    <div>
                        <p className="z-loan-header z-loan-header-small">
                            NEW PACKAGE
                        </p>
                    </div>
                </div>
                <Row>
                    <div className="z-flex-column-center z-com-p2 loan-package-modal">
                        <div className="z-admin-left z-flex-space z-com-div z-mb-4">
                            <div>
                                <p>Select Company</p>
                            </div>
                            <div>
                                <Select
                                    defaultValue="Choose a company"
                                    size="default"
                                    className="z-comp-input z-package-input"
                                    onChange={onCompanyChange}
                                >
                                    {companyLoading ? (
                                        <Option value="loading...">
                                            <Icon type="loading" />
                                        </Option>
                                    ) : (
                                        companies.map((company, i) => {
                                            return (
                                                <Option
                                                    value={company.id}
                                                    className="z-select-input"
                                                    key={i}
                                                >
                                                    <img
                                                        src={
                                                            company.logo
                                                                ? company.logo
                                                                : DefaultCompanyAvatar
                                                        }
                                                        alt="logo"
                                                    />{" "}
                                                    {company.name}
                                                </Option>
                                            );
                                        })
                                    )}
                                </Select>
                            </div>
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div z-mb-4">
                            <div>
                                <p>Select Level</p>
                            </div>
                            <div>
                                <Select
                                    mode="multiple"
                                    placeholder="Select a Level"
                                    size="default"
                                    className="z-comp-input z-package-input"
                                    onChange={onCompanyLevelChange}
                                    value={companyLevelId}
                                >
                                    {companyLevelLoading ? (
                                        <Option value="loading...">
                                            <Icon type="loading" />
                                        </Option>
                                    ) : (
                                        allCompanyLevels.map((level, i) => {
                                            return (
                                                <Option
                                                    value={level.id}
                                                    className="z-select-input"
                                                    key={i}
                                                >
                                                    {level.name}
                                                </Option>
                                            );
                                        })
                                    )}
                                </Select>
                            </div>
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div z-mb-6">
                            <div>
                                <p>Loan package name</p>
                            </div>
                            <div>
                                <Input
                                    className="z-comp-input z-package-input"
                                    value={name}
                                    name="name"
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div z-mb-1">
                            <div>
                                <p>Loan maximum</p>
                            </div>
                            <div>
                                <Input
                                    className="z-comp-input z-package-input2"
                                    value={loan}
                                    name="loan"
                                    onChange={handleOnLoanChange}
                                />
                            </div>
                        </div>
                        <div className="z-mb-1 z-width-100">
                            <Slider
                                value={Number(loan)}
                                onChange={(value) => {
                                    setLoan(value);
                                }}
                                className="z-slider"
                                max={10000000}
                            />
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div z-mb-1">
                            <div>
                                <p>Interest rate (%)</p>
                            </div>
                            <div>
                                <Input
                                    className="z-comp-input z-package-input2"
                                    value={rate}
                                    name="rate"
                                    onChange={(e) => {
                                        setRate(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="z-mb-4 z-width-100">
                            <Slider
                                value={Number(rate)}
                                onChange={(value) => {
                                    setRate(value);
                                }}
                                className="z-slider"
                                max={100}
                            />
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div ">
                            {/* <div className="z-admin-left z-flex-space z-com-div z-mb-6"> */}
                            <div className="z-mb-10">
                                <p>Payment tenure</p>
                            </div>
                            <div>
                                <Select
                                    value={tenure.toString()}
                                    style={{
                                        width: 120,
                                    }}
                                    onChange={(value) => {
                                        setTenure(value);
                                    }}
                                    className="z-package-input2"
                                >
                                    <Option value="1">1 month</Option>
                                    <Option value="2">2 months</Option>
                                    <Option value="3">3 months</Option>
                                    <Option value="4">4 months</Option>
                                    <Option value="5">5 months</Option>
                                    <Option value="6">6 months</Option>
                                    <Option value="9">9 months</Option>
                                    <Option value="12">12 months</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        </Modal>
    );
}
