import React, { useState } from "react";
import { Button, Popconfirm, message } from "antd";
import { createBlacklistByUserId } from "../../../APIsHandler/ApiController";

export default function BlacklistCustomerButton({
    customerId,
    isDisabled,
    reload,
}) {
    const [isLoading, setIsLoading] = useState(false);

    function handleBlacklist() {
        setIsLoading(true);

        createBlacklistByUserId(customerId)
            .then(() => {
                setIsLoading(false);
                reload();
            })
            .catch((error) => {
                setIsLoading(false);
                message.error(
                    `Unable to blacklist customer.  ${
                        error.data !== undefined ? error.data.message : ""
                    }. Please try again.`,
                    3
                );
            });
    }

    return (
        <Popconfirm
            title="Do you want to blacklist this customer?"
            onConfirm={handleBlacklist}
            okText="Yes"
            cancelText="No"
            disabled={isDisabled}
        >
            <Button
                loading={isLoading}
                className="z-table-btn"
                disabled={isDisabled}
                type="danger"
            >
                {" "}
                Blacklist
            </Button>
        </Popconfirm>
    );
}
