import React from "react";
import {Row, Radio, Icon, Skeleton, Input, Button} from "antd";
import card from "../../../assets/images/undraw_Savings_dwkw.svg";
const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
};
    export default ({onRadioChange, accountOption, transferring, accounts, acctLoading, amount, onInputChange, showAddBankModal}) => (
        <Row>
            <Skeleton loading={acctLoading} active>
            <div>
            <div className="z-w-modal">
                <div className="z-icon-modal z-wallet-modal">
                    <img src={card} alt="card" />
                </div>
                {transferring ?
                    <div className="z-wallet-radio z-height-100px">
                        <p className="z-apply-label-small z-no-mb z-modal-parag-small z-mll">
                            Transferring fund into your account <Icon type="loading"/>
                        </p>
                    </div>
                    :
                    <div>
                        <div className="z-profile-width z-mb-8">
                            <p className="z-w-label">Amount To Withdraw</p>
                            <Input size="large" placeholder="e.g. 10000" value={amount} name="amount"
                                   onChange={e => {onInputChange(e)}}
                                   className="z-footer-input z-apply-input z-apply-input-small z-profile-label-input z-wallet-amount"/>
                        </div>
                        <div>
                            <p className="z-w-label">Which account will you like to use?</p>

                            <Radio.Group onChange={e => onRadioChange(e)} value={accountOption} className="z-radio-scroll">
                            { accounts.length  > 0 ? accounts.map((account, i) => (
                                                        <Radio style={radioStyle} value={i} key={i} className="z-w-radio">
                                                            {account.institution}: {account.account_number}
                                                        </Radio>
                                                    )) :
                                                        <Button className="z-landing-btn z-section-btn z-login-btn z-wallet-btn mb-3" shape="round" size="large"
                                                                onClick={e => {showAddBankModal()}} >
                                                            Add New Bank
                                                        </Button>
                            }
                        </Radio.Group>
                        </div>
                    </div>}
            </div>
        </div>
        </Skeleton>

    </Row>
)