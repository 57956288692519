import React, { useState, useEffect } from "react";
import { Button, Col, Icon, Row, Skeleton, message } from "antd";
import {
    getComanyLevelByCompanyId,
    getCompany,
} from "../../../APIsHandler/ApiController";
import NavigationTab from "../../NavigationTab";
import CompanyLevelTable from "./CompanyLevelTable";
import CreateCompanyLevelModal from "./CreateCompanyLevelModal";

export default function CompanyLevel({ match }) {
    const companyId = match.params.companyId; // company Id from url

    const [fetchingCompanyLevels, setFetchingCompanyLevels] = useState(true);
    const [fetchingCompany, setFetchingCompany] = useState(true);
    const [companyLevels, setCompanyLevels] = useState([]);
    const [companyName, setCompanyName] = useState(null);
    const [currentNavIndex, setCurrentNavIndex] = useState(0);
    const [nextNavIndex, setNextNavIndex] = useState(10);
    const [navDiff, setNavDiff] = useState(10);
    const [noOfPages, setNoOfPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    // modal
    const [createCompanyLevelModal, setCreateCompanyLevelModal] =
        useState(false);

    useEffect(() => {
        loadCompanyLevels();
        loadCompany();
    }, []);

    function loadCompanyLevels() {
        setFetchingCompanyLevels(true);

        getComanyLevelByCompanyId(companyId)
            .then((response) => {
                const data = response.data.data;

                setNoOfPages(Math.ceil(data.length / navDiff));
                setCompanyLevels(data);
                setFetchingCompanyLevels(false);
            })
            .catch((error) => {
                setFetchingCompanyLevels(false);

                message.error(
                    `Unable to fetch company levels. ${
                        error.data !== undefined ? error.data.message : ""
                    }. Please try again.`,
                    3
                );
            });
    }

    function loadCompany() {
        getCompany(companyId)
            .then((response) => {
                setFetchingCompany(false);
                setCompanyName(response.data.data.name);
            })
            .catch((error) => {
                setFetchingCompany(false);

                message.error(
                    `Unable to fetch company. ${
                        error.data !== undefined ? error.data.message : ""
                    }. Please try again.`,
                    3
                );
            });
    }

    function navigateRight() {
        if (currentPage !== noOfPages) {
            setNextNavIndex(nextNavIndex + navDiff);
            setCurrentNavIndex(currentNavIndex + navDiff);
            setCurrentPage((nextNavIndex + navDiff) / navDiff);
        }
    }

    function navigateLeft() {
        if (currentPage !== 1) {
            setNextNavIndex(nextNavIndex - navDiff);
            setCurrentNavIndex(currentNavIndex - navDiff);
            setCurrentPage((nextNavIndex - navDiff) / navDiff);
        }
    }

    return (
        <div>
            <Skeleton loading={fetchingCompany} active>
                <div>
                    <h1 className="z-dashboard-label">{`All ${companyName} Level`}</h1>
                </div>
            </Skeleton>

            <Skeleton loading={fetchingCompanyLevels} active>
                <div>
                    <Row>
                        <Col span={24}>
                            <div
                                className="z-dashboard-panel z-mb-5 z-width-100 z-admin-panel2"
                                style={{ height: "100%" }}
                            >
                                <div className="z-flex-start z-mb-6">
                                    <Icon
                                        type="copy"
                                        className="z-label-icon"
                                    />
                                    <p className="z-label-text">
                                        {`All ${companyName} Level`}
                                    </p>
                                </div>
                                <div>
                                    <Button
                                        type="primary"
                                        icon="plus"
                                        className="z-tab-btn"
                                        onClick={() =>
                                            setCreateCompanyLevelModal(true)
                                        }
                                    >
                                        {`Add new ${companyName} Level`}
                                    </Button>
                                </div>
                                <div>
                                    <CompanyLevelTable
                                        currentNavIndex={currentNavIndex}
                                        nextNavIndex={nextNavIndex}
                                        companyLevels={companyLevels}
                                        currentPage={currentPage}
                                        navDiff={navDiff}
                                        reload={loadCompanyLevels}
                                    />
                                </div>

                                {companyLevels.length > 0 && (
                                    <div className="z-admin-gr-div">
                                        <NavigationTab
                                            noOfPages={noOfPages}
                                            currentPage={currentPage}
                                            navigateLeft={navigateLeft}
                                            navigateRight={navigateRight}
                                        />
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <CreateCompanyLevelModal
                        closeModal={() => setCreateCompanyLevelModal(false)}
                        isVisible={createCompanyLevelModal}
                        reload={loadCompanyLevels}
                        companyId={companyId}
                        companyName={companyName}
                    />
                </div>
            </Skeleton>
        </div>
    );
}
