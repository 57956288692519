import React, {useState} from "react";
import {Card, Skeleton, Button} from "antd";
import applyLoanIcon from "../../../assets/images/getting-help/applyLoanIcon.svg"
import guarantorIcon from "../../../assets/images/getting-help/guarantorIcon.svg";

const GettingStarted = (props) => {
    const [loadApplyLoan, setLoanApplyLoan] = useState(false);
    const [loadGuarantorHelp, setLoadGuarantorHelp] = useState(false);
    const {setPageContent} = props;

    return (
        <section className="z-section-padding2" style={{marginTop: "20px" }}>
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <p className="mt-4 mb-4 main-header">
                            Let's get you started with SFS Credits
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <p className="mb-4 main-subtitle"
                        >
                            Step by Step tutorials on how to apply for loans and guarantee loan requests</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6 align-column-right mb-3">
                        <Card className="text-center m-0 justify-content-center help-card"
                        >
                            <Skeleton loading={loadApplyLoan}>
                                <p><img src={applyLoanIcon} className="" alt="How to apply for loan"/></p>
                                <p className="help-card-text mt-3 mb-2">How to apply for Loans</p>
                                <Button className="getting_started_btn ant-btn-round ant-btn-lg mt-5"
                                        onClick={ () => setPageContent('applyLoan')}
                                >
                                    Continue
                                </Button>
                            </Skeleton>

                        </Card>
                    </div>
                    <div className="col-sm-12 col-md-6 align-column-left mb-3">
                        <Card className="text-center m-0 justify-content-center help-card">
                            <Skeleton loading={loadGuarantorHelp}>
                                <p><img src={guarantorIcon} className="" alt="What to do as a Guarantor"/></p>
                                <p className="help-card-text mt-3 mb-2">How to Guarantee a loan request</p>
                                <Button className="getting_started_btn ant-btn-round ant-btn-lg mt-5"
                                        onClick={() => setPageContent('guarantorGuide')}
                                >
                                    Continue
                                </Button>
                            </Skeleton>
                        </Card>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GettingStarted;