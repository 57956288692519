import React, { useState } from "react";
import { Button, Icon, Table } from "antd";
import Column from "antd/lib/table/Column";
import avatar from "../../../assets/images/Rectangle 158@2x.png";
import moment from "moment";
import NavigationTab from "../../NavigationTab";
import EditAdministratorModal from "./EditAdministratorModal";
import UpdateAdministratorStatusButton from "./UpdateAdministratorStatusButton";

export default function AdministratorsTable({
    navDiff,
    noOfPages,
    users,
    fetchUsers,
}) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [currentNavIndex, setCurrentNavIndex] = useState(0);
    const [nextNavIndex, setNextNavIndex] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [viewDetails, setViewDetails] = useState(false);

    const [selectedUser, setSelectedUser] = useState(null);

    // shows details modal
    function showModal(user) {
        setSelectedUser(user);
        setViewDetails(true);
    }

    // closes details modal
    function closeModal(e) {
        setViewDetails(false);
    }

    function onChange(date, dateString) {
        console.log(date, dateString);
    }

    function onSelectChange(selectedRowKeys) {
        console.log("selectedRowKeys changed: ", selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    }

    function navigateRight() {
        if (currentPage !== noOfPages) {
            setNextNavIndex(nextNavIndex + navDiff);
            setCurrentNavIndex(currentNavIndex + navDiff);
            setCurrentPage((nextNavIndex + navDiff) / navDiff);
        }
    }

    function navigateLeft() {
        if (currentPage !== 1) {
            setNextNavIndex(nextNavIndex - navDiff);
            setCurrentNavIndex(currentNavIndex - navDiff);
            setCurrentPage((nextNavIndex - navDiff) / navDiff);
        }
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <>
            <Table
                rowSelection={rowSelection}
                bordered={false}
                dataSource={users.slice(currentNavIndex, nextNavIndex)}
                className="z-admin-table z-todo"
                pagination={false}
                rowKey="id"
                rowClassName={(record, index) => {
                    if (index % 2 === 0) {
                        return "z-stripped";
                    }
                }}
                scroll={{ x: "220%" }}
            >
                <Column
                    title="Mark all"
                    dataIndex="sn"
                    key="sn"
                    render={(text, record, index) => {
                        return (
                            <div className="">
                                <p className="z-table-num z-no-mb">
                                    {Number(
                                        index + (currentPage - 1) * navDiff
                                    ) + 1}
                                </p>
                            </div>
                        );
                    }}
                />
                <Column
                    title="Name"
                    dataIndex="name"
                    key="name"
                    render={(text, record) => {
                        return (
                            <div className="z-flex-start">
                                <div className="z-more ">
                                    <Icon type="more" className="z-font-12" />
                                </div>
                                <div className="z-testimonial-avatar z-table-avatar z-ml-4">
                                    <img
                                        src={
                                            record.hasOwnProperty(
                                                "user_profile"
                                            ) && record.user_profile !== null
                                                ? record.user_profile.avatar
                                                : avatar
                                        }
                                        alt="logo"
                                    />
                                </div>
                                <div className="z-ml-7">
                                    <p className="z-table-name z-table-parag ">
                                        {record.firstname} {record.lastname}
                                    </p>
                                </div>
                            </div>
                        );
                    }}
                />
                <Column
                    title="Email"
                    dataIndex="email"
                    key="email"
                    render={(text, record) => {
                        return <p className="z-table-com">{record.email}</p>;
                    }}
                />
                <Column
                    title="Company"
                    dataIndex="company"
                    key="company"
                    render={(text, record) => {
                        return (
                            <p className="z-table-com">
                                {record.company !== null &&
                                record.company !== "undefined"
                                    ? record.company.name
                                    : "SFS Credit"}
                            </p>
                        );
                    }}
                />
                <Column
                    title="Created"
                    dataIndex="created"
                    key="created"
                    render={(text, record) => {
                        return (
                            <p className="z-table-com">
                                {moment(record.created_at).format("DD-MM-YYYY")}
                            </p>
                        );
                    }}
                />
                <Column
                    title="Level"
                    dataIndex="level"
                    key="level"
                    render={(text, record) => {
                        return (
                            <p className="z-table-com">
                                {record.hasOwnProperty("roles") &&
                                record.roles.length > 0
                                    ? record.roles[0].name
                                    : "admin"}
                            </p>
                        );
                    }}
                />
                <Column
                    title="Delete"
                    dataIndex="action"
                    key="Action"
                    render={(i, record, index) => {
                        return (
                            <>
                                <div className="">
                                    <Button
                                        className="z-tablebtn"
                                        onClick={(e) => showModal(record)}
                                    >
                                        {" "}
                                        view
                                    </Button>
                                    <UpdateAdministratorStatusButton
                                        userId={record.id}
                                        status={record.status}
                                        reload={fetchUsers}
                                    />
                                </div>
                            </>
                        );
                    }}
                />
            </Table>
            {users.length > 0 && (
                <div className="z-admin-gr-div">
                    <NavigationTab
                        noOfPages={noOfPages}
                        currentPage={currentPage}
                        navigateLeft={navigateLeft}
                        navigateRight={navigateRight}
                    />
                </div>
            )}
            <EditAdministratorModal
                isOpen={viewDetails}
                closeModal={closeModal}
                user={selectedUser}
                fetchUsers={fetchUsers}
            />
        </>
    );
}
