import React, {Fragment, useState} from "react";
import Stepper from "./Stepper";
import Header from "./Header";
import applyLoanBG from "../../../../assets/images/getting-help/applyLoan6.png";

const SixthStep = (props) => {
    const {setCurrentPageStep, setPageContent} = props;
    return (
        <Fragment>
            <div className="page">
                <Header setPageContent={setPageContent}/>
                <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="circleNum mb-4">6</div>
                        <div className="help-topic">Enter your card details and apply</div>
                        <p className="help-content">After filling the application form, enter your card details and apply after which
                            you wait for approval from your guarantor and SFS Admin approval
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-7">
                        <img src={applyLoanBG} alt="Background Image" className="img-fluid top-img"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Stepper setCurrentPageStep={setCurrentPageStep} active="6"/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SixthStep;