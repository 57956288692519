import React, { useEffect, useState } from "react";
import { Row, Radio, Tooltip, Icon, Checkbox } from "antd";
import card from "../../../assets/images/undraw_wallet_aym5.svg";
const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
};
export default function WalletOptionCard({
    onRadioChange,
    fundingOption,
    guaranteeLoanWithCard,
    changeGuaranteeLoanWithCard,
}) {
    const [showCardOption, setShowCardOption] = useState(true);

    useEffect(() => {
        setShowCardOption(fundingOption === "admin" ? true : false);
    }, [fundingOption]);

    return (
        <Row>
            <div>
                <div>
                    <div className="z-icon-modal">
                        <img src={card} alt="card" />
                    </div>
                    <p className="z-apply-label-small z-modal-parag">
                        Please select a funding option
                    </p>
                    <Radio.Group
                        onChange={(e) => onRadioChange(e)}
                        value={fundingOption}
                        className="z-wallet-radio"
                    >
                        <Tooltip
                            placement="topLeft"
                            title="Admin funds the loan request."
                        >
                            <Radio style={radioStyle} value="admin">
                                Admin Funding
                            </Radio>
                        </Tooltip>
                        <Tooltip
                            placement="topRight"
                            title="Empower yourself as a guarantor by funding the loan you guarantee. Enjoy a 50% share of the generated income when the loan is repaid. Your support, your earnings—a rewarding partnership!"
                        >
                            <Radio style={radioStyle} value="cash">
                                Cash down
                            </Radio>
                        </Tooltip>
                    </Radio.Group>
                    {showCardOption && (
                        <>
                            {" "}
                            <div className="z-apply-label-large">
                                <Checkbox
                                    className="z-no-mb z-modal-parag-small z-mll z-mt-5"
                                    onChange={changeGuaranteeLoanWithCard}
                                    checked={guaranteeLoanWithCard}
                                    name="guaranteeLoanWithCard"
                                >
                                    Guarantee the loan with your debit card
                                </Checkbox>
                                <p className="z-no-mb z-modal-parag-small z-mll">
                                    and earn a percentage upon successful repayment
                                </p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Row>
    );
}
