import React from "react";
import Column from "antd/lib/table/Column";
import moment from "moment";
import { Table } from "antd";
import DeleteBlacklistButton from "../dashboardLayout/adminDashboard/DeleteBlacklistButton";

export default function BlacklistRow({
    blacklists,
    currentNavIndex,
    nextNavIndex,
    currentPage,
    navDiff,
    reload,
}) {
    return (
        <Table
            bordered={false}
            dataSource={blacklists.slice(currentNavIndex, nextNavIndex)}
            style={{
                width: "100%",
            }}
            className="z-admin-table z-todo"
            pagination={false}
            rowKey="id"
            rowClassName={(record, index) => {
                if (index % 2 === 0) {
                    return "z-stripped";
                }
            }}
            scroll={{ x: "100%" }}
        >
            <Column
                title="S/N"
                dataIndex="sn"
                key="sn"
                render={(text, record, index) => {
                    return (
                        <div className="">
                            <p className="z-table-num z-no-mb">
                                {Number(index + (currentPage - 1) * navDiff) +
                                    1}
                            </p>
                        </div>
                    );
                }}
            />
            <Column
                title="Customer Email"
                dataIndex="email"
                key="email"
                render={(text, record) => {
                    return (
                        <div>
                            <p className="z-table-status z-table-parag2">
                                {record.email}
                            </p>
                        </div>
                    );
                }}
            />
            <Column
                title="Blacklist Date"
                dataIndex="blacklist_date"
                key="blacklist_date"
                render={(text, record) => {
                    return (
                        <p className="z-table-text2 z-table-parag2">
                            {moment(record.created_at).format("DD-MM-YYYY")}
                        </p>
                    );
                }}
            />
            <Column
                title="Actton"
                dataIndex="action"
                key="Action"
                render={(i, record, index) => (
                    <DeleteBlacklistButton id={record.id} reload={reload} />
                )}
            />
        </Table>
    );
}
