import React, {Fragment} from 'react';
import Header from "../GuarantorGuide/Header";
import Stepper from "./Stepper";
import guarantorGuideBG from "../../../../assets/images/getting-help/guarantorGuide4.png";

const FourthStep = (props) => {
    const {setCurrentPageStep, setPageContent} = props;

    return (
        <Fragment>
            <div className="page">
                <Header setPageContent={setPageContent}/>
                <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="circleNum mb-4">4</div>
                        <div className="help-topic">Select funding option & proceed to finish</div>
                        <p className="help-content">
                            Select the funding option, admin funding or cashdown and click proceed to finish
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-7">
                        <div>
                            <img src={guarantorGuideBG} alt="Background Image" className="img-fluid top-img"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Stepper setCurrentPageStep={setCurrentPageStep} active="4"/>
                    </div>
                </div>

            </div>
        </Fragment>
    );
};

export default FourthStep;