import React, { useEffect, useState } from "react";
import { Button, Modal, Radio, Row } from "antd";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";

export default function UserLoanPackages({
    showLoanModal,
    closeModal2,
    loanPackageId,
    onRadioChange,
    loanPackages,
    radioStyle,
    companyLevelId,
}) {
    const [filteredLoanPackages, setFilteredLoanPackages] = useState([]);

    function qualify(companyLevelId, companyLevels) {
        if (companyLevelId && companyLevels && companyLevels.length > 0) {
            const levels = companyLevels.filter(
                (companyLevel) => companyLevel.id == companyLevelId
            );

            if (levels.length > 0) {
                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        const tempLoanPackage = loanPackages.filter((loanPackage) => {
            if (qualify(companyLevelId, loanPackage.company_levels)) {
                return loanPackage;
            }
        });

        setFilteredLoanPackages(tempLoanPackage);
        console.log(tempLoanPackage);
    }, [loanPackages, companyLevelId]);

    return (
        <Modal
            className="z-custom z-custom2 z-custom3 z-app-modal"
            visible={showLoanModal}
            title={"w"}
            onCancel={(e) => {
                closeModal2(e);
            }}
            footer={[
                <Button
                    className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row z-btn-company z-border-active"
                    shape="round"
                    size="large"
                    onClick={(e) => {
                        closeModal2(e);
                    }}
                    key={1}
                >
                    Cancel
                </Button>,
                <Button
                    className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg z-btn-company2-active"
                    shape="round"
                    size="large"
                    onClick={(e) => {
                        closeModal2(e);
                    }}
                    key={2}
                >
                    Add
                </Button>,
            ]}
        >
            <div>
                <div className="z-flex-space z-modal-label-div">
                    <div>
                        <p className="z-loan-header">Loan Packages</p>
                    </div>
                </div>

                <Row className="z-flex-space">
                    <div className="z-flex-space-start z-width-100">
                        <Radio.Group
                            onChange={onRadioChange}
                            value={loanPackageId}
                            className="z-package-p"
                        >
                            {filteredLoanPackages.map((loanPackage, i) => {
                                return (
                                    <div
                                        className="z-flex-space-start z-mb-10"
                                        key={i}
                                    >
                                        <Radio
                                            style={radioStyle}
                                            value={loanPackage.id}
                                            // disabled={loanPackage.isDisabled}
                                        >
                                            {loanPackage.name}
                                        </Radio>
                                        <div className="z-flex-space-start z-width-68">
                                            <div className="z-flex-column-start">
                                                <p className="z-no-mb z-package-label">
                                                    Loan Maximum
                                                </p>
                                                <p className="z-no-mb z-package-label2">
                                                    {naira}{" "}
                                                    {formatCurrency(
                                                        loanPackage.amount
                                                    )}
                                                </p>
                                            </div>
                                            <div className="z-flex-column-start">
                                                <p className="z-no-mb z-package-label">
                                                    Interest Rate
                                                </p>
                                                <p className="z-no-mb z-package-label2">
                                                    {loanPackage.interest_rate}
                                                </p>
                                            </div>
                                            <div className="z-flex-column-start">
                                                <p className="z-no-mb z-package-label">
                                                    Payment Tenor
                                                </p>
                                                <p className="z-no-mb z-package-label2">
                                                    {loanPackage.tenure} months
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Radio.Group>
                    </div>
                </Row>
            </div>
        </Modal>
    );
}
