import React from 'react';
import Column from "antd/lib/table/Column";
import {formatCurrency, naira} from "./globalFunctions";
import moment from "moment";
import {Table} from "antd";
import Input from "antd/es/input";

export default ({rowSelection, transactions, currentNavIndex, nextNavIndex, currentPage, navDiff}) => (
    <Table bordered={false}
           dataSource={transactions.slice(currentNavIndex, nextNavIndex)}
           className="z-admin-table z-todo"
           pagination={false}
           rowKey="id"
           rowClassName={(record, index) => {
               if(index%2 === 0) {
                   return "z-stripped"
               }
           }}
           scroll={{ x: '220%',  }}
    >
        <Column
            title="S/N"
            dataIndex="sn"
            key="sn"
            render={(text, record, index) => {
                return <div  className="">
                    <p className="z-table-num z-no-mb">{Number(index + ((currentPage - 1) * navDiff)) + 1}</p>
                </div>
            }} />
        <Column
            title="User"
            dataIndex="user"
            key="user"
            render={(text, record) => {
                return <div><p className="z-table-status z-table-parag2">
                    {record.user ? record.user.full_name :  record.model ? record.model.user.full_name: ""}
                </p></div>

            }}/>
        <Column
            title="Description"
            dataIndex="description"
            key="description"
            render={(text, record) => {
                return <div className="z-flex-start">
                    <div className="z-ml-7">
                        <p className="z-table-name z-table-parag ">{record.description }</p></div>
                </div>

            }}
        />
        <Column
            title="Reference"
            dataIndex="reference"
            key="reference"
            render={(text, record) => {
                return <div><p className="z-table-status z-table-parag2">
                    {record.reference}
                </p></div>

            }}/>
        <Column
            title="Amount"
            dataIndex="amount"
            key="amount"
            render={(text, record) => {
                return <p className="z-table-text z-table-parag">
                    {naira}  {formatCurrency(Number(record.amount))}
                </p>
            }} />
        <Column
            title="Transaction Date"
            dataIndex="transaction_date"
            key="transaction_date"
            render={(text, record) => {
                return <p className="z-table-text2 z-table-parag2">
                    {moment(record.created_at).format("DD-MM-YYYY")}
                </p>
            }} />
        <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(text, record) => {
                return <p  className={record.status === "success" ? "z-table-status z-table-parag2 z-color-green2" :
                    record.status === "processing" ? "z-table-status z-table-parag2 z-color-yellow" :  "z-table-status z-table-parag2"}>
                    {record.status }
                </p>
            }} />
        <Column
            title="Type"
            dataIndex="type"
            key="type"
            render={(text, record) => {
                return <p  className={record.type === "credit" ? "z-table-status z-table-parag2 z-color-green2" : "z-table-status z-table-parag2 z-color-yellow"} >
                    {record.type }
                </p>
            }} />
    </Table>
)