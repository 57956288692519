import React from "react";
import { Icon } from "antd";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";
import moment from "moment";


export default function PaybackSummary({ allSummary }) {
    return allSummary.map((summary, i) => {
        return (
            <div
                className="z-flex-row z-loan-padding2 z-table-row z-mb-2"
                key={i}
            >
                <div className="z-width-100 z-flex-start">
                    <div className="z-new-dIcon z-loan-drIcon">
                        <Icon type="cloud" />
                    </div>
                    <div className="z-width-100">
                        <p className="z-no-mb z-package-label text-center">
                            Amount Due
                        </p>
                        <p className="z-table-row-p">
                            {naira} {formatCurrency(summary.amount_due)}
                        </p>
                    </div>
                </div>
                <div className="z-width-100">
                    <div className="">
                        <p className="z-no-mb z-package-label text-center">
                            Payback Date
                        </p>
                        <p className="z-table-row-p">
                            {moment(summary.payback_date).format("DD-MM-YYYY")}
                        </p>
                    </div>
                </div>
            </div>
        );
    });
}
