import React, {useState} from 'react';
import {Col, Form, Icon, Input, message, Radio, Select, Skeleton} from "antd";
import card from "../../../assets/images/undraw_Savings_dwkw.svg";
import {resolveAccountCn} from "../../../APIsHandler/ApiController";

const {Option} = Select;

const AddBankModal = (props) => {
    const {banks, setAccountNumber, handleBankName ,setAccountName, setPassword, accountName, nameLoading} = props;

    const { getFieldDecorator, validateFields } = props.form;


    return (
        <div className="row">
            <Skeleton loading={false}>
                <div className="z-w-modal">
                    <Form>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Form.Item label="Account Number">
                                    {getFieldDecorator('account_number', {
                                        rules: [{
                                            required: true, message: 'Please input the account number'
                                        }]
                                    })(
                                        <Input placeholder="" onChange={ e => setAccountNumber(e.target.value)}/>
                                    )}
                                </Form.Item>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Form.Item label="Bank Name">
                                    {getFieldDecorator('bank_name', {
                                        rules: [{
                                            required: true, message: 'Please input the account number'
                                        }]
                                    })(
                                        <Select
                                            className=""
                                            showSearch
                                            style={{ width: 200 }}
                                            placeholder="Select a bank"
                                            optionFilterProp="children"
                                            onChange={handleBankName}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {banks.map((bank, i) => {
                                                return(
                                                    <Option value={bank.code} key={i}>{bank.name}</Option>)
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Skeleton loading={nameLoading} active>
                                    <Form.Item label="Account Name">
                                        <Input value={accountName}
                                               onChange={ e => setAccountName(e.target.value)}
                                               disabled={accountName.length > 2}
                                        />
                                    </Form.Item>
                                </Skeleton>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Form.Item label="Password">
                                    {getFieldDecorator('password', {
                                        rules: [{ required: true, message: 'Please input the account password'
                                        }]
                                    })(
                                        <Input type="password" placeholder="" onChange={ e => setPassword(e.target.value)}/>
                                    )}
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </Skeleton>
        </div>
    );
};

export default Form.create()(AddBankModal);