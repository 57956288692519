import React, { useState } from "react";
import { Button, Icon, Input, Modal, Row, Radio, message } from "antd";
import { changeAdminRole } from "../../../APIsHandler/ApiController";
import { useEffect } from "react";

const RadioGroup = Radio.Group;

export default function EditAdministratorModal({
    isOpen,
    closeModal,
    user,
    fetchUsers,
}) {
    const [editClicked, setEditClicked] = useState(false);

    const [loading, setLoading] = useState(false);

    const [username, setUsername] = useState("");
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [value, setValue] = useState("admin");

    useEffect(() => {
        if (user) {
            console.log(user);

            setUsername(user.firstname);
            setFullName(user.lastname);
            setEmail(user.email);
            setValue(user.roles[0].name);
        }
    }, [user]);

    /*
     * update admin*/
    function updateAdmin() {
        const params = {
            user_id: user.id,
            role: value,
        };
        setLoading(true);
        changeAdminRole(params, onUpdateAdmin);
    }

    function onUpdateAdmin(status, data) {
        if (status) {
            setLoading(false);
            setEditClicked(false);
            message.success("Admin details successfully updated", 3);

            // Refresh users
            fetchUsers();
        } else {
            setLoading(false);
            message.error("Unable to update admin. " + data.data.message, 3);
        }
    }

    function onRadioChange(e) {
        setValue(e.target.value);
    }

    function handleCloseModal() {
        closeModal();
        setEditClicked(false);
    }

    // edit details
    function editDetails() {
        setEditClicked(true);
    }

    // cancel edit details
    function cancelEditDetails() {
        setEditClicked(false);
    }

    const radioStyle = {
        display: "block",
        height: "30px",
        lineHeight: "30px",
    };

    return (
        <Modal
            className="z-custom z-custom2 z-custom3 "
            visible={isOpen}
            title={"w"}
            onCancel={handleCloseModal}
            footer={[
                editClicked ? (
                    <Button
                        className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row z-btn-company z-border-active"
                        shape="round"
                        size="large"
                        onClick={cancelEditDetails}
                        key="cancel"
                    >
                        Cancel
                    </Button>
                ) : (
                    <Button
                        key="close"
                        className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row z-btn-company"
                        shape="round"
                        size="large"
                        onClick={closeModal}
                    >
                        Close
                    </Button>
                ),
                editClicked ? (
                    <Button
                        key="Update"
                        className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg z-btn-company2-active"
                        shape="round"
                        size="large"
                        onClick={updateAdmin}
                        loading={loading}
                    >
                        Update
                    </Button>
                ) : (
                    <Button
                        key="Update-1"
                        className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg z-btn-company2"
                        shape="round"
                        size="large"
                    >
                        Update
                    </Button>
                ),
            ]}
        >
            <div>
                <div className="z-flex-space z-admin-label-div">
                    <div>
                        <Button
                            type="primary"
                            className={
                                editClicked
                                    ? "z-btn-company-active z-font-13"
                                    : "z-tab-btn z-font-13"
                            }
                            onClick={editDetails}
                        >
                            Edit <Icon type="edit" />
                        </Button>
                    </div>
                    <div>
                        <p className="z-loan-header z-loan-header-small">
                            {username}
                        </p>
                    </div>
                </div>
                <Row>
                    <div className="z-flex-column-center z-com-p">
                        <div className="z-admin-left z-flex-space z-com-div z-mb-4">
                            <div>
                                <p>First Name</p>
                            </div>
                            <div>
                                <Input
                                    className="z-comp-input"
                                    value={username}
                                    name="username"
                                    disabled={!editClicked}
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div z-mb-4">
                            <div>
                                <p>Last name</p>
                            </div>
                            <div>
                                <Input
                                    className="z-comp-input"
                                    value={fullName}
                                    name="full_name"
                                    disabled={!editClicked}
                                    onChange={(e) =>
                                        setFullName(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div z-mb-10">
                            <div>
                                <p>Email</p>
                            </div>
                            <div>
                                <Input
                                    className="z-comp-input"
                                    value={email}
                                    name="email"
                                    disabled={!editClicked}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div ">
                            <div>
                                <p>{editClicked ? "Change role" : "role"}</p>
                            </div>
                            <div className="z-com-card z-height-115">
                                <RadioGroup
                                    onChange={onRadioChange}
                                    value={value}
                                    disabled={!editClicked}
                                >
                                    <Radio
                                        style={radioStyle}
                                        value="super-admin"
                                        className="z-radio"
                                    >
                                        Super Admin
                                    </Radio>
                                    <Radio
                                        style={radioStyle}
                                        value="admin"
                                        className="z-radio"
                                    >
                                        Admin
                                    </Radio>
                                    <Radio
                                        style={radioStyle}
                                        value="user"
                                        className="z-radio"
                                    >
                                        User
                                    </Radio>
                                </RadioGroup>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        </Modal>
    );
}
