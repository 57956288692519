import React, { useEffect, useState } from "react";
import { Col, Input, Row, Upload, Select, message, Skeleton } from "antd";
import {
    getAllCompanyLevels,
    getBankNames,
    resolveAccountCn,
} from "../../../APIsHandler/ApiController";
const { Option } = Select;

export default function GuarantorBankDetails({
    accountNumber,
    companyLevelId,
    onCompnayLevelChange,
    changeInput2,
    onBankNameChange,
    bankName,
    accountName,
    onAccountNameChange,
}) {
    const [companyLevels, setCompanyLevels] = useState([]);
    const [companyLevelLoading, setCompanyLevelLoading] = useState(true);
    const [banks, setBanks] = useState([]);
    const [banksLoading, setBanksLoading] = useState(true);
    const [nameLoading, setNameLoading] = useState(false);

    useEffect(() => {
        fetchCompanyLevels();
        fetchBanks();
    }, []);

    useEffect(() => {
        if (accountNumber.length > 9 && bankName.length > 0) resolveAccount();
    }, [bankName]);

    function fetchBanks() {
        getBankNames()
            .then((res) => {
                setBanks(res.data.data);
                setBanksLoading(false);
            })
            .catch((err) => {
                setBanksLoading(false);
                message.error(`Unable to retrieve banks.`);
            });
    }

    function fetchCompanyLevels() {
        getAllCompanyLevels()
            .then((resp) => {
                setCompanyLevels(resp.data.data);
                setCompanyLevelLoading(false);
            })
            .catch((error) => {
                setCompanyLevelLoading(false);
                message.error(`Unable to retrieve levels.`);
            });
    }

    /*
     * Resolve account name*/
    function resolveAccount() {
        setNameLoading(true);
        resolveAccountCn({ account: accountNumber, bank: bankName })
            .then((res) => {
                onAccountNameChange(res.data.data.account_name);
                setNameLoading(false);
            })
            .catch((err) => {
                setNameLoading(false);
                message.error(
                    "Unable to resolve account. Please check the account details.",
                    3
                );
            });
    }

    return (
        <Row>
            <div className="z-flex-column-center z-padding-15">
                <Row>
                    <Col span={24} className="z-mb-5">
                        <p className="z-apply-label-small z-request--small-p">
                            Select Level
                        </p>
                        <Skeleton loading={companyLevelLoading} active>
                            <div>
                                <Select
                                    className="z-apply-select"
                                    style={{ width: "100%" }}
                                    onChange={onCompnayLevelChange}
                                    // value={companyLevelId}
                                    defaultValue="Select a Level" 
                                >
                                    <Option disabled value={0}>
                                        Select a Level
                                    </Option>
                                    {companyLevels.map((companyLevel, i) => {
                                        return (
                                            <Option
                                                value={companyLevel.id}
                                                key={i}
                                            >
                                                {companyLevel.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        </Skeleton>
                    </Col>
                    <Col span={24} className="z-mb-5">
                        <p className="z-apply-label-small z-request--small-p">
                            Account Number
                        </p>
                        <div>
                            <Input
                                size="large"
                                placeholder="Account Number"
                                name="accountNumber"
                                value={accountNumber}
                                onChange={changeInput2}
                                className="z-footer-input z-apply-input z-apply-input-small"
                            />
                        </div>
                    </Col>
                    <Col span={24} className="z-mb-5">
                        <p className="z-apply-label-small z-request--small-p">
                            Bank Name
                        </p>
                        <div>
                            <Select
                                className="z-apply-select"
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Select a bank"
                                optionFilterProp="children"
                                onChange={onBankNameChange}
                                loading={banksLoading}
                                // onSearch={() => {}}
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {banks.map((bank, i) => {
                                    return (
                                        <Option value={bank.code} key={i}>
                                            {bank.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </Col>
                    <Col span={24} className="z-mb-5">
                        <p className="z-apply-label-small z-request--small-p">
                            Account Name
                        </p>
                        <Skeleton loading={nameLoading} active>
                            <div>
                                <Input
                                    size="large"
                                    placeholder="Account Name"
                                    name="accountName"
                                    value={accountName}
                                    onChange={changeInput2}
                                    className="z-footer-input z-apply-input z-apply-input-small"
                                    disabled={accountName.length > 2}
                                />
                            </div>
                        </Skeleton>
                    </Col>

                    {/* <Col span={24} className="z-mb-5">
                        <p className="z-apply-label-small z-request--small-p">
                            Your Current salary
                        </p>
                        <div className="z-apply-card z-request-card">
                            <div className="z-flex-space z-apply-tag">
                                <p>N</p>
                                <p>.00</p>
                            </div>
                            <div>
                                <Input
                                    size="large"
                                    placeholder="1000"
                                    value={currentAmount}
                                    name="currentAmount"
                                    className="z-footer-input z-apply-input"
                                    onChange={(e) => {
                                        changeInput2(e);
                                    }}
                                />
                            </div>
                        </div>
                    </Col> */}
                    {/* <Col span={24} >
                    <p className="z-apply-label-small z-request--small-p">
                        Upload Bank Statement
                    </p>
                    <div className="z-flex-space">
                        {!uploadClicked && <Input size="large" placeholder="..."
                                                  className="z-footer-input z-apply-input z-apply-input-small z-width-65"/>}
                        <Upload {...uploadProps} className="z-upload-flex">
                            <p className="z-profile-small">
                                Upload file
                            </p>
                        </Upload>
                    </div>
                </Col> */}
                </Row>
            </div>
        </Row>
    );
}
