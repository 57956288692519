import React, { useState } from "react";
import { addNewAdmin } from "../../../APIsHandler/ApiController";
import { Button, Input, Modal, Row, Radio, message } from "antd";

const RadioGroup = Radio.Group;

export default function CreateAdministratorModal({
    closeModal,
    isOpen,
    fetchUsers,
}) {
    const [username, setUsername] = useState("");
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [value, setValue] = useState("admin");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    function onRadioChange(e) {
        setValue(e.target.value);
    }

    /*
     * add admin*/
    function handleAddAdmin() {
        const params = {
            firstname: username,
            lastname: fullName,
            role: value,
            email,
            password,
        };
        setLoading(true);
        addNewAdmin(params, onAddAdmin);
    }

    function onAddAdmin(status, data) {
        if (status) {
            setLoading(false);
            setFullName("");
            setUsername("");
            setPassword("");
            setEmail("");
            closeModal();

            message.success("Admin successfully created", 3);
            // Refresh page
            fetchUsers();
        } else {
            setLoading(false);
            message.error("Unable to create admin. " + data.data.message, 3);
        }
    }

    const radioStyle = {
        display: "block",
        height: "30px",
        lineHeight: "30px",
    };

    return (
        <Modal
            className="z-custom z-custom2 z-custom3 "
            visible={isOpen}
            title={"w"}
            onCancel={closeModal}
            footer={[
                <Button
                    className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row z-btn-company z-border-active"
                    shape="round"
                    size="large"
                    onClick={closeModal}
                >
                    Cancel
                </Button>,
                <Button
                    className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg z-btn-company2-active"
                    shape="round"
                    size="large"
                    onClick={handleAddAdmin}
                    loading={loading}
                >
                    Add
                </Button>,
            ]}
        >
            <div>
                <div className="z-flex-space z-admin-label-div">
                    <div>
                        <p className="z-loan-header">Add Admin</p>
                    </div>
                    <div>
                        <p className="z-loan-header z-loan-header-small">
                            New Admin
                        </p>
                    </div>
                </div>
                <Row>
                    <div className="z-flex-column-center z-com-p">
                        <div className="z-admin-left z-flex-space z-com-div z-mb-4">
                            <div>
                                <p>First Name</p>
                            </div>
                            <div>
                                <Input
                                    className="z-comp-input"
                                    value={username}
                                    name="username"
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div z-mb-4">
                            <div>
                                <p>Last name</p>
                            </div>
                            <div>
                                <Input
                                    className="z-comp-input"
                                    value={fullName}
                                    name="full_name"
                                    onChange={(e) =>
                                        setFullName(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div z-mb-4">
                            <div>
                                <p>Email</p>
                            </div>
                            <div>
                                <Input
                                    className="z-comp-input"
                                    value={email}
                                    name="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div z-mb-10">
                            <div>
                                <p>Password</p>
                            </div>
                            <div>
                                <Input.Password
                                    className="z-comp-input"
                                    value={password}
                                    name="password"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="z-admin-left z-flex-space z-com-div ">
                            <div>
                                <p>Set role</p>
                            </div>
                            <div className="z-com-card">
                                <RadioGroup
                                    onChange={onRadioChange}
                                    value={value}
                                >
                                    <Radio
                                        style={radioStyle}
                                        value="super-admin"
                                        className="z-radio"
                                    >
                                        Super Admin
                                    </Radio>
                                    <Radio
                                        style={radioStyle}
                                        value="admin"
                                        className="z-radio"
                                    >
                                        Admin
                                    </Radio>
                                </RadioGroup>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        </Modal>
    );
}
