import React, { Component } from "react";
import { Button, Icon, Modal, message, Skeleton, Popconfirm } from "antd";
import empty from "../../../assets/images/undraw_no_data_qbuo (3).svg";
import {
    acceptGuarantorCashDownRequest,
    acceptGuarantorRequest,
    addBankDetails,
    declineGuarantorRequest,
    fundCashDownRequest,
    fundWallet,
    getGuarantorRequests,
    getUserCards,
    getWalletBalance,
    initializeCard,
    initializeWalletTransaction,
    uploadFile,
} from "../../../APIsHandler/ApiController";
import PaystackGateway from "../../paymentDir/PaystackGateway";
import NavigationTab from "../../NavigationTab";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";
import GuarantorCard from "./GuarantorCard";
import GuarantorBankDetails from "./GuarantorBankDetails";
import GuarantorModalDetails from "./GuarantorModalDetails";
import WalletOptionCard from "./WalletOptionCard";
import FundingWalletPanel from "./FundingWalletPanel";
import overviewIcon from "../../../assets/images/dashboard/overview.svg";
import sideBanner from "../../../assets/images/dashboard/loanBanner.png";

export default class GuarantorRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewDetails: false,
            loading: true,
            requests: [],
            allRequests: [],
            currentIndex: 0,
            showAddDetailStep: false,
            uploadClicked: false,
            fileList: "",
            addRequest: false,
            declineRequest: false,
            tabNum: 0,
            showSelectaCardStep: false,
            addcard: false,
            cardDetails: "",
            cards: [],
            showSelectFundingOptionStep: false,
            acceptLoading: false,
            fileId: "",
            currentNavIndex: 0,
            nextNavIndex: 10,
            navDiff: 10,
            noOfPages: 0,
            currentPage: 1,
            initReference: "",
            initAmount: 0,
            initCard: false,
            currentCard: 0,
            fundingOption: "admin",
            walletFunded: false,
            fundingLow: false,
            initializeWallet: false,
            companyLevelId: null,
            accountNumber: "",
            bankName: "",
            accountName: "",
            guaranteeLoanWithCard: false,
            submittedDetails: false,
        };
    }

    componentDidMount() {
        getGuarantorRequests()
            .then((res) => {
                let { data } = res.data;
                let num = Math.ceil(data.length / this.state.navDiff);
                this.setState({
                    requests: data,
                    allRequests: data,
                    noOfPages: num,
                    loading: false,
                });
            })
            .catch((err) => {
                this.setState({ loading: false });
                message.error("Unable to retrieve requests at the moment.", 3);
            });
        this.getCards();
    }

    onBankNameChange = (value) => {
        this.setState({
            bankName: value,
        });
    };

    onAccountNameChange = (value) => {
        this.setState({
            accountName: value,
        });
    };

    onCompnayLevelChange = (value) => {
        this.setState({
            companyLevelId: value,
        });
    };

    //closes details modal
    closeModal = (e) => {
        this.setState({
            addRequest: false,
            addcard: false,
            showSelectaCardStep: false,
            showSelectFundingOptionStep: false,
            showAddDetailStep: false,
            currentIndex: 0,
            viewDetails: false,
            fileId: "",
            fundingLow: false,
            walletFunded: false,
            initializeWallet: false,
        });
    };

    //shows details modal
    showModal = (e, index) => {
        this.setState({ currentIndex: index, viewDetails: true });
    };

    //handle the input change
    changeInput2 = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    changeGuaranteeLoanWithCard = (e) => {
        this.setState({
            guaranteeLoanWithCard: e.target.checked,
        });
    };

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    onChange = (info) => {
        /*this_.setState({uploadClicked: true})*/
        if (info.file.status !== "uploading") {
            console.log("file", info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
            // Get this url from response in real world.
            this.setState({ fileId: info.file.response.data });
            this.getBase64(info.file.originFileObj, (imageUrl) =>
                this.setState({
                    imageUrl,
                    loading: false,
                })
            );
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
            this.setState({ fileList: [] });
        }
    };

    /**
     * handle the manual upload of the id card check
     * */
    beforeUpload = (file) => {
        this.setState({ uploadClicked: true });

        const isPNG = file.type === "image/png";
        const isJPG = file.type === "image/jpeg";
        const isPDF = file.type === "application/pdf";
        if (!isJPG && !isPNG && !isPDF) {
            message.error("You can only upload JPG, JPEG, or PDF file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 0.25;
        if (!isLt2M) {
            message.error("Image must smaller than 250kb!");
        }
        this.setState((state) => ({
            fileList: [...state.fileList, file],
        }));
        return (isJPG || isPNG) && isLt2M;
    };

    isAddBankDetailsButtonDisabled() {
        const { companyLevelId, accountNumber, bankName, accountName } =
            this.state;

        if (
            !companyLevelId ||
            !accountNumber ||
            accountNumber.length < 9 ||
            !bankName ||
            !accountName ||
            accountName.length < 1
        ) {
            return true;
        }

        return false;
    }

    canApproveLoan = () => {
        const {
            walletFunded,
            guaranteeLoanWithCard,
            cardDetails,
            fundingOption,
            submittedDetails,
        } = this.state;

        // if user has not submitted bank information
        if(!submittedDetails) return false

        if (
            !guaranteeLoanWithCard &&
            fundingOption === "admin"
        ) {
            return true;
        }

        if (walletFunded && fundingOption === "cash") {
            return true;
        }

        if (
            walletFunded &&
            guaranteeLoanWithCard &&
            cardDetails.toString().length > 0
        ) {
            return true;
        }

        return false;
    };

    addBankDetails = () => {
        this.setState({ addRequest: true });
        const {
            requests,
            currentIndex,
            fileId,
            companyLevelId,
            accountNumber,
            bankName,
            accountName,
        } = this.state;

        const formData = new FormData();

        formData.append("company_level_id", companyLevelId);
        formData.append("account_number", accountNumber);
        formData.append("bank_name", bankName);
        formData.append("account_name", accountName);
        formData.append(
            "loan_request_id",
            requests[currentIndex].loan_request_id
        );

        addBankDetails(formData, this.onAddDatails);
    };

    onAddDatails = (status, data) => {
        if (status) {
            this.setState({
                showAddDetailStep: false,
                submittedDetails: true,
                showSelectFundingOptionStep: true,
            });

            /* this.acceptLoan(data.loan_request_id)*/
        } else {
            message.error("Unable to accept request!" + data.data.message);
            this.setState({ addRequest: false });
        }
    };

    showBankDetails = () => {
        this.setState({ showAddDetailStep: true });
    };

    /*
     * accepting loan*/

    acceptLoan = (id) => {
        const { fundingOption, guaranteeLoanWithCard } = this.state;

        this.setState({ acceptLoading: true });

        if (fundingOption === "cash") {
            // cash down funding option
            acceptGuarantorCashDownRequest(
                {
                    loan_request_id: id,
                    guarantee_with_card: guaranteeLoanWithCard,
                    cash_down: "yes",
                },
                this.onAccept
            );
        } else {
            acceptGuarantorRequest(
                {
                    loan_request_id: id,
                    guarantee_with_card: guaranteeLoanWithCard,
                },
                this.onAccept
            );
        }
    };

    onAccept = (status, data) => {
        if (status) {
            this.setState({
                addRequest: false,
                showSelectFundingOptionStep: false,
                cardDetails: "",
                acceptLoading: false,
                viewDetails: false,
            });
            message.success("The request has been accepted!. ", 5);
            window.location.reload();
        } else {
            this.setState({
                addRequest: false,
                acceptLoading: false,
                showAddDetailStep: false,
            });
            message.error("Unable to accept request!" + data.data.message);
        }
    };

    /*
     * decline loan*/

    declineLoan = () => {
        const { requests, currentIndex } = this.state;
        this.setState({ declineRequest: true });
        declineGuarantorRequest(
            { loan_request_id: requests[currentIndex].loan_request_id },
            this.onDecline
        );
    };

    onDecline = (status, data) => {
        if (status) {
            this.setState({
                declineRequest: false,
                showAddDetailStep: false,
                viewDetails: false,
            });
            message.success("The request has been declined!");
            window.location.reload();
        } else {
            this.setState({ declineRequest: false, showAddDetailStep: false });
            message.error("Unable to decline request!" + data.data.message);
        }
    };

    cancel = (e) => {};
    /*
     * sort table data*/
    sortData = (tab) => {
        const { allRequests, navDiff } = this.state;
        if (tab === 1) {
            const sorted = allRequests.filter((request) => {
                return request.guarantor_approved === "approved";
            });
            let num = Math.ceil(sorted.length / navDiff);
            this.setState({ requests: sorted, noOfPages: num });
        } else if (tab === 2) {
            const sorted = allRequests.filter((request) => {
                return request.guarantor_approved === "pending";
            });
            let num = Math.ceil(sorted.length / navDiff);
            this.setState({ requests: sorted, noOfPages: num });
        } else if (tab === 3) {
            const sorted = allRequests.filter((request) => {
                return request.guarantor_approved === "declined";
            });
            let num = Math.ceil(sorted.length / navDiff);
            this.setState({ requests: sorted, noOfPages: num });
        } else if (tab === 4) {
            const sorted = allRequests.filter((request) => {
                return request.guarantor_approved === "cancelled";
            });
            let num = Math.ceil(sorted.length / navDiff);
            this.setState({ requests: sorted, noOfPages: num });
        } else {
            let num = Math.ceil(allRequests.length / navDiff);
            this.setState({ requests: allRequests, noOfPages: num });
        }
    };

    /*
     * Toogle tabs*/
    setTab = (e, i) => {
        this.setState({ tabNum: i });
        this.sortData(i);
    };

    /*
     * Get user debit cards on the platform*/
    getCards = () => {
        getUserCards(this.onGetCards);
    };

    /*
     * get user loans callback*/

    onGetCards = (status, data) => {
        if (status) {
            if (data.length > 0) {
                this.setState({
                    cardDetails: data[this.state.currentCard].id,
                    cards: data,
                });
            } else {
                this.setState({ cards: data });
            }
        } else {
            message.error("Unable to retrieve debit cards at the moment.", 3);
        }
    };

    /*
     * Show payment gateway modal*/
    launchGateway = () => {
        this.setState({ initCard: true });
        initializeCard(this.onInitialize);
    };

    fileUpload = (info) => {
        let formData = new FormData();
        formData.append("uploadFile", info.file);
        uploadFile(formData)
            .then((res) => {
                info.onSuccess(res.data, info.file);
            })
            .catch((err) => {
                info.onError("File upload issue", info.file);
            });
    };

    onInitialize = (status, data) => {
        if (status) {
            this.setState({
                initAmount: data.amount,
                initReference: data.reference,
                initCard: false,
                addCard: true,
                launchPaystack: true,
            });
        } else {
            message.error("Unable to generate payment reference id");
        }
    };

    addOldCard = () => {
        const { cards } = this.state;
        if (cards.length < 1) {
            this.launchGateway();
        }
        if (this.state.cardDetails.toString().length > 0) {
            this.setState({
                initCard: false,
                addCard: false,
                cardDetails: this.state.cards[this.state.currentCard].id,
                // showAddDetailStep: false,
                // showSelectaCardStep: false,
                // showSelectFundingOptionStep: true,
            });
            message.success("Card has been added", 3);

            if (this.state.fundingOption === "cash") {
                this.handleCashdown();
            } else {
                this.setState({
                    walletFunded: true,
                });
            }
        }
    };

    /*
     * On paystack authorization success*/
    cardDetailsSuccess = (response) => {
        const { cards } = this.state;
        cards.push(response);
        this.setState({
            cards,
            cardDetails: response.id,
            addCard: false,
            launchPaystack: false,
            // showAddDetailStep: false,
            // showSelectaCardStep: false,
            // showSelectFundingOptionStep: true,
        });
    };

    handleCashdown = () => {
        getWalletBalance()
            .then((res) => {
                const { data } = res.data;
                if (
                    data.balance <
                    this.state.requests[this.state.currentIndex].loan_request
                        .loan_package.amount
                ) {
                    this.setState({
                        showSelectaCardStep: false,
                        showSelectFundingOptionStep: false,
                        fundingLow: true,
                    });
                } else {
                    message.success(
                        "Authorized! The loan will be paid from your wallet",
                        5
                    );
                    this.setState({
                        showSelectaCardStep: false,
                        showSelectFundingOptionStep: false,
                        walletFunded: true,
                    });
                }
            })
            .catch((err) => {
                message.error(
                    "Unable to get wallet balance. Please try again later"
                );
            });
    };

    /*
     * On paystack authorization error*/
    cardDetailsError = () => {
        this.setState({
            showSelectaCardStep: true,
            addCard: false,
            launchPaystack: false,
        });
    };

    navigateRight = () => {
        const {
            navDiff,
            nextNavIndex,
            currentNavIndex,
            currentPage,
            noOfPages,
        } = this.state;

        if (currentPage !== noOfPages) {
            this.setState({
                nextNavIndex: nextNavIndex + navDiff,
                currentNavIndex: currentNavIndex + navDiff,
                currentPage: (nextNavIndex + navDiff) / navDiff,
            });
        }
    };

    navigateLeft = () => {
        const { navDiff, nextNavIndex, currentNavIndex, currentPage } =
            this.state;

        if (currentPage !== 1) {
            this.setState({
                nextNavIndex: nextNavIndex - navDiff,
                currentNavIndex: currentNavIndex - navDiff,
                currentPage: (nextNavIndex - navDiff) / navDiff,
            });
        }
    };

    //scrolls cards right
    navLeft = () => {
        const { currentCard } = this.state;
        if (currentCard > 0) {
            this.setState({ currentCard: currentCard - 1 });
        }
    };

    //scrolls input values down
    navRight = () => {
        const { currentCard, cards } = this.state;
        if (currentCard < cards.length - 1) {
            this.setState({ currentCard: currentCard + 1 });
        }
    };

    // handle wallet options
    onRadioChange = (e) => {
        const value = e.target.value;

        if (value === "cash") {
            this.setState({
                fundingOption: value,
                guaranteeLoanWithCard: true,
            });
        } else {
            this.setState({
                fundingOption: value,
            });
        }
    };

    //admin funding loan request
    adminFunding = () => {
        message.success("funding option added", 3);
        this.setState({
            showSelectFundingOptionStep: false,
            showSelectaCardStep: true,
        });
    };

    //initialize funding process
    initializeWallet = () => {
        this.setState({ initializeWallet: true });

        const loanRequest =
            this.state.requests[this.state.currentIndex].loan_request;

        const amount = loanRequest.amount || loanRequest.loan_package.amount;

        initializeWalletTransaction({ amount }, this.onInitializeWallet);
    };

    onInitializeWallet = (status, data) => {
        if (status) {
            const cards = this.state.cards;
            const fundindCard = cards.filter((card) => {
                return card.id === this.state.cardDetails;
            });
            //fund user wallet with card debit
            fundCashDownRequest(
                {
                    amount: data.amount,
                    reference: data.reference,
                    card_id: fundindCard[0].id,
                    card_last4: fundindCard[0].last4,
                    card_type: fundindCard[0].card_type,
                },
                this.paystackFundWallet
            );
        } else {
            message.error(
                "Unable to initialize transaction. Please try again",
                5
            );
            this.setState({ initializeWallet: false });
        }
    };

    //fund user wallet with card debit callback
    paystackFundWallet = (status, response) => {
        if (status) {
            //verify wallet funding
            fundWallet({ reference: response.reference }, this.onFundWallet);
        } else {
            message.error(
                "Unable to complete transaction. " + response.data.message
            );
            this.setState({ initializeWallet: false });
        }
    };

    onFundWallet = (status, data) => {
        if (status) {
            message.success(
                "Your wallet was successfully funded and has been authorized for the loan payment.",
                5
            );
            this.setState({ fundingLow: false, walletFunded: true });
        } else {
            message.error("Unable to fund wallet. Please try again", 5);
            this.setState({ initializeWallet: false });
        }
    };

    changeFundingOption = () => {
        this.setState({
            fundingLow: false,
            showSelectFundingOptionStep: true,
            fundingOption: "admin",
        });
    };

    render() {
        const {
            viewDetails,
            loading,
            requests,
            currentIndex,
            showAddDetailStep,
            uploadClicked,
            fileId,
            declineRequest,
            addRequest,
            tabNum,
            showSelectaCardStep,
            cardDetails,
            addcard,
            launchPaystack,
            showSelectFundingOptionStep,
            acceptLoading,
            currentNavIndex,
            nextNavIndex,
            navDiff,
            currentPage,
            initReference,
            initAmount,
            cards,
            currentCard,
            fundingOption,
            walletFunded,
            fundingLow,
            initializeWallet,
            initCard,
            companyLevelId,
            accountNumber,
            bankName,
            accountName,
            guaranteeLoanWithCard,
        } = this.state;

        return (
            <div>
                <div>
                    <h1 className="z-dashboard-label">Requests</h1>
                </div>
                <Skeleton loading={loading} active>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="z-flex-start z-guarantor-padding mb-4">
                                <img
                                    src={overviewIcon}
                                    className="img-fluid mr-3"
                                    alt="Overview Icon"
                                />
                                <span className="z-label-text">
                                    Guarantor Requests
                                </span>
                            </div>
                            <div className="z-flex-space z-guarantor-padding2 mb-2">
                                <div
                                    className={
                                        tabNum === 0
                                            ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                            : "z-cursor-poiner z-width-100 z-flex-row"
                                    }
                                    onClick={(e) => {
                                        this.setTab(e, 0);
                                    }}
                                >
                                    <div className="z-guarantor-tab ">
                                        <p className="z-ml-8 z-notification-active">
                                            All
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        tabNum === 1
                                            ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                            : "z-cursor-poiner z-width-100 z-flex-row"
                                    }
                                    onClick={(e) => {
                                        this.setTab(e, 1);
                                    }}
                                >
                                    <div className="z-guarantor-tab">
                                        <p className="z-ml-8 z-notification-active">
                                            Approved
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        tabNum === 2
                                            ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                            : "z-cursor-poiner z-width-100 z-flex-row"
                                    }
                                    onClick={(e) => {
                                        this.setTab(e, 2);
                                    }}
                                >
                                    <div className="z-guarantor-tab">
                                        <p className="z-ml-8 z-notification-active">
                                            Pending
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        tabNum === 3
                                            ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                            : "z-cursor-poiner z-width-100 z-flex-row"
                                    }
                                    onClick={(e) => {
                                        this.setTab(e, 3);
                                    }}
                                >
                                    <div className="z-guarantor-tab">
                                        <p className="z-ml-8 z-notification-active">
                                            Declined
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        tabNum === 4
                                            ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                            : "z-cursor-poiner z-width-100 z-flex-row"
                                    }
                                    onClick={(e) => {
                                        this.setTab(e, 4);
                                    }}
                                >
                                    <div className="z-guarantor-tab">
                                        <p className="z-ml-8 z-notification-active">
                                            Cancelled
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {requests.length < 1 ? (
                                <div className="z-empty-div z-flex-column-center">
                                    <img src={empty} alt="empty" />
                                </div>
                            ) : (
                                requests
                                    .slice(currentNavIndex, nextNavIndex)
                                    .map((loan, i) => {
                                        return (
                                            <div
                                                className="z-flex-space z-guarantor-padding3 z-table-row"
                                                key={
                                                    i +
                                                    (currentPage - 1) * navDiff
                                                }
                                            >
                                                <div className="z-width-30">
                                                    <Icon
                                                        type="bars"
                                                        className="z-color-gray"
                                                    />
                                                </div>
                                                <div className="z-width-150 ">
                                                    <div className="">
                                                        <p className="z-table-row-p">
                                                            {loan.hasOwnProperty(
                                                                "loan_request"
                                                            ) &&
                                                                loan.loan_request !==
                                                                    null &&
                                                                loan
                                                                    .loan_request
                                                                    .user
                                                                    .firstname}{" "}
                                                            {loan.hasOwnProperty(
                                                                "loan_request"
                                                            ) &&
                                                                loan.loan_request !==
                                                                    null &&
                                                                loan
                                                                    .loan_request
                                                                    .user
                                                                    .lastname}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="z-width-100">
                                                    <div
                                                        className=""
                                                        onClick={(e) => {
                                                            this.showModal(
                                                                e,
                                                                i +
                                                                    (currentPage -
                                                                        1) *
                                                                        navDiff
                                                            );
                                                        }}
                                                    >
                                                        <p className="z-profile-small">
                                                            view request
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="z-width-100">
                                                    <div className="">
                                                        <p className="z-table-row-p">
                                                            {naira}
                                                            {loan.hasOwnProperty(
                                                                "loan_request"
                                                            ) &&
                                                                loan.loan_request !==
                                                                    null &&
                                                                formatCurrency(
                                                                    loan
                                                                        .loan_request
                                                                        .amount ||
                                                                        loan
                                                                            .loan_request
                                                                            .loan_package
                                                                            .amount
                                                                )}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="z-width-50 z-gres-small">
                                                    <div
                                                        className={
                                                            loan.guarantor_approved ===
                                                            "pending"
                                                                ? "z-new-dIcon z-drIcon z-req-yellow"
                                                                : loan.guarantor_approved ===
                                                                  "declined"
                                                                ? "z-new-dIcon z-drIcon z-req-red"
                                                                : "z-new-dIcon z-drIcon"
                                                        }
                                                    >
                                                        <Icon type="cloud" />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                            )}
                            {requests.length > 0 && (
                                <div className="z-margin-auto z-flex-center">
                                    <NavigationTab
                                        noOfPages={this.state.noOfPages}
                                        currentPage={this.state.currentPage}
                                        navigateLeft={this.navigateLeft}
                                        navigateRight={this.navigateRight}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="col-md-4">
                            <div className="mt-4">
                                <img
                                    src={sideBanner}
                                    className="img-fluid"
                                    alt="Loan sidebar image"
                                />
                            </div>
                        </div>
                    </div>
                    <Modal
                        className="z-custom z-custom5"
                        visible={viewDetails}
                        title={"w"}
                        onOk={this.handleOk}
                        onCancel={(e) => {
                            this.closeModal(e);
                        }}
                        footer={[
                            requests.length > 0 &&
                            requests[currentIndex].guarantor_approved ===
                                "pending" ? (
                                <div className="z-flex-row" key="8">
                                    <Popconfirm
                                        title="Are you sure you want to decline the request?"
                                        onConfirm={(e) => {
                                            this.declineLoan(e);
                                        }}
                                        onCancel={(e) => {
                                            this.cancel();
                                        }}
                                        okText="Decline"
                                        cancelText="No"
                                        key="1"
                                    >
                                        <Button
                                            className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row"
                                            shape="round"
                                            size="large"
                                            key="6"
                                            loading={declineRequest}
                                        >
                                            Decline
                                            <Icon type="close" />
                                        </Button>
                                    </Popconfirm>
                                    ,
                                    {showAddDetailStep ? (
                                        <Button
                                            className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg"
                                            shape="round"
                                            size="large"
                                            key="2"
                                            disabled={this.isAddBankDetailsButtonDisabled()}
                                            loading={addRequest}
                                            onClick={this.addBankDetails}
                                        >
                                            Add
                                            <Icon type="check" />
                                        </Button>
                                    ) : this.canApproveLoan() ? (
                                        <Popconfirm
                                            title="Are you sure you want to accept the request?"
                                            onConfirm={(e) => {
                                                this.acceptLoan(
                                                    requests.length > 0 &&
                                                        requests[currentIndex]
                                                            .loan_request_id
                                                );
                                            }}
                                            onCancel={(e) => {
                                                this.cancel();
                                            }}
                                            okText="Accept"
                                            cancelText="No"
                                            key="7"
                                        >
                                            <Button
                                                className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg"
                                                shape="round"
                                                size="large"
                                                key="4"
                                                loading={acceptLoading}
                                            >
                                                Accept
                                                <Icon type="check" />
                                            </Button>
                                        </Popconfirm>
                                    ) : showSelectFundingOptionStep ? (
                                        <Button
                                            className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg"
                                            shape="round"
                                            size="large"
                                            key="6"
                                            onClick={this.adminFunding}
                                        >
                                            Proceed <Icon type="check" />
                                        </Button>
                                    ) : showSelectaCardStep ? (
                                        <Button
                                            className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg"
                                            shape="round"
                                            size="large"
                                            key="3"
                                            loading={addcard || initCard}
                                            onClick={(e) => {
                                                this.addOldCard();
                                            }}
                                        >
                                            Add Card
                                            <Icon type="check" />
                                        </Button>
                                    ) : fundingLow ? (
                                        <Popconfirm
                                            title="Are you sure you want to fund the request?"
                                            onConfirm={(e) => {
                                                this.initializeWallet(e);
                                            }}
                                            onCancel={(e) => {
                                                this.cancel();
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                            key="14"
                                        >
                                            <Button
                                                className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg"
                                                shape="round"
                                                size="large"
                                                key="15"
                                            >
                                                Fund Wallet
                                                <Icon type="check" />
                                            </Button>
                                        </Popconfirm>
                                    ) : (
                                        <Button
                                            className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg"
                                            shape="round"
                                            size="large"
                                            key="5"
                                            onClick={(e) => {
                                                this.showBankDetails(e);
                                            }}
                                        >
                                            Approve
                                            <Icon type="check" />
                                        </Button>
                                    )}
                                </div>
                            ) : requests.length > 0 &&
                              requests[currentIndex].guarantor_approved ===
                                  "declined" ? (
                                <p className="z-declined" key={7}>
                                    Declined
                                </p>
                            ) : requests.length > 0 &&
                              requests[currentIndex].guarantor_approved ===
                                  "approved" ? (
                                <p className="z-accepted" key={8}>
                                    Accepted
                                </p>
                            ) : (
                                ""
                            ),
                        ]}
                    >
                        <div>
                            <div className="z-flex-space z-nav-holder z-modal-nav">
                                <div className="z-not-left  z-flex-column-center z-modal-b">
                                    <Icon type="left" />
                                </div>
                                <div className="z-flex-space z-width-35">
                                    <div className="z-flex-column-center z-not-right">
                                        {currentIndex + 1}
                                    </div>{" "}
                                    <p className="z-nav-holder-p">/</p>
                                    <p className="z-nav-holder-p">
                                        {requests.length}
                                    </p>
                                </div>
                                <div className="z-not-left z-flex-column-center z-modal-b">
                                    <Icon type="right" />
                                </div>
                            </div>
                            {showAddDetailStep ? (
                                <GuarantorBankDetails
                                    accountNumber={accountNumber}
                                    companyLevelId={companyLevelId}
                                    onCompnayLevelChange={
                                        this.onCompnayLevelChange
                                    }
                                    changeInput2={this.changeInput2}
                                    onBankNameChange={this.onBankNameChange}
                                    bankName={bankName}
                                    accountName={accountName}
                                    onAccountNameChange={
                                        this.onAccountNameChange
                                    }
                                />
                            ) : showSelectFundingOptionStep ? (
                                <WalletOptionCard
                                    onRadioChange={this.onRadioChange}
                                    fundingOption={fundingOption}
                                    changeGuaranteeLoanWithCard={
                                        this.changeGuaranteeLoanWithCard
                                    }
                                    guaranteeLoanWithCard={
                                        guaranteeLoanWithCard
                                    }
                                />
                            ) : showSelectaCardStep ? (
                                <GuarantorCard
                                    cardDetails={cardDetails}
                                    cards={cards}
                                    navRight={this.navRight}
                                    navLeft={this.navLeft}
                                    launchGateway={this.launchGateway}
                                    currentCard={currentCard}
                                />
                            ) : fundingLow ? (
                                <FundingWalletPanel
                                    initializeWallet={initializeWallet}
                                    changeFundingOption={
                                        this.changeFundingOption
                                    }
                                    amount={
                                        requests[currentIndex].loan_request
                                            .amount ||
                                        requests[currentIndex].loan_request
                                            .loan_package.amount
                                    }
                                    cards={cards}
                                    currentCard={currentCard}
                                />
                            ) : (
                                <GuarantorModalDetails
                                    currentIndex={currentIndex}
                                    requests={requests}
                                />
                            )}
                        </div>
                    </Modal>
                    {launchPaystack ? (
                        <PaystackGateway
                            amount={initAmount}
                            cardDetailsSuccess={this.cardDetailsSuccess}
                            cardDetailsError={this.cardDetailsError}
                            referenceId={initReference}
                        />
                    ) : null}
                </Skeleton>
            </div>
        );
    }
}
