import React, {Fragment} from 'react';
import Header from "../GuarantorGuide/Header";
import Stepper from "./Stepper";
import guarantorGuideBG from "../../../../assets/images/getting-help/guarantorGuide1.png";

const FirstStep = (props) => {
    const {setCurrentPageStep, setPageContent} = props;
    return (
        <Fragment>
            <div className="page">
                <Header setPageContent={setPageContent} />
                <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="circleNum mb-4">1</div>
                        <div className="help-topic">Log in/Sign up</div>
                        <p className="help-content">Go to sfscredit.com, login or signup as a new user,
                            as a new user - first, select your company in the dropdown list
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-7">
                        <div>
                            <img src={guarantorGuideBG} alt="Background Image" className="img-fluid top-img"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Stepper setCurrentPageStep={setCurrentPageStep} active="1"/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FirstStep;