import React, {Fragment} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

const Header = (props) => {
    const {setPageContent} = props;
    return (
       <Fragment>
           <p className="mb-3" onClick={() => setPageContent('gettingStarted')}>
               <FontAwesomeIcon icon={faArrowLeft} size="xs" className="icon-arrow"/>
               &nbsp;&nbsp;<span className="header-text">GETTING STARTED</span>
           </p>
           <p className="mb-5 header-subtitle">How to apply for loans in seven easy steps</p>
       </Fragment>
    );
};

export default Header;