import React from "react";
import moment from "moment";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";
import { Row } from "antd";
import DefaultAvatar from "../../../assets/images/unisex_avatar_icon.png";

export default ({ requests, currentIndex }) => (
    <Row>
        <div className="z-flex-space z-p-3 z-cup-res">
            <div className="z-testimonial-avatar z-profile-avatar z-mb-1 z-img-100">
                <img
                    src={
                        requests.length > 0 &&
                        requests[currentIndex].hasOwnProperty("loan_request") &&
                        requests[currentIndex].loan_request !== null &&
                        (requests[currentIndex].loan_request.user
                            .user_profile &&
                        requests[currentIndex].loan_request.user.user_profile
                            .avatar
                            ? requests[currentIndex].loan_request.user
                                  .user_profile.avatar
                            : DefaultAvatar)
                    }
                    alt="avatar"
                />
            </div>
            <div className="z-width-60">
                <div>
                    {requests.length > 0 && (
                        <p className="z-modal-header">
                            {requests[currentIndex].hasOwnProperty(
                                "loan_request"
                            ) &&
                                requests[currentIndex].loan_request !== null &&
                                requests[currentIndex].loan_request.user
                                    .firstname}{" "}
                            {requests[currentIndex].hasOwnProperty(
                                "loan_request"
                            ) &&
                                requests[currentIndex].loan_request !== null &&
                                requests[currentIndex].loan_request.user
                                    .lastname}
                        </p>
                    )}
                </div>
                <div className="z-width-100 z-ml-2 z-mt-8">
                    <div className="z-flex-space">
                        <div className="z-modal-left">
                            <p>Date applied </p>
                            <p>Amount Requested</p>
                            <p>Total Repayment Amount</p>
                            <p>Current Monthly Salary</p>
                            <p>Company</p>
                        </div>
                        <div className="z-modal-right">
                            <p>
                                {requests.length > 0 &&
                                    requests[currentIndex].hasOwnProperty(
                                        "loan_request"
                                    ) &&
                                    requests[currentIndex].loan_request !==
                                        null &&
                                    moment(
                                        requests[currentIndex].loan_request
                                            .created_at
                                    ).format("Do MMMM YYYY")}
                            </p>
                            <p>
                                {requests.length > 0 &&
                                    requests[currentIndex].hasOwnProperty(
                                        "loan_request"
                                    ) &&
                                    requests[currentIndex].loan_request !==
                                        null &&
                                    naira +
                                        formatCurrency(
                                            requests[currentIndex].loan_request
                                                .amount ||
                                                requests[currentIndex]
                                                    .loan_request.loan_package
                                                    .amount
                                        )}
                            </p>
                            <p>
                                {requests.length > 0 &&
                                    requests[currentIndex].hasOwnProperty(
                                        "loan_request"
                                    ) &&
                                    requests[currentIndex].loan_request !==
                                        null &&
                                    naira +
                                        formatCurrency(
                                            requests[currentIndex].loan_request
                                                .total_payback ||
                                                requests[currentIndex]
                                                    .loan_request.loan_package
                                                    .total_payback
                                        )}
                            </p>
                            <p>
                                {requests.length > 0 &&
                                    requests[currentIndex].hasOwnProperty(
                                        "loan_request"
                                    ) &&
                                    requests[currentIndex].loan_request !==
                                        null &&
                                    naira +
                                        formatCurrency(
                                            requests[currentIndex].loan_request
                                                .current_salary
                                        )}
                            </p>
                            <p>
                                {requests.length > 0 &&
                                    requests[currentIndex].hasOwnProperty(
                                        "loan_request"
                                    ) &&
                                    requests[currentIndex].loan_request !==
                                        null &&
                                    requests[currentIndex].loan_request.user
                                        .company.name}
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="z-file-div z-mt-8">
                    <a href={requests.length > 0 && (requests[currentIndex].hasOwnProperty("loan_request") && requests[currentIndex].loan_request !== null)
                    ? requests[currentIndex].loan_request.bank_statement: ""}>
                        View bank statement
                    </a>
                </div> */}
            </div>
        </div>
    </Row>
);
