import React, { Component } from "react";
import { Button, Col, Icon, Modal, Row, Skeleton, Table } from "antd";
import Column from "antd/lib/table/Column";
import { getUsers } from "../../../APIsHandler/ApiController";
import moment from "moment";
import NavigationTab from "../../NavigationTab";
import DefaultAvatar from "../../../assets/images/unisex_avatar_icon.png";
import BlacklistCustomerButton from "./BlacklistCustomerButton";

export default class CustomersTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: false,
            todayDate: new Date(),
            selectedRowKeys: [],
            fetchingUsers: true,
            users: [],
            currentNavIndex: 0,
            nextNavIndex: 10,
            navDiff: 10,
            noOfPages: 0,
            currentPage: 1,
            viewUser: false,
            currentIndex: 0,
        };
    }

    componentDidMount() {
        this.getAllUsers();
    }

    getAllUsers = () => {
        getUsers()
            .then((res) => {
                let { data } = res.data;
                const loanUsers = data.filter((user) => {
                    return user.roles[0].name === "user";
                });
                console.log("users", loanUsers);
                let num = Math.ceil(loanUsers.length / this.state.navDiff);
                this.setState({
                    users: loanUsers,
                    noOfPages: num,
                    fetchingUsers: false,
                });
            })
            .catch((err) => {
                this.setState({ fetchingUsers: false });
            });
    }

    onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    onSelectChange = (selectedRowKeys) => {
        console.log("selectedRowKeys changed: ", selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    navigateRight = () => {
        const {
            navDiff,
            nextNavIndex,
            currentNavIndex,
            currentPage,
            noOfPages,
        } = this.state;

        if (currentPage !== noOfPages) {
            this.setState({
                nextNavIndex: nextNavIndex + navDiff,
                currentNavIndex: currentNavIndex + navDiff,
                currentPage: (nextNavIndex + navDiff) / navDiff,
            });
        }
    };

    navigateLeft = () => {
        const { navDiff, nextNavIndex, currentNavIndex, currentPage } =
            this.state;

        if (currentPage !== 1) {
            this.setState({
                nextNavIndex: nextNavIndex - navDiff,
                currentNavIndex: currentNavIndex - navDiff,
                currentPage: (nextNavIndex - navDiff) / navDiff,
            });
        }
    };

    /*
     * Show user modal*/
    showApprove = (i) => {
        this.setState({ currentIndex: i, viewUser: true });
    };

    /*
     *Close user modal*/
    closeUser = (i) => {
        this.setState({ viewUser: false });
    };

    showUserTransaction = (id) => {
        window.location.href = "/dashboard/admin/transactions/" + id;
    };

    render() {
        const {
            selectedRowKeys,
            users,
            fetchingUsers,
            currentNavIndex,
            nextNavIndex,
            navDiff,
            currentPage,
            viewUser,
            currentIndex,
        } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return (
            <div>
                <div>
                    <h1 className="z-dashboard-label">Customers</h1>
                </div>

                <Skeleton loading={fetchingUsers} active>
                    <div>
                        <Row>
                            <Col span={24}>
                                <div
                                    className="z-dashboard-panel z-mb-5 z-width-100 z-admin-panel2"
                                    style={{ height: "100%" }}
                                >
                                    <div className="z-flex-start z-mb-6">
                                        <Icon
                                            type="copy"
                                            className="z-label-icon"
                                        />
                                        <p className="z-label-text">
                                            Customers
                                        </p>
                                    </div>
                                    <div>
                                        <Table
                                            rowSelection={rowSelection}
                                            bordered={false}
                                            dataSource={users.slice(
                                                currentNavIndex,
                                                nextNavIndex
                                            )}
                                            className="z-admin-table z-todo"
                                            pagination={false}
                                            rowKey="id"
                                            rowClassName={(record, index) => {
                                                if (index % 2 === 0) {
                                                    return "z-stripped";
                                                }
                                            }}
                                            scroll={{ x: "220%" }}
                                        >
                                            <Column
                                                title="Mark all"
                                                dataIndex="sn"
                                                key="sn"
                                                render={(
                                                    text,
                                                    record,
                                                    index
                                                ) => {
                                                    return (
                                                        <div className="">
                                                            <p className="z-table-num z-no-mb">
                                                                {Number(
                                                                    index +
                                                                        (currentPage -
                                                                            1) *
                                                                            navDiff
                                                                ) + 1}
                                                            </p>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Name"
                                                dataIndex="firstName"
                                                key="firstName"
                                                render={(text, record) => {
                                                    return (
                                                        <div className="z-flex-start">
                                                            <div className="z-testimonial-avatar z-table-avatar z-ml-4">
                                                                <img
                                                                    src={
                                                                        record.hasOwnProperty(
                                                                            "user_profile"
                                                                        ) &&
                                                                        record.user_profile !==
                                                                            null
                                                                            ? record
                                                                                  .user_profile
                                                                                  .avatar
                                                                                ? record
                                                                                      .user_profile
                                                                                      .avatar
                                                                                : DefaultAvatar
                                                                            : DefaultAvatar
                                                                    }
                                                                    alt="logo"
                                                                />
                                                            </div>
                                                            <div className="z-ml-7">
                                                                <p className="z-table-name z-table-parag ">
                                                                    {
                                                                        record.firstname
                                                                    }{" "}
                                                                    {
                                                                        record.lastname
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Email"
                                                dataIndex="email"
                                                key="email"
                                                render={(text, record) => {
                                                    return (
                                                        <div>
                                                            <p className="z-table-status z-table-parag2">
                                                                {record.email}
                                                            </p>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Company"
                                                dataIndex="company"
                                                key="company"
                                                render={(text, record) => {
                                                    return (
                                                        <p className="z-table-text z-table-parag">
                                                            {record.company !==
                                                                null &&
                                                            record.company !==
                                                                "undefined"
                                                                ? record.company
                                                                      .name
                                                                : ""}
                                                        </p>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Date Joined"
                                                dataIndex="joined"
                                                key="joined"
                                                render={(text, record) => {
                                                    return (
                                                        <p className="z-table-text2 z-table-parag2">
                                                            {moment(
                                                                record.created_at
                                                            ).format(
                                                                "DD-MM-YYYY"
                                                            )}
                                                        </p>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Status"
                                                dataIndex="status"
                                                key="status"
                                                render={(text, record) => {
                                                    return (
                                                        <p className="z-table-text z-table-parag3">
                                                            {record.user_profile
                                                                ? "Profile Updated"
                                                                : "No Profile Yet"}
                                                        </p>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title=""
                                                dataIndex="action"
                                                key="Action"
                                                render={(i, record, index) => {
                                                    return (
                                                        <div className="">
                                                            <Button
                                                                className="z-tablebtn"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    this.showApprove(
                                                                        index +
                                                                            (currentPage -
                                                                                1) *
                                                                                navDiff
                                                                    );
                                                                }}
                                                            >
                                                                {" "}
                                                                view
                                                            </Button>
                                                            <BlacklistCustomerButton
                                                                customerId={record.id}
                                                                reload={this.getAllUsers}
                                                                key={record.id}
                                                                isDisabled={record.is_blacklisted}
                                                            />
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </Table>
                                    </div>

                                    <Modal
                                        className="z-custom z-custom2 "
                                        visible={viewUser}
                                        title={"w"}
                                        onCancel={(e) => {
                                            this.closeUser(e);
                                        }}
                                        footer={[
                                            <Button
                                                className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row"
                                                shape="round"
                                                size="large"
                                                key="3"
                                                onClick={(e) => {
                                                    this.closeUser(e);
                                                }}
                                            >
                                                Close
                                                <Icon type="close" />
                                            </Button>,
                                            <Button
                                                className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg"
                                                shape="round"
                                                size="large"
                                                key="4"
                                                onClick={(e) => {
                                                    this.showUserTransaction(
                                                        users.length > 0 &&
                                                            users[currentIndex]
                                                                .id
                                                    );
                                                }}
                                            >
                                                Transactions
                                                <Icon type="check" />
                                            </Button>,
                                        ]}
                                    >
                                        <Row>
                                            <div className="z-flex-space z-p-3">
                                                <div className="z-testimonial-avatar z-profile-avatar z-mb-1 z-img-100">
                                                    <img
                                                        src={
                                                            users.length > 0 &&
                                                            users[currentIndex]
                                                                .user_profile
                                                                ? users[
                                                                      currentIndex
                                                                  ].user_profile
                                                                      .avatar
                                                                    ? users[
                                                                          currentIndex
                                                                      ]
                                                                          .user_profile
                                                                          .avatar
                                                                    : DefaultAvatar
                                                                : DefaultAvatar
                                                        }
                                                        alt="avatar"
                                                    />
                                                </div>
                                                <div className="z-width-60">
                                                    <div>
                                                        {users.length > 0 && (
                                                            <p className="z-modal-header">
                                                                {
                                                                    users[
                                                                        currentIndex
                                                                    ].full_name
                                                                }
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="z-width-100 z-ml-2 z-mt-8">
                                                        <div className="z-flex-space-start">
                                                            <div className="z-modal-left">
                                                                <p>Email </p>
                                                                <p>
                                                                    Date of
                                                                    birth
                                                                </p>
                                                                <p>
                                                                    Next of kin
                                                                </p>
                                                                <p>
                                                                    Phone Number
                                                                </p>
                                                                <p>
                                                                    Date Created
                                                                </p>
                                                            </div>
                                                            <div className="z-modal-right">
                                                                <p>
                                                                    {users.length >
                                                                        0 &&
                                                                        users[
                                                                            currentIndex
                                                                        ].email}
                                                                </p>
                                                                <p>
                                                                    {users.length >
                                                                        0 &&
                                                                    users[
                                                                        currentIndex
                                                                    ]
                                                                        .user_profile
                                                                        ? moment(
                                                                              users[
                                                                                  currentIndex
                                                                              ]
                                                                                  .user_profile
                                                                                  .date_of_birth
                                                                          ).format(
                                                                              "Do MMMM YYYY"
                                                                          )
                                                                        : "Null"}
                                                                </p>
                                                                <p>
                                                                    {users.length >
                                                                        0 &&
                                                                    users[
                                                                        currentIndex
                                                                    ]
                                                                        .user_profile
                                                                        ? users[
                                                                              currentIndex
                                                                          ]
                                                                              .user_profile
                                                                              .next_of_kin
                                                                        : "Null"}
                                                                </p>
                                                                <p>
                                                                    {users.length >
                                                                        0 &&
                                                                    users[
                                                                        currentIndex
                                                                    ]
                                                                        .user_profile
                                                                        ? users[
                                                                              currentIndex
                                                                          ]
                                                                              .user_profile
                                                                              .phone_number
                                                                        : "Null"}
                                                                </p>
                                                                <p>
                                                                    {users.length >
                                                                        0 &&
                                                                        moment(
                                                                            users[
                                                                                currentIndex
                                                                            ]
                                                                                .created_at
                                                                        ).format(
                                                                            "Do MMMM YYYY"
                                                                        )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </Modal>

                                    {users.length > 0 && (
                                        <div className="z-admin-gr-div">
                                            <NavigationTab
                                                noOfPages={this.state.noOfPages}
                                                currentPage={
                                                    this.state.currentPage
                                                }
                                                navigateLeft={this.navigateLeft}
                                                navigateRight={
                                                    this.navigateRight
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Skeleton>
            </div>
        );
    }
}
