import React, { useState, useEffect } from "react";
import { Button, Col, Icon, Input, Modal, Row, Skeleton, message } from "antd";
import {
    getAllBlacklist,
} from "../../../APIsHandler/ApiController";
import BlacklistRows from "../../globalComponents/BlacklistRows";
import NavigationTab from "../../NavigationTab";
import CreateBlacklistModal from "./CreateBlacklistModal";

export default function Blacklist() {
    const [fetchingTransactions, setFetchingTransactions] = useState(true);
    const [blacklists, setBlacklists] = useState([]);
    const [currentNavIndex, setCurrentNavIndex] = useState(0);
    const [nextNavIndex, setNextNavIndex] = useState(10);
    const [navDiff, setNavDiff] = useState(10);
    const [noOfPages, setNoOfPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    // modal
    const [createBlacklistModal, setCreateBlacklistModal] = useState(false);

    useEffect(() => {
        loadBlacklistedEmails();
    }, []);

    function loadBlacklistedEmails() {
        setFetchingTransactions(true);

        getAllBlacklist()
            .then((response) => {
                const data = response.data.data;

                setNoOfPages(Math.ceil(data.length / navDiff));
                setBlacklists(data);
                setFetchingTransactions(false);
            })
            .catch((error) => {
                setFetchingTransactions(false);

                message.error(
                    `Unable to fetch blacklisted Emails.  ${
                        error.data !== undefined ? error.data.message : ""
                    }. Please try again.`,
                    3
                );
            });
    }

    function navigateRight() {
        if (currentPage !== noOfPages) {
            setNextNavIndex(nextNavIndex + navDiff);
            setCurrentNavIndex(currentNavIndex + navDiff);
            setCurrentPage((nextNavIndex + navDiff) / navDiff);
        }
    }

    function navigateLeft() {
        if (currentPage !== 1) {
            setNextNavIndex(nextNavIndex - navDiff);
            setCurrentNavIndex(currentNavIndex - navDiff);
            setCurrentPage((nextNavIndex - navDiff) / navDiff);
        }
    }

    return (
        <div>
            <div>
                <h1 className="z-dashboard-label">All Blacklisted Emails</h1>
            </div>

            <Skeleton loading={fetchingTransactions} active>
                <div>
                    <Row>
                        <Col span={24}>
                            <div
                                className="z-dashboard-panel z-mb-5 z-width-100 z-admin-panel2"
                                style={{ height: "100%" }}
                            >
                                <div className="z-flex-start z-mb-6">
                                    <Icon
                                        type="copy"
                                        className="z-label-icon"
                                    />
                                    <p className="z-label-text">
                                        All Blacklist
                                    </p>
                                </div>
                                <div>
                                    <Button
                                        type="primary"
                                        icon="plus"
                                        className="z-tab-btn"
                                        onClick={() =>
                                            setCreateBlacklistModal(true)
                                        }
                                    >
                                        Add new blacklist
                                    </Button>
                                </div>
                                <div>
                                    <BlacklistRows
                                        currentNavIndex={currentNavIndex}
                                        nextNavIndex={nextNavIndex}
                                        blacklists={blacklists}
                                        currentPage={currentPage}
                                        navDiff={navDiff}
                                        reload={loadBlacklistedEmails}
                                    />
                                </div>

                                {blacklists.length > 0 && (
                                    <div className="z-admin-gr-div">
                                        <NavigationTab
                                            noOfPages={noOfPages}
                                            currentPage={currentPage}
                                            navigateLeft={navigateLeft}
                                            navigateRight={navigateRight}
                                        />
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <CreateBlacklistModal
                        closeModal={() => setCreateBlacklistModal(false)}
                        isVisible={createBlacklistModal}
                        reload={loadBlacklistedEmails}
                    />
                </div>
            </Skeleton>
        </div>
    );
}
