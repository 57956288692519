import React, {Fragment, useState} from "react";
import {Button} from "antd";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";

export default function GuarantorGuide(props) {
    const [currentPageStep, setCurrentPageStep] = useState('first');
    const {setPageContent} = props;

    let step;
    if(currentPageStep === 'first') {
        step = <FirstStep {...props} setCurrentPageStep={setCurrentPageStep} />
    }
    if (currentPageStep === 'second') {
        step = <SecondStep {...props} setCurrentPageStep={setCurrentPageStep} />
    }
    if (currentPageStep === 'third') {
        step = <ThirdStep {...props} setCurrentPageStep={setCurrentPageStep} />
    }
    if (currentPageStep === 'fourth') {
        step = <FourthStep {...props} setCurrentPageStep={setCurrentPageStep} />
    }

    return (
        <Fragment>
            <div className="gs-main_card mt-5">
                <div className="row mt-4 ml-2 mr-2 mb-4">
                    <div className="col-md-3">
                        <Button className="gs-btn" onClick={() => setPageContent('gettingStarted')}>
                            Getting started
                        </Button>
                        <div className="help-nav mt-4">
                            <p className="help-nav_item not-active" onClick={() => setPageContent('applyLoan')}>
                                How to apply for Loans
                            </p>
                            <hr />
                            <p className="help-nav_item active"  onClick={() => setPageContent('guarantorGuide')}>
                                How to guarantee a loan request
                            </p>
                        </div>

                    </div>
                    <div className="col-md-9 step-card">
                        {step}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}