import React, {Component} from 'react';
import {Button, Col, Modal, Row} from "antd";
import "../../assets/css/main.css";
import Icon from '../../assets/images/illustration.png';
import Icon3 from '../../assets/images/forward icon.png';
import Icon2 from '../../assets/images/illustration (2).png';


export default class WelcomeMessageModal extends Component{


    render() {
        const {state, closeWelcomeModal, showSignupModal, signIn} = this.props;
        return(
            <div>
                <Modal className="z-custom z-modal-welcome"
                    visible={state.welcomeModal}
                    title={null}
                    onOk={this.handleOk}
                    onCancel={e => {closeWelcomeModal()}}
                    footer={null}
                >
                    <div>
                        <Row>
                            {state.pageType === 0 &&
                            <Col span={24} className="z-testimonial z-welcome z-flex-column-center">
                                <h2 className="z-mb-31">
                                    Hello & Welcome
                                </h2>
                                <div className="z-welcome-icon">
                                    <img src={Icon} alt="users"/>
                                </div>
                                <div className="z-flex-column-center z-mt-13">
                                    <Button className="z-landing-btn z-section-btn z-mb-6 z-button-gradient z-section-btn-login"
                                            shape="round" size="large" onClick={e => {showSignupModal(e, 0)}}>New User</Button>
                                    <Button className="z-landing-btn z-section-btn z-button-plain z-section-btn-login" shape="round" size="large"
                                            onClick={e => {showSignupModal(e, 1)}}>Returning Users</Button>
                                </div>
                            </Col> }

                            {state.pageType === 1 &&
                            <Col span={24} className="z-testimonial z-welcome z-flex-column-center">
                                <h2 className="z-mb-31">
                                    Sign Up Complete
                                </h2>
                                <div className="z-welcome-icon">
                                    <img src={Icon2} alt="thumbs-up"/>
                                </div>
                                <div>
                                    <p className="z-complete-p">Kindly proceed to your Email inbox to verify account</p>
                                </div>
                                <div className="z-flex-column-center z-mt-6">
                                    <Button className="z-landing-btn z-complete-btn z-button-gradient z-flex-row"
                                            shape="round" size="large" onClick={e => {signIn(e)}}>Home
                                        <img className="z-btn-img" src={Icon3} alt="next"/></Button>
                                </div>
                            </Col> }
                        </Row>
                    </div>
                </Modal>
            </div>
        )
    }
}