import React from "react";

import { Row, Icon } from "antd";
import card from "../../../assets/images/undraw_Savings_dwkw.svg";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";

export default function FundingWalletPanel({
    initializeWallet,
    changeFundingOption,
    amount,
}) {
    return (
        <Row>
            <div>
                <div>
                    <div className="z-icon-modal z-icon-modal2">
                        <img src={card} alt="card" />
                    </div>
                    {initializeWallet ? (
                        <div className="z-wallet-radio">
                            <p className="z-apply-label-small z-no-mb z-modal-parag-small z-mll">
                                Funding wallet. Almost there...{" "}
                                <Icon type="loading" />
                            </p>
                        </div>
                    ) : (
                        <div>
                            <p className="z-apply-label-small z-modal-parag">
                                Insufficient balance!. A sum of{" "}
                                <b>
                                    {naira} {formatCurrency(amount)}{" "}
                                </b>{" "}
                                will be deducted from the authorized card.
                            </p>
                            <div className="z-wallet-radio">
                                <p
                                    className="z-apply-label-small z-no-mb z-modal-parag-small z-mll z-navp-bt"
                                    onClick={(e) => {
                                        changeFundingOption();
                                    }}
                                >
                                    Change funding option
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Row>
    );
}
