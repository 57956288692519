import React, { Component } from 'react';
import {Switch, Route} from 'react-router-dom';
import './App.scss';
import HomePage from "./components/homepage/HomePage";
import 'antd/dist/antd.css';
import DashboardLayout from "./components/dashboardLayout/DashboardLayout";
import VerifyEmail from "./components/globalComponents/VerifyEmail";
import PasswordResetPage from "./components/globalComponents/PasswordResetPage";
import GettingStarted from "./components/homepage/HowTos/GettingStarted";


class App extends Component {
  render() {
    return (
        <div>
          <Switch>
            <Route exact path={'/'} component={HomePage}/>
            <Route exact path={'/getting-started'} component={GettingStarted} />
            <Route exact path={'/verify'} component={VerifyEmail} />
            <Route exact path={'/reset'} component={PasswordResetPage} />
            <Route path={'/dashboard'} component={DashboardLayout}/>
          </Switch>
        </div>
    );
  }
}

export default App;
