import React, { useEffect, useState } from "react";
import { Col, Input, Row, Select, message, Skeleton } from "antd";
import {
    resolveAccountCn,
    userHasAccount,
} from "../../../APIsHandler/ApiController";

const { Option } = Select;

export default function UserApplicationGuarrantorForm({
    companyLevelLoading,
    companyLevels,
    banks,
    state,
    handleField,
    setGuarantorAccountName,
    setGuarantorHasAccount,
}) {
    useEffect(() => {
        const { guarantorEmail } = state;

        if (guarantorEmail.length > 0 && isEmail(guarantorEmail)) {
            resolveGuarantorInfoForm();
        }
    }, [state.guarantorEmail]);

    const [showGuarantorInfoForm, setShowGuarantorInfoForm] = useState(false);
    const [checkingGurantorAccount, setCheckingGuarantorAccount] =
        useState(false);
    const [accountNameLoading, setAccountNameLoading] = useState(false);

    const resolveGuarantorInfoForm = () => {
        setCheckingGuarantorAccount(true);
        userHasAccount(state.guarantorEmail)
            .then((data) => {
                setCheckingGuarantorAccount(false);

                setGuarantorHasAccount(data.data.data);

                setShowGuarantorInfoForm(!data.data.data); // If guarantor does not have an account show guarantor info form
            })
            .catch((err) => {
                setCheckingGuarantorAccount(false);
                message.error("Something went wrong, please try again");
            });
    };

    const resolveAccount = (guarantorAccountNumber, guarantorBankName) => {
        if (guarantorAccountNumber.length > 9 && guarantorBankName.length > 0) {
            setAccountNameLoading(true);

            resolveAccountCn({
                account: guarantorAccountNumber,
                bank: guarantorBankName,
            })
                .then((res) => {
                    const { data } = res.data;

                    setGuarantorAccountName(data.account_name);
                    setAccountNameLoading(false);
                })
                .catch((err) => {
                    setAccountNameLoading(false);
                    message.error(
                        "Unable to resolve account. Please check the account details.",
                        3
                    );
                });
        }
    };

    const handleAccountNumberChange = (e) => {
        resolveAccount(e.target.value, state.guarantorBankName);

        handleField(e);
    };

    const handleSelect = (value, name, execResolveAccount = false) => {
        if (execResolveAccount)
            resolveAccount(state.guarantorAccountNumber, value);

        handleField({
            target: {
                name,
                value: value,
            },
        });
    };

    const isEmail = (email) => {
        const regex =
            /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    };

    return (
        <Skeleton loading={checkingGurantorAccount} active>
            {showGuarantorInfoForm && (
                <>
                    <Row className="z-mt-7 z-mb-2">
                        {/* <Col span={12} className="z-width-44 z-mr-6"> */}
                        <hr />
                        <p className="z-apply-label-large">
                            Other Guarantor Information
                        </p>
                        {/* </Col> */}
                    </Row>

                    <Row className="z-mt-2">
                        <Col span={12} className="z-width-44 z-mr-6">
                            <p className="z-apply-label-small">
                                Guarantor's Firstname
                            </p>
                            <Input
                                size="large"
                                placeholder="Guarantor Firstname"
                                name="guarantorFirstname"
                                value={state.guarantorFirstname}
                                onChange={handleField}
                                className="z-footer-input z-apply-input z-apply-input-small"
                            />
                        </Col>
                        <Col span={12} className="z-width-44">
                            <p className="z-apply-label-small">
                                Guarantor's Lastname
                            </p>
                            <Input
                                size="large"
                                placeholder="Guarantor Lastname"
                                name="guarantorLastname"
                                value={state.guarantorLastname}
                                onChange={handleField}
                                className="z-footer-input z-apply-input z-apply-input-small"
                            />
                        </Col>
                    </Row>
                    <Row className="z-mt-7">
                        <Col span={12} className="z-width-44 z-mr-6">
                            <p className="z-apply-label-small">
                                Guarantor's Phone Number
                            </p>
                            <Input
                                size="large"
                                placeholder="Phone Number"
                                name="guarantorPhone"
                                value={state.guarantorPhone}
                                onChange={handleField}
                                className="z-footer-input z-apply-input z-apply-input-small"
                            />
                        </Col>
                        <Col span={12} className="z-width-44">
                            <p className="z-apply-label-small">
                                Select Guarantor's Level
                            </p>
                            <Skeleton loading={companyLevelLoading} active>
                                <Select
                                    className="z-apply-select"
                                    style={{ width: "100%" }}
                                    onChange={(value) =>
                                        handleSelect(value, "guarantorLevel")
                                    }
                                    value={state.guarantorLevel}
                                    placeholder="Select a Level"
                                >
                                    {companyLevels.map((companyLevel) => {
                                        return (
                                            <Option
                                                value={companyLevel.id}
                                                key={companyLevel.id}
                                            >
                                                {companyLevel.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Skeleton>
                        </Col>
                    </Row>
                    <Row className="z-mt-7">
                        <Col span={12} className="z-width-44 z-mr-6">
                            <p className="z-apply-label-small">
                                Guarantor's Account Number
                            </p>
                            <div>
                                <Input
                                    size="large"
                                    placeholder="Guarantor's Account Number"
                                    name="guarantorAccountNumber"
                                    value={state.guarantorAccountNumber}
                                    onChange={handleAccountNumberChange}
                                    className="z-footer-input z-apply-input z-apply-input-small"
                                />
                            </div>
                        </Col>
                        <Col span={12} className="z-width-44">
                            <p className="z-apply-label-small">
                                Guarantor's Bank Name
                            </p>
                            <Select
                                className="z-apply-select"
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Select a bank"
                                optionFilterProp="children"
                                value={state.guarantorBankName}
                                onChange={(value) =>
                                    handleSelect(
                                        value,
                                        "guarantorBankName",
                                        true
                                    )
                                }
                                // onSearch={this.onSearch}
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {banks.map((bank, i) => {
                                    return (
                                        <Option value={bank.code} key={i}>
                                            {bank.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                    </Row>
                    <Row className="z-mt-7">
                        <Col span={12} className="z-width-44 z-mr-6">
                            <p className="z-apply-label-small">
                                Guarantor's Account Name
                            </p>
                            <Skeleton loading={accountNameLoading} active>
                                <div>
                                    <Input
                                        size="large"
                                        placeholder="Guarantor's Account Name"
                                        name="guarantorAccountName"
                                        value={state.guarantorAccountName}
                                        onChange={setGuarantorAccountName}
                                        className="z-footer-input z-apply-input z-apply-input-small"
                                        disabled={
                                            state.guarantorAccountName.length >
                                            2
                                        }
                                    />
                                </div>
                            </Skeleton>
                        </Col>
                    </Row>
                    <Row className="z-mt-1">
                        {" "}
                        <hr />{" "}
                    </Row>
                </>
            )}
        </Skeleton>
    );
}
