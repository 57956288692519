import React, { Component } from "react";
import {
    Badge,
    message,
    Skeleton,
} from "antd";
import "../../../assets/js/loading-bar.css";
import {
    getUserRequest,
} from "../../../APIsHandler/ApiController";
import moment from "moment";
import empty from "../../../assets/images/undraw_no_data_qbuo (3).svg";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";
import NavigationTab from "../../NavigationTab";
import overviewIcon from "../../../assets/images/dashboard/overview.svg";
import sideBanner from "../../../assets/images/dashboard/loanBanner.png";
import UserLoanDetails from "./UserLoanDetails";

export default class UserLoans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewDetails: false,
            loading: true,
            usersLoans: [],
            currentIndex: 0,
            tabNum: 0,
            allRequests: [],
            currentNavIndex: 0,
            nextNavIndex: 10,
            navDiff: 10,
            noOfPages: 0,
            currentPage: 1,
            loadingCancelLoan: false,
        };
    }

    componentDidMount() {
        this.fetchLoanRequest();
    }

    fetchLoanRequest = () => {
        getUserRequest()
            .then((res) => {
                const { data } = res.data;
                let num = Math.ceil(data.length / this.state.navDiff);
                this.setState({
                    allRequests: data,
                    usersLoans: data,
                    noOfPages: num,
                    loading: false,
                });
            })
            .catch((err) => {
                message.error("Unable to load page", 3);
                this.setState({ loading: false });
            });
    };

    //closes details modal
    closeModal = (e) => {
        this.setState({ viewDetails: false });
    };

    //shows details modal
    showModal = (e, i) => {
        this.setState({ currentIndex: i, viewDetails: true });
    };

    /*
     * Toogle tabs
     * */
    setTab = (e, i) => {
        this.setState({ tabNum: i });
        this.sortData(i);
    };

    /*
     * sort table data*/
    sortData = (tab) => {
        const { allRequests, navDiff } = this.state;
        if (tab === 1) {
            const sorted = allRequests.filter((request) => {
                return request.status === "approved";
            });
            let num = Math.ceil(sorted.length / navDiff);
            this.setState({ usersLoans: sorted, noOfPages: num });
        } else if (tab === 2) {
            const sorted = allRequests.filter((request) => {
                return request.status === "pending";
            });
            let num = Math.ceil(sorted.length / navDiff);
            this.setState({ usersLoans: sorted, noOfPages: num });
        } else if (tab === 3) {
            const sorted = allRequests.filter((request) => {
                return request.status === "declined";
            });
            let num = Math.ceil(sorted.length / navDiff);
            this.setState({ usersLoans: sorted, noOfPages: num });
        } else if (tab === 4) {
            const sorted = allRequests.filter((request) => {
                return request.status === "cancelled";
            });
            let num = Math.ceil(sorted.length / navDiff);
            this.setState({ usersLoans: sorted, noOfPages: num });
        } else {
            let num = Math.ceil(allRequests.length / navDiff);
            this.setState({ usersLoans: allRequests, noOfPages: num });
        }
    };

    navigateRight = () => {
        const {
            navDiff,
            nextNavIndex,
            currentNavIndex,
            currentPage,
            noOfPages,
        } = this.state;

        if (currentPage !== noOfPages) {
            this.setState({
                nextNavIndex: nextNavIndex + navDiff,
                currentNavIndex: currentNavIndex + navDiff,
                currentPage: (nextNavIndex + navDiff) / navDiff,
            });
        }
    };

    navigateLeft = () => {
        const { navDiff, nextNavIndex, currentNavIndex, currentPage } =
            this.state;
        if (currentPage !== 1) {
            this.setState({
                nextNavIndex: nextNavIndex - navDiff,
                currentNavIndex: currentNavIndex - navDiff,
                currentPage: (nextNavIndex - navDiff) / navDiff,
            });
        }
    };

    render() {
        const {
            viewDetails,
            loading,
            usersLoans,
            currentIndex,
            tabNum,
            currentNavIndex,
            nextNavIndex,
            navDiff,
            currentPage,
            loadingCancelLoan,
        } = this.state;
        return (
            <div>
                <Skeleton loading={loading} active>
                    <div className="row">
                        <div className="col-md-8">
                            <h1 className="z-dashboard-label">My Loans</h1>
                            <div className="z-flex-start z-guarantor-padding mb-3">
                                <img
                                    src={overviewIcon}
                                    className="img-fluid mr-3"
                                    alt="Overview Icon"
                                />
                                <span className="z-label-text">My Loans</span>
                            </div>
                            <div className="z-flex-space z-guarantor-padding2 mb-2">
                                <div
                                    className={
                                        tabNum === 0
                                            ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                            : "z-cursor-poiner z-width-100 z-flex-row"
                                    }
                                    onClick={(e) => {
                                        this.setTab(e, 0);
                                    }}
                                >
                                    <div className="z-guarantor-tab ">
                                        <p className="z-ml-8 z-notification-active">
                                            All
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        tabNum === 1
                                            ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                            : "z-cursor-poiner z-width-100 z-flex-row"
                                    }
                                    onClick={(e) => {
                                        this.setTab(e, 1);
                                    }}
                                >
                                    <div className="z-guarantor-tab">
                                        <p className="z-ml-8 z-notification-active">
                                            Approved
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        tabNum === 2
                                            ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                            : "z-cursor-poiner z-width-100 z-flex-row"
                                    }
                                    onClick={(e) => {
                                        this.setTab(e, 2);
                                    }}
                                >
                                    <div className="z-guarantor-tab">
                                        <p className="z-ml-8 z-notification-active">
                                            Pending
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        tabNum === 3
                                            ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                            : "z-cursor-poiner z-width-100 z-flex-row"
                                    }
                                    onClick={(e) => {
                                        this.setTab(e, 3);
                                    }}
                                >
                                    <div className="z-guarantor-tab">
                                        <p className="z-ml-8 z-notification-active">
                                            Declined
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        tabNum === 4
                                            ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                            : "z-cursor-poiner z-width-100 z-flex-row"
                                    }
                                    onClick={(e) => {
                                        this.setTab(e, 4);
                                    }}
                                >
                                    <div className="z-guarantor-tab">
                                        <p className="z-ml-8 z-notification-active">
                                            Cancelled
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {usersLoans.length < 1 ? (
                                <div className="z-empty-div z-flex-column-center">
                                    <img src={empty} alt="empty" />
                                </div>
                            ) : (
                                usersLoans
                                    .slice(currentNavIndex, nextNavIndex)
                                    .map((loan, i) => {
                                        return (
                                            <div
                                                className="z-flex-space z-loan-padding z-table-row"
                                                key={
                                                    i +
                                                    (currentPage - 1) * navDiff
                                                }
                                            >
                                                <div className="z-width-30">
                                                    <div className="table-index">
                                                        {i + 1}
                                                    </div>
                                                </div>
                                                <div className="z-width-100 ">
                                                    <div className="z-flex-column-start">
                                                        <p className="z-table-row-p">
                                                            {
                                                                loan
                                                                    .loan_package
                                                                    .name
                                                            }
                                                        </p>
                                                        <p className="z-table-label">
                                                            {moment(
                                                                loan.created_at
                                                            ).format(
                                                                "Do MMMM YYYY"
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="z-width-100">
                                                    <div className="">
                                                        <p className="z-table-row-p">
                                                            {naira}
                                                            {formatCurrency(
                                                                loan.amount ||
                                                                    loan
                                                                        .loan_package
                                                                        .amount
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="z-width-100 z-pres-small">
                                                    <div
                                                        className=""
                                                        onClick={(e) => {
                                                            this.showModal(
                                                                e,
                                                                i +
                                                                    (currentPage -
                                                                        1) *
                                                                        navDiff
                                                            );
                                                        }}
                                                    >
                                                        <p className="z-profile-small z-nbm">
                                                            summary &nbsp;&nbsp;{" "}
                                                            <Badge status="processing" />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                            )}

                            {usersLoans.length > 0 && (
                                <div className="z-margin-auto z-flex-center">
                                    <NavigationTab
                                        noOfPages={this.state.noOfPages}
                                        currentPage={this.state.currentPage}
                                        navigateLeft={this.navigateLeft}
                                        navigateRight={this.navigateRight}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="col-md-4">
                            <div className="mt-4">
                                <img
                                    src={sideBanner}
                                    className="img-fluid"
                                    alt="Loan sidebar image"
                                />
                            </div>
                        </div>
                    </div>

                    <UserLoanDetails
                        isVisible={viewDetails}
                        closeModal={this.closeModal}
                        usersLoan={
                            usersLoans.length > 0 && usersLoans[currentIndex]
                        }
                        fetchLoanRequest={this.fetchLoanRequest}
                    />
                </Skeleton>
            </div>
        );
    }
}
